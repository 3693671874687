import * as api from '@/utils/services/users/settings/smokeFriendly';

const state = {
    userSmokeFriendly: {},
    userSmokeFriendlyList: [],
}

const getters = {
    getUserSmokeFriendly: state => state.userSmokeFriendly,
    getUserSmokeFriendlyList: state => state.userSmokeFriendlyList
}

const actions = {

    async fetchUserSmokeFriendly({ commit }) {
        const res = await api.getUserSmokeFriendly();
        commit('setUserSmokeFriendlyList', res.data)
        return res.data;
    },

    async saveUserSmokeFriendly({ commit }, payload) {
        const res = await api.storeUserSmokeFriendly(payload);
        commit('setUserSmokeFriendly', res.data)
    },

    async updateUserSmokeFriendly({ commit }, payload, id) {
        const res = await api.updateUserSmokeFriendly(payload, id);
        commit('setUserSmokeFriendly', res.data)
    },

    async showUserSmokeFriendly({ commit }, id) {
        const res = await api.showUserSmokeFriendly(id);
        commit('setUserSmokeFriendly', res.data)
        return res.data;
    },

    async deleteUserSmokeFriendly({ commit }, id) {
        const res = await api.deleteUserSmokeFriendly(id);
        commit('setUserSmokeFriendlyList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserSmokeFriendly(state, payload) {
        state.userSmokeFriendly = payload
    },

    setUserSmokeFriendlyList(state, payload) {
        state.userSmokeFriendlyList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}