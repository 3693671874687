import * as api from '@/utils/services/clients/settings/medicationManagementDosage';

const state = {
    medicationManagementDosage: {},
    medicationManagementDosageList: [],
}

const getters = {
    getClientMedicationManagementDosage: state => state.medicationManagementDosage,
    getClientMedicationManagementDosageList: state => state.medicationManagementDosageList
}

const actions = {

    async fetchClientMedicationManagementDosages({ commit }) {
        const res = await api.getClientMedicationManagementDosages();
        commit('setClientMedicationManagementDosageList', res.data)
        return res.data;
    },

    async saveClientMedicationManagementDosage({ commit }, payload) {
        const res = await api.storeClientMedicationManagementDosage(payload);
        commit('setClientMedicationManagementDosage', res.data)
    },

    async updateClientMedicationManagementDosage({ commit }, payload, id) {
        const res = await api.updateClientMedicationManagementDosage(payload, id);
        commit('setClientMedicationManagementDosage', res.data)
    },

    async showClientMedicationManagementDosage({ commit }, id) {
        const res = await api.showClientMedicationManagementDosage(id);
        commit('setClientMedicationManagementDosage', res.data)
        return res.data;
    },

    async deleteClientMedicationManagementDosage({ commit }, id) {
        const res = await api.deleteClientMedicationManagementDosage(id);
        commit('setClientMedicationManagementDosageList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMedicationManagementDosage(state, payload) {
        state.medicationManagementDosage = payload
    },

    setClientMedicationManagementDosageList(state, payload) {
        state.medicationManagementDosageList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}