export const state = {
    tasks: {},
    tasks_user: {},
    overview: {},
    tasks_team: {},
    tasks_selected: {},
    task_form_data: {},
    task_item: {},
    task_item_completed: {},
    task_item_overdue: {},
    task_sub_item_selected: {},
    task_sub_item_open: {},
    task_sub_item_related: {},
    task_sub_item_reviewed: {},
    task_sub_item_escalated: {},
    task_user_count: {},
    task_dashboard: {},
    happiness_dashboard: {},
    value_base_dashboard: {},
    metric_dashboard: {},
    templates: {},
    modules: {},
    categories: {},
    statuses: {},
    field_templates: {},
    taskTemplateDetails: {},
    task_template_detail_fields: {},
    task_priorities: {},
    task_priority_details: {},
    task_frequencies: {},
    task_frequency_details: {},
    task_details: {},
    taskForReviewSupervisor: {},
    sampleTask: {},
    visibilityState: false,
    FilterState: false,
  
}