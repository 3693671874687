<template>
    <div class="container row">
        <div class="col-12">
            {{buildversion}} 
        </div> 
    </div>
   
</template>
  
  <script setup>
  import { ref, onMounted } from "vue";
  import {useStore} from 'vuex';
    
  const store = useStore();

  const buildversion = ref("");  

   onMounted(async () => {
        buildversion.value =  await store.dispatch('fetchBuildVersion');
    });

  </script>
  
  <style scoped>

  </style>
  