import * as api from '@/utils/services/clients/settings/authorizationServiceType';

const state = {
    authorizationServiceType: {},
    authorizationServiceTypeList: [],
}

const getters = {
    getClientAuthorizationServiceType: state => state.authorizationServiceType,
    getClientAuthorizationServiceTypeList: state => state.authorizationServiceTypeList
}

const actions = {

    async fetchClientAuthorizationServiceTypes({ commit }) {
        const res = await api.getClientAuthorizationServiceTypes();
        commit('setClientAuthorizationServiceTypeList', res.data)
        return res.data;
    },

    async saveClientAuthorizationServiceType({ commit }, payload) {
        const res = await api.storeClientAuthorizationServiceType(payload);
        commit('setClientAuthorizationServiceType', res.data)
    },

    async updateClientAuthorizationServiceType({ commit }, payload, id) {
        const res = await api.updateClientAuthorizationServiceType(payload, id);
        commit('setClientAuthorizationServiceType', res.data)
    },

    async showClientAuthorizationServiceType({ commit }, id) {
        const res = await api.showClientAuthorizationServiceType(id);
        commit('setClientAuthorizationServiceType', res.data)
        return res.data;
    },

    async deleteClientAuthorizationServiceType({ commit }, id) {
        const res = await api.deleteClientAuthorizationServiceType(id);
        commit('setClientAuthorizationServiceTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientAuthorizationServiceType(state, payload) {
        state.authorizationServiceType = payload
    },

    setClientAuthorizationServiceTypeList(state, payload) {
        state.authorizationServiceTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}