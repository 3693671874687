import * as api from '@/utils/services/users/settings/complianceActionTaken';

const state = {
    userComplianceActionTaken: {},
    userComplianceActionTakenList: [],
}

const getters = {
    getUserComplianceActionTaken: state => state.userComplianceActionTaken,
    getUserComplianceActionTakenList: state => state.userComplianceActionTakenList
}

const actions = {

    async fetchUserComplianceActionTaken({ commit }) {
        const res = await api.getUserComplianceActionTaken();
        commit('setUserComplianceActionTakenList', res.data)
        return res.data;
    },

    async saveUserComplianceActionTaken({ commit }, payload) {
        const res = await api.storeUserComplianceActionTaken(payload);
        commit('setUserComplianceActionTaken', res.data)
    },

    async updateUserComplianceActionTaken({ commit }, payload, id) {
        const res = await api.updateUserComplianceActionTaken(payload, id);
        commit('setUserComplianceActionTaken', res.data)
    },

    async showUserComplianceActionTaken({ commit }, id) {
        const res = await api.showUserComplianceActionTaken(id);
        commit('setUserComplianceActionTaken', res.data)
        return res.data;
    },

    async deleteUserComplianceActionTaken({ commit }, id) {
        const res = await api.deleteUserComplianceActionTaken(id);
        commit('setUserComplianceActionTakenList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserComplianceActionTaken(state, payload) {
        state.userComplianceActionTaken = payload
    },

    setUserComplianceActionTakenList(state, payload) {
        state.userComplianceActionTakenList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}