import * as api from '@/utils/services/clients/settings/vaccineTypes';

const state = {
    vaccineType: {},
    vaccineTypeList: [],
}

const getters = {
    getClientVaccineType: state => state.vaccineType,
    getClientVaccineTypeList: state => state.vaccineTypeList
}

const actions = {

    async fetchClientVaccineTypes({ commit }) {
        const res = await api.getClientVaccineTypes();
        commit('setClientVaccineTypeList', res.data)
        return res.data;
    },

    async saveClientVaccineType({ commit }, payload) {
        const res = await api.storeClientVaccineType(payload);
        commit('setClientVaccineType', res.data)
    },

    async updateClientVaccineType({ commit }, payload, id) {
        const res = await api.updateClientVaccineType(payload, id);
        commit('setClientVaccineType', res.data)
    },

    async showClientVaccineType({ commit }, id) {
        const res = await api.showClientVaccineType(id);
        commit('setClientVaccineType', res.data)
        return res.data;
    },

    async deleteClientVaccineType({ commit }, id) {
        const res = await api.deleteClientVaccineType(id);
        commit('setClientVaccineTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientVaccineType(state, payload) {
        state.vaccineType = payload
    },

    setClientVaccineTypeList(state, payload) {
        state.vaccineTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}