import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const toastMsg = (message, type, autoClose) => {
    toast(message, {
        type,
        autoClose: autoClose ?? 1500,
    });
}

export default toastMsg