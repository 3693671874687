import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'clients/settings/vaccine-type';

export const getClientVaccineTypes = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const storeClientVaccineType = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateClientVaccineType = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showClientVaccineType = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteClientVaccineType = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
