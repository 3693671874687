import { complaintDropdownsRequest } from '@/utils/services/complaints';
import categories from './settings/categories';
import types from './settings/types';
import reasons from './settings/reasons';

export default {
    state: {
        complaint_dropdowns: {},

        ...categories.state,
        ...types.state,
        ...reasons.state,
        
    },

    getters: {
        listComplaintDropdowns: (state) => state.complaint_dropdowns, 

        ...categories.getters,
        ...types.getters,
        ...reasons.getters,
    },

    mutations: {
        setDropdowns(state, payload) {
            state.complaint_dropdowns = payload;
        }, 

        ...categories.mutations,
        ...types.mutations,
        ...reasons.mutations,
    },

    actions: {
        async getComplaintDropdowns({ commit }) {
            const res = await complaintDropdownsRequest()
            commit('setDropdowns', res.data)
        },

        ...categories.actions,
        ...types.actions,
        ...reasons.actions,

    }
}
