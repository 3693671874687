import * as api from '@/utils/services/systemmodules';

const state = {
    system_modules: {},
    system_module_info: {},
    sub_system_modules_in_system_module: {},
}

const getters = {
    listSystemModules: state => state.system_modules,
    listSystemModuleInfo: state => state.system_module_info,
    listSubSystemModulesInSystemModule: state => state.sub_system_modules_in_system_module,
}

const actions = {

    async fetchSystemModules({ commit }) {
        try {
            const response = await api.getSystemModules();
            commit('setSystemModules', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async createSystemModule({ commit }, payload) {
        try {
            const response = await api.addSystemModule(payload);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async SystemModuleInfo({ commit }, id) {
        try {
            const response = await api.findSystemModule(id);
            commit('setSystemModuleInfo', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editSystemModule({ commit }, { moduleId, payload }) {
        try {
            const response = await api.updateSystemModule(moduleId, payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async deleteSystemModule({ commit }, id) {
        try {
            const response = await api.removeSystemModule(id);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    //SUB SYSTEM MODULES
    async subModulesInModule({ commit }, id) {
        try {
            const response = await api.getSubModulesByModuleId(id);
            commit('setSubSystemModulesInSystemModule', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async createSubSystemModule({ commit }, payload) {
        try {
            const response = await api.addSubSystemModule(payload);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editSubSystemModule({ commit }, { subModuleId, payload }) {
        try {
            const response = await api.updateSubSystemModule(subModuleId, payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

}

const mutations = {

    setSystemModules(state, sys_modules) {
        state.system_modules = sys_modules
    },

    setSystemModuleInfo(state, sys_module_info) {
        state.system_module_info = sys_module_info
    },

    setSubSystemModulesInSystemModule(state, sub_modules_in_system_module) {
        state.sub_system_modules_in_system_module = sub_modules_in_system_module
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}