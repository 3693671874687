import * as api from '@/utils/services/users/settings/skillLevel';

const state = {
    userSkillLevel: {},
    userSkillLevelList: [],
}

const getters = {
    getUserSkillLevel: state => state.userSkillLevel,
    getUserSkillLevelList: state => state.userSkillLevelList
}

const actions = {

    async fetchUserSkillLevels({ commit }) {
        const res = await api.getUserSkillLevels();
        commit('setUserSkillLevelList', res.data)
        return res.data;
    },

    async saveUserSkillLevel({ commit }, payload) {
        const res = await api.storeUserSkillLevel(payload);
        commit('setUserSkillLevel', res.data)
    },

    async updateUserSkillLevel({ commit }, payload, id) {
        const res = await api.updateUserSkillLevel(payload, id);
        commit('setUserSkillLevel', res.data)
    },

    async showUserSkillLevel({ commit }, id) {
        const res = await api.showUserSkillLevel(id);
        commit('setUserSkillLevel', res.data)
        return res.data;
    },

    async deleteUserSkillLevel({ commit }, id) {
        const res = await api.deleteUserSkillLevel(id);
        commit('setUserSkillLevelList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserSkillLevel(state, payload) {
        state.userSkillLevel = payload
    },

    setUserSkillLevelList(state, payload) {
        state.userSkillLevelList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}