import * as api from '@/utils/services/clients/settings/livingWith';

const state = {
    livingWith: {},
    livingWithList: [],
}

const getters = {
    getClientLivingWith: state => state.livingWith,
    getClientLivingWithList: state => state.livingWithList
}

const actions = {

    async fetchClientLivingWiths({ commit }) {
        const res = await api.getClientLivingWiths();
        commit('setClientLivingWithList', res.data)
        return res.data;
    },

    async saveClientLivingWith({ commit }, payload) {
        const res = await api.storeClientLivingWith(payload);
        commit('setClientLivingWith', res.data)
    },

    async updateClientLivingWith({ commit }, payload, id) {
        const res = await api.updateClientLivingWith(payload, id);
        commit('setClientLivingWith', res.data)
    },

    async showClientLivingWith({ commit }, id) {
        const res = await api.showClientLivingWith(id);
        commit('setClientLivingWith', res.data)
        return res.data;
    },

    async deleteClientLivingWith({ commit }, id) {
        const res = await api.deleteClientLivingWith(id);
        commit('setClientLivingWithList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientLivingWith(state, payload) {
        state.livingWith = payload
    },

    setClientLivingWithList(state, payload) {
        state.livingWithList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}