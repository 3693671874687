import * as api from '@/utils/services/clients/settings/physicianType';

const state = {
    physicianType: {},
    physicianTypeList: [],
}

const getters = {
    getClientPhysicianType: state => state.physicianType,
    getClientPhysicianTypeList: state => state.physicianTypeList
}

const actions = {

    async fetchClientPhysicianTypes({ commit }) {
        const res = await api.getClientPhysicianTypes();
        commit('setClientPhysicianTypeList', res.data)
        return res.data;
    },

    async saveClientPhysicianType({ commit }, payload) {
        const res = await api.storeClientPhysicianType(payload);
        commit('setClientPhysicianType', res.data)
    },

    async updateClientPhysicianType({ commit }, payload, id) {
        const res = await api.updateClientPhysicianType(payload, id);
        commit('setClientPhysicianType', res.data)
    },

    async showClientPhysicianType({ commit }, id) {
        const res = await api.showClientPhysicianType(id);
        commit('setClientPhysicianType', res.data)
        return res.data;
    },

    async deleteClientPhysicianType({ commit }, id) {
        const res = await api.deleteClientPhysicianType(id);
        commit('setClientPhysicianTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPhysicianType(state, payload) {
        state.physicianType = payload
    },

    setClientPhysicianTypeList(state, payload) {
        state.physicianTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}