import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default [
    {
        path: "schedule-management",
        name: "ScheduleManagement",
        component: () => import("@/views/schedule_management/index.vue"),
        meta: {
            check: 'show_schedule',
        },
        beforeEnter: checkPermissions,
    },

    {
        path: "schedule-management/schedule",
        name: "SchedulePage",
        component: () => import("@/views/schedule_management/scheduling/Schedule.vue"),
    },

    {
        path: "schedule-management/staff-schedule",
        name: "StaffSchedulePage",
        component: () => import("@/views/schedule_management/scheduling/StaffSchedule.vue"),
    },

    {
        path: "schedule-management/sample",
        name: "SamplePage",
        // component: () => import("@/views/schedule_management/scheduling/Sample.vue"),
    },

    {
        path: "schedule-management/match-staff",
        name: "MatchStaffPage",
        component: () => import("@/views/schedule_management/scheduling/MatchStaff.vue"),
    },

    {
        path: "schedule-management/system-models",
        name: "SystemModelsPage",
        component: () => import("@/views/schedule_management/SystemModels.vue"),
    },

    {
        path: "schedule-management/evv-management-action",
        name: "ScheduleEVVManagementAction",
        component: () => import("@/views/schedule_management/scheduling/tabs/components/evv-action"),
    },

    // Settings Route::
    {
        path: "schedule-management/settings/roles",
        name: "SchedulingRolesPage",
        component: () => import("@/views/schedule_management/settings/roles"),
    },

    {
        path: "schedule-management/settings/preference-groups",
        name: "SchedulingPreferenceGroupsPage",
        component: () => import("@/views/schedule_management/settings/preference_groups"),
    },

    {
        path: "schedule-management/settings/time-from-client-percentages",
        name: "SchedulingMatchTimeFromClientPercentagesPage",
        component: () => import("@/views/schedule_management/settings/match_time_from_client_percentages"),
    },

    {
        path: "schedule-management/settings",
        name: "SchedulingSettingsPage",
        component: () => import("@/views/schedule_management/settings"),
        meta: {
            check: 'show_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: "schedule-management/settings/program-category",
        name: "SchedulingSettingsProgramCategoryPage",
        component: () => import("@/views/schedule_management/settings/program-categories"),
    },
    {
        path: "schedule-management/settings/staff-type",
        name: "SchedulingSettingsStaffTypePage",
        component: () => import("@/views/schedule_management/settings/staff-types"),
    },
    {
        path: 'schedule-management/settings/types/:id',
        name: 'SchedulingSettingsProgramTypePageID',
        component: () => import('@/views/schedule_management/settings/staff-types/Details'),
        meta: { requiresAuth: true }
    },
    {
        path: 'schedule-management/settings/evv-issues-types',
        name: 'SchedulingSettingsEVVIssueTypesPage',
        component: () => import('@/views/schedule_management/settings/evv-issue-type'),
        meta: { requiresAuth: true }
    },
    {
        path: 'schedule-management/settings/admin-activities',
        name: 'SchedulingSettingsAdminActivitiesPage',
        component: () => import('@/views/schedule_management/settings/admin-activities'),
        meta: { requiresAuth: true }
    },
    {
        path: 'schedule-management/settings/admin-activities/:id',
        name: 'SchedulingSettingsAdminActivitiesDetailsPage',
        component: () => import('@/views/schedule_management/settings/admin-activities/Details.vue'),
        meta: { requiresAuth: true }
    },
];
