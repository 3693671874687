import * as api from '@/utils/services/coordinators';


const state = {
    coordinators: {}
}

const getters = {
    listCoordinators: state => state.coordinators
}

const actions = {

    async fetchCoordinators(content, params) {
        try {

            const response = await api.getCoordinators(params);
          
            content.commit('setCoordinators', response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error.response;
        }
    },

    async assignCoordinator(content, params) {
        try {
            const response = await api.assign_homecareworker_coordinator(params);

          
            return response.data;
        } catch (error) {
            console.log(error);
            throw error.response;
        }
    }

}

const mutations = {
    setCoordinators(state, coordinator) {
        state.coordinators = coordinator
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}