import {fetchComplaintCategoryRequest} from '@/utils/services/complaints/settings/categories';

const state = {
    category: {},
}

const getters = {
    getComplaintCategory: state => state.category
}

const actions = {
    async fetchComplaintCategory({ commit }, id) {
        const res = await fetchComplaintCategoryRequest(id)
        commit('setComplaintCategory', res.data.data)

    },
}

const mutations = {
    setComplaintCategory(state, payload) {
        state.category = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}