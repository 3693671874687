import api from '@/utils/services/emails';
import axios from 'axios';

const state = {
	errors: {},
	loading: false,
	emailSMTPDetail: {},

}

const getters = {
	emailSMTPLoading: state => state.loading,
	emailSMTPErrors: state => state.errors,
	emailSMTPDetail: state => state.emailSMTPDetail,
}

const actions = {

	async fetchEmailSMTP(content) {
		content.commit('setEmailSMTPLoader', true)
		api.getEmailSMTP()
			.then(response => {

				content.commit('setEmailSMTPDetail', response.data.smtp)
				content.commit('setEmailSMTPLoader', false)
			}).catch(error => {
				console.log(error.response)
			})
	},

	async updateEmailSMTP(content, { data, id }) {
		api.updateEmailSMTP(data, id)
			.then((response) => {

				// shit here
			}).catch(error => {
				content.commit('setEmailSMTPError', error.response.data.errors)
				content.commit('setEmailSMTPLoader', false)
			})
	},

}

const mutations = {

	setEmailSMTPDetail(state, emailSMTPDetail) {
		state.emailSMTPDetail = emailSMTPDetail
	},

	setEmailSMTPLoader(state, loading) {
		state.loading = loading
	},

	setEmailSMTPError(state, errors) {
		state.errors = errors
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}