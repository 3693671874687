import * as api from '@/utils/services/clients/settings/mentalStatus';

const state = {
    mentalStatus: {},
    mentalStatusList: [],
}

const getters = {
    getClientMentalStatus: state => state.mentalStatus,
    getClientMentalStatusList: state => state.mentalStatusList
}

const actions = {

    async fetchClientMentalStatus({ commit }) {
        const res = await api.getClientMentalStatus();
        commit('setClientMentalStatusList', res.data)
        return res.data;
    },

    async saveClientMentalStatus({ commit }, payload) {
        const res = await api.storeClientMentalStatus(payload);
        commit('setClientMentalStatus', res.data)
    },

    async updateClientMentalStatus({ commit }, payload, id) {
        const res = await api.updateClientMentalStatus(payload, id);
        commit('setClientMentalStatus', res.data)
    },

    async showClientMentalStatus({ commit }, id) {
        const res = await api.showClientMentalStatus(id);
        commit('setClientMentalStatus', res.data)
        return res.data;
    },

    async deleteClientMentalStatus({ commit }, id) {
        const res = await api.deleteClientMentalStatus(id);
        commit('setClientMentalStatusList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMentalStatus(state, payload) {
        state.mentalStatus = payload
    },

    setClientMentalStatusList(state, payload) {
        state.mentalStatusList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}