import * as api from '@/utils/services/clients/settings/hospitals';

const state = {
    hospital: {},
    hospitalList: [],
}

const getters = {
    getClientHospital: state => state.hospital,
    getClientHospitalList: state => state.hospitalList
}

const actions = {

    async fetchClientHospitals({ commit }) {
        const res = await api.getClientHospitals();
        commit('setClientHospitalList', res.data)
        return res.data;
    },

    async saveClientHospital({ commit }, payload) {
        const res = await api.storeClientHospital(payload);
        commit('setClientHospital', res.data)
    },

    async updateClientHospital({ commit }, payload, id) {
        const res = await api.updateClientHospital(payload, id);
        commit('setClientHospital', res.data)
    },

    async showClientHospital({ commit }, id) {
        const res = await api.showClientHospital(id);
        commit('setClientHospital', res.data)
        return res.data;
    },

    async deleteClientHospital({ commit }, id) {
        const res = await api.deleteClientHospital(id);
        commit('setClientHospitalList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientHospital(state, payload) {
        state.hospital = payload
    },

    setClientHospitalList(state, payload) {
        state.hospitalList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}