import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: 'intake-coordinators',
        name: 'Intake Coordinators',
        component: () =>
            import ('@/views/intakecoordinators/index.vue'),
        meta: {
            check: 'show_intake_coordinators',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: 'intake-coordinators/add',
        name: 'Add Intake Coordinator',
        component: () =>
            import ('@/views/intakecoordinators/create'),
    },
    {
        path: 'intake-coordinators/update/:id',
        name: 'Update Intake Coordinator',
        component: () =>
            import ('@/views/intakecoordinators/edit'),
    },
    {
        path: 'intake-coordinators/view/:id',
        name: 'View Intake Coordinator Details',
        component: () =>
            import ('@/views/homecareworkers/view'),
    },

]