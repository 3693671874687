import * as api from '@/utils/services/clients/settings/symptoms';

const state = {
    symptomSettings: {},
    symptomSettingsList: [],
    symptomSettingsByDiagnosisList: [],
}

const getters = {
    getClientSymptomSettings: state => state.symptomSettings,
    getClientSymptomSettingsList: state => state.symptomSettingsList,
    getClientSymptomSettingsByDiagnosisList: state => state.symptomSettingsByDiagnosisList
}

const actions = {

    async fetchClientSymptomsSettings({ commit }) {
        const res = await api.getClientSymptoms();
        commit('setClientSymptomSettingsList', res.data)
        return res.data;
    },

    async fetchClientSymptomsSettingsByDiagnosis({ commit }, id) {
        const res = await api.getClientSymptomsByDiagnosis(id);
        commit('setClientSymptomSettingsByDiagnosisList', res.data)
        return res.data;
    },

    async saveClientSymptomSettings({ commit }, payload) {
        const res = await api.storeClientSymptom(payload);
        commit('setClientSymptomSettings', res.data)
    },

    async updateClientSymptomSettings({ commit }, payload) {
        const res = await api.updateClientSymptom(payload);
        commit('setClientSymptomSettings', res.data)
    },

    async showClientSymptomSettings({ commit }, id) {
        const res = await api.showClientSymptom(id);
        commit('setClientSymptomSettings', res.data)
        return res.data;
    },

    async deleteClientSymptomSettings({ commit }, id) {
        const res = await api.deleteClientSymptom(id);
        commit('setClientSymptomSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientSymptomSettings(state, payload) {
        state.symptomSettings = payload
    },

    setClientSymptomSettingsList(state, payload) {
        state.symptomSettingsList = payload
    },

    setClientSymptomSettingsByDiagnosisList(state, payload) {
        state.symptomSettingsByDiagnosisList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}