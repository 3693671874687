import * as api from '@/utils/services/users/settings/educationCertification';

const state = {
    userEducationCertification: {},
    userEducationCertificationList: [],
}

const getters = {
    getUserEducationCertification: state => state.userEducationCertification,
    getUserEducationCertificationList: state => state.userEducationCertificationList
}

const actions = {

    async fetchUserEducationCertifications({ commit }) {
        const res = await api.getUserEducationCertifications();
        commit('setUserEducationCertificationList', res.data)
        return res.data;
    },

    async saveUserEducationCertification({ commit }, payload) {
        const res = await api.storeUserEducationCertification(payload);
        commit('setUserEducationCertification', res.data)
    },

    async updateUserEducationCertification({ commit }, payload, id) {
        const res = await api.updateUserEducationCertification(payload, id);
        commit('setUserEducationCertification', res.data)
    },

    async showUserEducationCertification({ commit }, id) {
        const res = await api.showUserEducationCertification(id);
        commit('setUserEducationCertification', res.data)
        return res.data;
    },

    async deleteUserEducationCertification({ commit }, id) {
        const res = await api.deleteUserEducationCertification(id);
        commit('setUserEducationCertificationList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserEducationCertification(state, payload) {
        state.userEducationCertification = payload
    },

    setUserEducationCertificationList(state, payload) {
        state.userEducationCertificationList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}