import { incidentDropdownsRequest, getIncidentRequest,getIncidentSummary, getIncidentDashboardData } from '@/utils/services/incidents';
import categories from './settings/categories';
import types from './settings/types';
import reasons from './settings/reasons';

export default {
    state: {
        incident_dropdowns: {}, 
        incident: {},      
	    incident_dashboard: {},

        ...categories.state,
        ...types.state,
        ...reasons.state,
        
    },

    getters: {
        listIncidentDropdowns: (state) => state.incident_dropdowns, 
        listIncident: (state) => state.incident, 
        listIncidentDashboardData: state => state.incident_dashboard,

        ...categories.getters,
        ...types.getters,
        ...reasons.getters,
    },

    mutations: {
        setDropdowns(state, payload) {
            state.incident_dropdowns = payload;
        }, 

        setIncident(state, payload) {
            state.incident = payload;
        },

        setIncidentDashboardData(state, data) {
            state.incident_dashboard = data
        },
    

        ...categories.mutations,
        ...types.mutations,
        ...reasons.mutations,
    },

    actions: {
        async getIncidentDropdowns({ commit }) {
            const res = await incidentDropdownsRequest()
            commit('setDropdowns', res.data)
        },

        async getIncident({ commit }, id) {
            const res = await getIncidentRequest(id)
            commit('setIncident', res.data.data)
        },
        

        async getIncidentSummary({ commit }, id) {
            const res = await getIncidentSummary(id)
            commit('setIncident', res.data.data)
        },
    
        async getIncidentDashboardData({ commit }, params) {
            const res = await getIncidentDashboardData(params);
            if (res && res.data) { // Check if 'res' and 'res.data' are not null
            //  console.log("getIncidentDashboardData", res.data);
              commit('setIncidentDashboardData', res.data);
            } else {
              console.log("getIncidentDashboardData returned null or undefined");
              commit('setIncidentDashboardData', res);
            }
          },
          
        
        ...categories.actions,
        ...types.actions,
        ...reasons.actions,

    }
}
