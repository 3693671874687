import * as api from '@/utils/services/users/settings/clientType';

const state = {
    userClientType: {},
    userClientTypeList: [],
}

const getters = {
    getUserClientType: state => state.userClientType,
    getUserClientTypeList: state => state.userClientTypeList
}

const actions = {

    async fetchUserClientType({ commit }) {
        const res = await api.getUserClientType();
        commit('setUserClientTypeList', res.data)
        return res.data;
    },

    async saveUserClientType({ commit }, payload) {
        const res = await api.storeUserClientType(payload);
        commit('setUserClientType', res.data)
    },

    async updateUserClientType({ commit }, payload, id) {
        const res = await api.updateUserClientType(payload, id);
        commit('setUserClientType', res.data)
    },

    async showUserClientType({ commit }, id) {
        const res = await api.showUserClientType(id);
        commit('setUserClientType', res.data)
        return res.data;
    },

    async deleteUserClientType({ commit }, id) {
        const res = await api.deleteUserClientType(id);
        commit('setUserClientTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserClientType(state, payload) {
        state.userClientType = payload
    },

    setUserClientTypeList(state, payload) {
        state.userClientTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}