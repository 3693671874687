import * as api from '@/utils/services/clients/settings/primaryPhysician';

const state = {
    primaryPhysician: {},
    primaryPhysicianList: [],
}

const getters = {
    getClientPrimaryPhysician: state => state.primaryPhysician,
    getClientPrimaryPhysicianList: state => state.primaryPhysicianList
}

const actions = {

    async fetchClientPrimaryPhysicians({ commit }) {
        const res = await api.getClientPrimaryPhysicians();
        commit('setClientPrimaryPhysicianList', res.data)
        return res.data;
    },

    async saveClientPrimaryPhysician({ commit }, payload) {
        const res = await api.storeClientPrimaryPhysician(payload);
        commit('setClientPrimaryPhysician', res.data)
    },

    async updateClientPrimaryPhysician({ commit }, payload, id) {
        const res = await api.updateClientPrimaryPhysician(payload, id);
        commit('setClientPrimaryPhysician', res.data)
    },

    async showClientPrimaryPhysician({ commit }, id) {
        const res = await api.showClientPrimaryPhysician(id);
        commit('setClientPrimaryPhysician', res.data)
        return res.data;
    },

    async deleteClientPrimaryPhysician({ commit }, id) {
        const res = await api.deleteClientPrimaryPhysician(id);
        commit('setClientPrimaryPhysicianList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPrimaryPhysician(state, payload) {
        state.primaryPhysician = payload
    },

    setClientPrimaryPhysicianList(state, payload) {
        state.primaryPhysicianList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}