import * as api from '@/utils/services/clients/settings/medicationManagement';

const state = {
    medicationManagementSettings: {},
    medicationManagementSettingsList: [],
}

const getters = {
    getClientMedicationManagementSettings: state => state.medicationManagementSettings,
    getClientMedicationManagementSettingsList: state => state.medicationManagementSettingsList
}

const actions = {

    async fetchClientMedicationManagementSettings({ commit }) {
        const res = await api.getClientMedicationManagements();
        commit('setClientMedicationManagementSettingsList', res.data)
        return res.data;
    },

    async saveClientMedicationManagementSettings({ commit }, payload) {
        const res = await api.storeClientMedicationManagement(payload);
        commit('setClientMedicationManagementSettings', res.data)
    },

    async updateClientMedicationManagementSettings({ commit }, payload, id) {
        const res = await api.updateClientMedicationManagement(payload, id);
        commit('setClientMedicationManagementSettings', res.data)
    },

    async showClientMedicationManagementSettings({ commit }, id) {
        const res = await api.showClientMedicationManagement(id);
        commit('setClientMedicationManagementSettings', res.data)
        return res.data;
    },

    async deleteClientMedicationManagementSettings({ commit }, id) {
        const res = await api.deleteClientMedicationManagement(id);
        commit('setClientMedicationManagementSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMedicationManagementSettings(state, payload) {
        state.medicationManagementSettings = payload
    },

    setClientMedicationManagementSettingsList(state, payload) {
        state.medicationManagementSettingsList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}