import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

export const getSystemActivityLog = async () => {
    try {

        return await axios.get(`${baseAPI}/system-activity-log`, Header);
    } catch (error) {
        return error
    }
}

export const getBuildVersion = async () => {
    try {
      const resp = await axios.get(`${baseAPI}/build-version`, Header);
      return resp.data;
    } catch (error) {
  
      return error
    }
  }
