import * as api from '@/utils/services/users/settings/complianceTestResult';

const state = {
    userComplianceTestResult: {},
    userComplianceTestResultList: [],
}

const getters = {
    getUserComplianceTestResult: state => state.userComplianceTestResult,
    getUserComplianceTestResultList: state => state.userComplianceTestResultList
}

const actions = {

    async fetchUserComplianceTestResult({ commit }) {
        const res = await api.getUserComplianceTestResult();
        commit('setUserComplianceTestResultList', res.data)
        return res.data;
    },

    async saveUserComplianceTestResult({ commit }, payload) {
        const res = await api.storeUserComplianceTestResult(payload);
        commit('setUserComplianceTestResult', res.data)
    },

    async updateUserComplianceTestResult({ commit }, payload, id) {
        const res = await api.updateUserComplianceTestResult(payload, id);
        commit('setUserComplianceTestResult', res.data)
    },

    async showUserComplianceTestResult({ commit }, id) {
        const res = await api.showUserComplianceTestResult(id);
        commit('setUserComplianceTestResult', res.data)
        return res.data;
    },

    async deleteUserComplianceTestResult({ commit }, id) {
        const res = await api.deleteUserComplianceTestResult(id);
        commit('setUserComplianceTestResultList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserComplianceTestResult(state, payload) {
        state.userComplianceTestResult = payload
    },

    setUserComplianceTestResultList(state, payload) {
        state.userComplianceTestResultList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}