import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'user/settings/work-license-type';

export const getUserWorkLicenseType = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const storeUserWorkLicenseType = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateUserWorkLicenseType = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showUserWorkLicenseType = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteUserWorkLicenseType = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
