import {fetchSchedulingAdminActivityRequest, fetchAdminActivityCompletedResponseRequest  } from '@/utils/services/schedule_management/settings/admin_activities';

const state = {
    activity: {},
    activityResponses: {},
}

const getters = {
    getSchedulingAdminActivity: state => state.activity,
    getSchedulingAdminActivityResponse: state => state.activityResponses
}

const actions = {
    async fetchSchedulingAdminActivity({ commit }, id) {
        const res = await fetchSchedulingAdminActivityRequest(id)
        commit('setSchedulingAdminActivity', res.data.data)

    },
    async fetchSchedulingAdminActivityResponse({ commit }) {
        const res = await fetchAdminActivityCompletedResponseRequest()
        commit('setSchedulingAdminActivity', res.data.data)

    },
}

const mutations = {
    setSchedulingAdminActivity(state, payload) {
        state.activity = payload
    },
    setSchedulingAdminActivityResponse(state, payload) {
        state.activity = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}