import * as api from '@/utils/services/clients/settings/taskActivityApplicable';

const state = {
    applicable: {},
    applicableList: [],
}

const getters = {
    getClientTaskActivityApplicable: state => state.applicable,
    getClientTaskActivityApplicableList: state => state.applicableList
}

const actions = {

    async fetchClientTaskActivityApplicable({ commit }) {
        const res = await api.getClientTaskActivityApplicable();
        commit('setClientTaskActivityApplicableList', res.data)
        return res.data;
    },

    async saveClientTaskActivityApplicable({ commit }, payload) {
        const res = await api.storeClientTaskActivityApplicable(payload);
        commit('setClientTaskActivityApplicable', res.data)
    },

    async updateClientTaskActivityApplicable({ commit }, payload, id) {
        const res = await api.updateClientTaskActivityApplicable(payload, id);
        commit('setClientTaskActivityApplicable', res.data)
    },

    async showClientTaskActivityApplicable({ commit }, id) {
        const res = await api.showClientTaskActivityApplicable(id);
        commit('setClientTaskActivityApplicable', res.data)
        return res.data;
    },

    async deleteClientTaskActivityApplicable({ commit }, id) {
        const res = await api.deleteClientTaskActivityApplicable(id);
        commit('setClientTaskActivityApplicableList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientTaskActivityApplicable(state, payload) {
        state.applicable = payload
    },

    setClientTaskActivityApplicableList(state, payload) {
        state.applicableList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}