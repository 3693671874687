import * as api from '@/utils/services/users/settings/sexuality';

const state = {
    userSexuality: {},
    userSexualityList: [],
}

const getters = {
    getUserSexuality: state => state.userSexuality,
    getUserSexualityList: state => state.userSexualityList
}

const actions = {

    async fetchUserSexualitys({ commit }) {
        const res = await api.getUserSexualitys();
        commit('setUserSexualityList', res.data)
        return res.data;
    },

    async saveUserSexuality({ commit }, payload) {
        const res = await api.storeUserSexuality(payload);
        commit('setUserSexuality', res.data)
    },

    async updateUserSexuality({ commit }, payload, id) {
        const res = await api.updateUserSexuality(payload, id);
        commit('setUserSexuality', res.data)
    },

    async showUserSexuality({ commit }, id) {
        const res = await api.showUserSexuality(id);
        commit('setUserSexuality', res.data)
        return res.data;
    },

    async deleteUserSexuality({ commit }, id) {
        const res = await api.deleteUserSexuality(id);
        commit('setUserSexualityList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserSexuality(state, payload) {
        state.userSexuality = payload
    },

    setUserSexualityList(state, payload) {
        state.userSexualityList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}