import * as api from '@/utils/services/clients/settings/insuranceType';

const state = {
    insuranceType: {},
    insuranceTypeList: [],
}

const getters = {
    getClientInsuranceType: state => state.insuranceType,
    getClientInsuranceTypeList: state => state.insuranceTypeList
}

const actions = {

    async fetchClientInsuranceTypes({ commit }) {
        const res = await api.getClientInsuranceTypes();
        commit('setClientInsuranceTypeList', res.data)
        return res.data;
    },

    async saveClientInsuranceType({ commit }, payload) {
        const res = await api.storeClientInsuranceType(payload);
        commit('setClientInsuranceType', res.data)
    },

    async updateClientInsuranceType({ commit }, payload, id) {
        const res = await api.updateClientInsuranceType(payload, id);
        commit('setClientInsuranceType', res.data)
    },

    async showClientInsuranceType({ commit }, id) {
        const res = await api.showClientInsuranceType(id);
        commit('setClientInsuranceType', res.data)
        return res.data;
    },

    async deleteClientInsuranceType({ commit }, id) {
        const res = await api.deleteClientInsuranceType(id);
        commit('setClientInsuranceTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientInsuranceType(state, payload) {
        state.insuranceType = payload
    },

    setClientInsuranceTypeList(state, payload) {
        state.insuranceTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}