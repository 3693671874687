import * as api from '@/utils/services/clients/settings/medicationManagementRoute';

const state = {
    medicationManagementRoute: {},
    medicationManagementRouteList: [],
}

const getters = {
    getClientMedicationManagementRoute: state => state.medicationManagementRoute,
    getClientMedicationManagementRouteList: state => state.medicationManagementRouteList
}

const actions = {

    async fetchClientMedicationManagementRoutes({ commit }) {
        const res = await api.getClientMedicationManagementRoutes();
        commit('setClientMedicationManagementRouteList', res.data)
        return res.data;
    },

    async saveClientMedicationManagementRoute({ commit }, payload) {
        const res = await api.storeClientMedicationManagementRoute(payload);
        commit('setClientMedicationManagementRoute', res.data)
    },

    async updateClientMedicationManagementRoute({ commit }, payload, id) {
        const res = await api.updateClientMedicationManagementRoute(payload, id);
        commit('setClientMedicationManagementRoute', res.data)
    },

    async showClientMedicationManagementRoute({ commit }, id) {
        const res = await api.showClientMedicationManagementRoute(id);
        commit('setClientMedicationManagementRoute', res.data)
        return res.data;
    },

    async deleteClientMedicationManagementRoute({ commit }, id) {
        const res = await api.deleteClientMedicationManagementRoute(id);
        commit('setClientMedicationManagementRouteList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMedicationManagementRoute(state, payload) {
        state.medicationManagementRoute = payload
    },

    setClientMedicationManagementRouteList(state, payload) {
        state.medicationManagementRouteList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}