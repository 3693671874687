import { ref } from 'vue';
import { useStore } from 'vuex';
import { hasPermission, setUserPerms } from './permissions';

export function usePermissions() {
    const store = useStore();

    return {
        hasPermission,
        setUserPerms,
    };
}
