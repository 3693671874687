//TO DO: Import Services
import { getRoleUser } from '../../utils/services/permission/index';

import {
saveTenantDetails, getTenants,  
    
} from "@/utils/services/multitenant";


//UPDATE 1

export const actions = {


  async saveTenantDetails({ commit }, payload) {
    try {
      const response = await saveTenantDetails(payload);
      return response;
    } catch (error) {
      console.error('Failed to save Tenant details:', error);
      throw error;
    }
  },

  async fetchTenants({ commit }, payload) {
    try {
      const response = await getTenants();
      return response;
    } catch (error) {
      console.error('Failed to get Tenants: ', error);
      throw error;
    }
  },





}