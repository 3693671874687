import * as api from '@/utils/services/users/settings/workLicenseStatus';

const state = {
    userWorkLicenseStatus: {},
    userWorkLicenseStatusList: [],
}

const getters = {
    getUserWorkLicenseStatus: state => state.userWorkLicenseStatus,
    getUserWorkLicenseStatusList: state => state.userWorkLicenseStatusList
}

const actions = {

    async fetchUserWorkLicenseStatus({ commit }) {
        const res = await api.getUserWorkLicenseStatus();
        commit('setUserWorkLicenseStatusList', res.data)
        return res.data;
    },

    async saveUserWorkLicenseStatus({ commit }, payload) {
        const res = await api.storeUserWorkLicenseStatus(payload);
        commit('setUserWorkLicenseStatus', res.data)
    },

    async updateUserWorkLicenseStatus({ commit }, payload, id) {
        const res = await api.updateUserWorkLicenseStatus(payload, id);
        commit('setUserWorkLicenseStatus', res.data)
    },

    async showUserWorkLicenseStatus({ commit }, id) {
        const res = await api.showUserWorkLicenseStatus(id);
        commit('setUserWorkLicenseStatus', res.data)
        return res.data;
    },

    async deleteUserWorkLicenseStatus({ commit }, id) {
        const res = await api.deleteUserWorkLicenseStatus(id);
        commit('setUserWorkLicenseStatusList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserWorkLicenseStatus(state, payload) {
        state.userWorkLicenseStatus = payload
    },

    setUserWorkLicenseStatusList(state, payload) {
        state.userWorkLicenseStatusList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}