import axios from "axios";
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;

export const getSystemModules = async() => {
    try {
        return await axios.get(`${baseAPI}/system-modules`, Header);
    } catch (error) {
        console.error('Error getting system modules:', error);
        throw error;
    }
};

export const addSystemModule = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/system-modules`, payload, Header);
    } catch (error) {
        console.error('Error adding system module:', error);
        throw error;
    }
};

export const findSystemModule = async(id) => {
    try {
        return await axios.get(`${baseAPI}/system-modules/${id}`, Header);
    } catch (error) {
        console.error('Error finding system module:', error);
        throw error;
    }
};

export const updateSystemModule = async(id, payload) => {
    try {
        return await axios.post(`${baseAPI}/system-modules/${id}/update`, payload, Header);
    } catch (error) {
        console.error('Error updating system module:', error);
        throw error;
    }
};

export const removeSystemModule = async(id) => {
    try {
        return await axios.post(`${baseAPI}/system-modules/${id}/delete`, null, Header);
    } catch (error) {
        console.error('Error deleting system module:', error);
        throw error;
    }
};

//sub system modules

export const getSubModulesByModuleId = async(id) => {
    try {
        return await axios.get(`${baseAPI}/sub-system-modules/${id}/system-module`, Header);
    } catch (error) {
        console.error('Error getting sub-system modules:', error);
        throw error;
    }
};

export const addSubSystemModule = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/sub-system-modules`, payload, Header);
    } catch (error) {
        console.error('Error adding sub-system modules:', error);
        throw error;
    }
};

export const updateSubSystemModule = async(id, payload) => {
    try {
        return await axios.post(`${baseAPI}/sub-system-modules/${id}/update`, payload, Header);
    } catch (error) {
        console.error('Error updating sub system module:', error);
        throw error;
    }
};

export const twoFactorAuthSettingsReq = async( payload) => {
    try {
        return await axios.post(`${baseAPI}/two-factor-settings`, payload, Header);
    } catch (error) {
        
        throw error;
    }
};