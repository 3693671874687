//roles
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: 'roles',
        name: 'Role',
        component: () =>
            import ('@/views/settings/roles/allroles'),
        // meta: {
        //     check: 'permission_management_access',
        // },
        // beforeEnter: checkPermissions,
    },
    {
        path: 'roles/:id',
        name: 'Role Name',
        component: () =>
            import ('@/views/settings/roles/RoleDetails.vue')
    },
    {
        path: 'roles/table',
        name: 'Role Table',
        component: () =>
            import ('@/views/settings/roles/RoleUsersTable.vue')
    },
    {
        path: 'system-role/:id',
        name: 'System Role Name',
        component: () =>
            import ('@/views/settings/roles/systemroles/SystemRoleDetails.vue')
    },
    {
        path: 'system-role/system-module/:id',
        name: 'System Module Name',
        component: () =>
            import ('@/views/settings/roles/systemroles/PermissionsList.vue')
    },
]