import { state } from "./tenantState.js"
import { actions } from "./tenantActions.js"
import { getters } from "./tenantGetters.js"
import { mutations } from "./tenantMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}