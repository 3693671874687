import * as api from '@/utils/services/homecareworkers';

const state = {
    homecareworkers: [],
    homeCareWorker: {},
    homecaredetails: {},
    homecaremgtdetails: {},
    homecareworkers_dashboard: {},
}

const getters = {
    getHomeCareWorker: state => state.homeCareWorker,
    listHomecareworkers: state => state.homecareworkers,
    listHomecareDetails: state => state.homecaredetails,
    listHomecareMgtDetails: state => state.homecaremgtdetails,
    listHomecareworkersDashboardData: state => state.homecareworkers_dashboard
}

const actions = {

    async fetchAllHomecareworkers({ commit }) {
        try {
            const response = await api.getHomecareworker();
            
            commit('setHomecareworkers', response.data.data);

            return response.data.data;

        } catch (error) {
            
            throw error.response;
        }
    },

    async addHomecareworker({commit}, params) {
        try {
            const response = await api.postHomecareworker(params);

            commit('setHomeCareWorker', response.data);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateHomecareworker(commit, params) {
        try {
            const response = await api.updateHomecareworker(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getHomecareWorkerDetails({ commit }, id) {
        try {
            const response = await api.homecareworkerDetails(id);
          
            commit('setHomecareworkerDetails', response.data.data);

            return response.data.data;
            
        } catch (error) {
            console.log(error);
        }
    },

    async getHomecareWorkerMgtDetails({ commit }, id) {
        try {
            const response = await api.homecareworkerMgtDetails(id);
            commit('setHomecareworkerMgtDetails', response.data.data);
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },

    async deleteHomecareworker(commit, id) {
        try {
            const response = await api.deleteHomecareworker(id);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getHomecareworkersDashboardData({ commit }, params) {
        const res = await api.getHomecareworkersDashboardData(params);
        if (res && res.data) {
          commit('setHomecareworkerDashboardData', res.data);
        } else {
          console.log("getHomecareworkersDashboardData returned null or undefined");
          commit('setHomecareworkerDashboardData', res);
        }
    },

}

const mutations = {

    setHomeCareWorker(state, hcwoker) {
        state.homeCareWorker = hcwoker
    },

    setHomecareworkers(state, hcwokers) {
        state.homecareworkers = hcwokers
    },

    setHomecareworkerDetails(state, homecareworker) {
        state.homecaredetails = homecareworker
    },

    setHomecareworkerMgtDetails(state, homecareworker) {
        state.homecaremgtdetails = homecareworker
    },

    setHomecareworkerDashboardData(state, data) {
        state.homecareworkers_dashboard = data
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}