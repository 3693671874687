//roles
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: 'system-modules',
        name: 'System Modules',
        component: () =>
            import ('@/views/systemmodules'),
        meta: {
            check: 'show_system_modules',
        },
        beforeEnter: checkPermissions,
    },

    {
        path: 'system-modules/:id',
        name: 'System Module Info',
        component: () =>
            import ('@/views/systemmodules/submodules'),
        meta: {
            check: 'show_system_modules',
        },
        beforeEnter: checkPermissions,
    },
]