import axios from 'axios';
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;


export const getCaseCoordinators = async(params) => {
    try {
        // if (params) {
        //     return await axios.get(`${baseAPI}/case-coordinators`, {
        //         params: {
        //             name: params.name || '',
        //             cc_id: params.cc_id || ''
        //         }
        //     }, Header);
        // }
        // return await axios.get(`${baseAPI}/case-coordinators`, {
        //     params: {
        //         name: '',
        //         cc_id: ''
        //     }
        // }, Header);
        return await axios.get(`${baseAPI}/case-coordinators`, Header);
    } catch (error) {
        console.error('Error getting case coordinators:', error);
        throw error;
    }
};

export const postCaseCoordinator = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/case-coordinators`, payload, Header);
    } catch (error) {
        console.error('Error creating case coordinator:', error);
        throw error;
    }
};

export const updateCaseCoordinator = async(params) => {
    try {
        return await axios.post(`${baseAPI}/case-coordinators/${params.id}/update`, params, Header);
    } catch (error) {
        console.error('Error updating case coordinator:', error);
        throw error;
    }
};

export const deleteCaseCoordinator = async(id) => {
    try {
        return await axios.post(`${baseAPI}/case-coordinators/${id}/delete`, Header);
    } catch (error) {
        console.error('Error deleting case coordinator:', error);
        throw error;
    }
};

export const caseCoordinatorDetails = async(id) => {
    try {
        return await axios.get(`${baseAPI}/case-coordinators/${id}`, Header);
    } catch (error) {
        console.error('Error getting case coordinator details:', error);
        throw error;
    }
};

export const getCaseCoordinatorDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/case-coordinators/data/dashboard?type=${type}&period=${period}`;
        
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        
        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);
        
        return res;
    } catch (error) {
        console.error('Error getting case coordinator dashboard details:', error);
        throw error; 
    }
}

export const importCaseCoordinators = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/case-coordinators/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};