import {fetchIncidentReasonRequest} from '@/utils/services/incidents/settings/reasons';

const state = {
    reason: {},
}

const getters = {
    getIncidentReason: state => state.reason
}

const actions = {
    async fetchIncidentReason({ commit }, id) {
        const res = await fetchIncidentReasonRequest(id)
        commit('setIncidentReason', res.data.data)

    },
}

const mutations = {
    setIncidentReason(state, payload) {
        state.reason = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}