import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default [
    {
        path: 'workerdashboard',
        name: 'WorkerDashboard',
        component: () =>
            import('@/views/homecareworkers/view/dashboard'),
    },
    
    {
        path: 'homecareworkers',
        name: 'Homecareworkers',
        component: () =>
            import ('@/views/homecareworkers'),
        meta: {
            check: 'show_home_care_workers',
            },
        beforeEnter: checkPermissions,
    },
    {
        path: '/homecareworkers/add',
        name: 'Add Homecareworker',
        component: () =>
            import ("@/views/homecareworkers/create"),
    },
    {
        path: 'homecareworkers/update/:id',
        name: 'Home Detail',
        component: () =>
            import ('@/views/homecareworkers/edit'),
    },
    {
        path: 'homecareworkers/view/:id',
        name: 'HomeCareWorkerUserServiceBoard',
        component: () =>
            import ('@/views/homecareworkers/view'),
    },
    {
        path: 'homecareworkers/works',
        name: 'Work Detail',
        component: () =>
            import ('@/views/homecareworkers/components/work/index.vue'),
    },
    {
        path: 'homecareworkers/work/:id',
        name: 'Work Details',
        component: () =>
            import ('@/views/homecareworkers/update/work'),
    },

    {
        path: 'homecareworkers/skills/:id',
        name: 'Skills Details',
        component: () =>
            import ('@/views/homecareworkers/update/qualification/partials/Skills.vue'),
    },
    {
        path: 'homecareworkers/job-experience/:id',
        name: 'Job Experience Details',
        component: () =>
            import ('@/views/homecareworkers/update/qualification/partials/Experience.vue'),
    },
    {
        path: 'homecareworkers/education/:id',
        name: 'Education Details',
        component: () =>
            import ('@/views/homecareworkers/update/qualification/partials/Education.vue'),
    },
    {
        path: 'homecareworkers/benefits/:id',
        name: 'Benefit Details',
        component: () =>
            import ('@/views/homecareworkers/update/financial/partials/Benefits.vue'),
    },
    {
        path: 'homecareworkers/bank/:id',
        name: 'Bank Details',
        component: () =>
            import ('@/views/homecareworkers/update/financial/partials/BankInfo.vue'),
    },
    {
        path: 'homecareworkers/compensation/:id',
        name: 'Compensation Details',
        component: () =>
            import ('@/views/homecareworkers/update/financial/partials/Compensation.vue'),
    },
    {
        path: 'homecareworkers/dependant/:id',
        name: 'Dependant Details',
        component: () =>
            import ('@/views/homecareworkers/update/family/partials/Dependants.vue'),
    },
    {
        path: 'homecareworkers/emergency-contact/:id',
        name: 'Contact Details',
        component: () =>
            import ('@/views/homecareworkers/update/family/partials/EmergencyContacts.vue'),
    },
    {
        path: 'homecareworkers/administrative-role/:id',
        name: 'Administrative Role Details',
        component: () =>
            import ('@/views/homecareworkers/update/roles'),
    },
    
]