import { getRoleUser } from '../../utils/services/permission/index';

import {getNotifications, getUserNotifications} from "@/utils/services/notifications";


export const actions = {

    async fetchNotifications({ commit }, payload) {
        const res = await getNotifications(payload);
        commit('setNotifications', res.data)
      },
      
      async fetchUserNotifications({ commit }, payload) {
        const res = await getUserNotifications(payload);
        commit('setUserNotifications', res);

        return res;
      },


}



