import * as api from '@/utils/services/clients/settings/vaccineBrands';

const state = {
    vaccineBrand: {},
    vaccineBrandList: [],
}

const getters = {
    getClientVaccineBrand: state => state.vaccineBrand,
    getClientVaccineBrandList: state => state.vaccineBrandList
}

const actions = {

    async fetchClientVaccineBrands({ commit }) {
        const res = await api.getClientVaccineBrands();
        commit('setClientVaccineBrandList', res.data)
        return res.data;
    },

    async saveClientVaccineBrand({ commit }, payload) {
        const res = await api.storeClientVaccineBrand(payload);
        commit('setClientVaccineBrand', res.data)
    },

    async updateClientVaccineBrand({ commit }, payload, id) {
        const res = await api.updateClientVaccineBrand(payload, id);
        commit('setClientVaccineBrand', res.data)
    },

    async showClientVaccineBrand({ commit }, id) {
        const res = await api.showClientVaccineBrand(id);
        commit('setClientVaccineBrand', res.data)
        return res.data;
    },

    async deleteClientVaccineBrand({ commit }, id) {
        const res = await api.deleteClientVaccineBrand(id);
        commit('setClientVaccineBrandList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientVaccineBrand(state, payload) {
        state.vaccineBrand = payload
    },

    setClientVaccineBrandList(state, payload) {
        state.vaccineBrandList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}