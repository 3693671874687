import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'clients/settings/treatments';

export const getClientTreatments = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const getClientTreatmentsByDiagnosis = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}/diagnosis`, Header);
    } catch (error) {
        return error
    }
}

export const storeClientTreatment = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateClientTreatment = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showClientTreatment = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteClientTreatment = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
