import * as api from '@/utils/services/clients';
import adminCareTeam from './settings/adminCareTeam';
import hospitals from './settings/hospitals';
import diagnosis from './settings/diagnosis';
import diagnosisTypes from './settings/diagnosisTypes';
import vaccineTypes from './settings/vaccineTypes';
import vaccineBrands from './settings/vaccineBrands';
import vaccineDoses from './settings/vaccineDoses';
import recurrenceRates from './settings/recurrenceRates';
import newProblems from './settings/newProblems';
import supplyTypes from './settings/supplyTypes';
import nutrition from './settings/nutrition';
import allergy from './settings/allergy';
import currentUsage from './settings/currentUsage';
import medicationManagement from './settings/medicationManagement';
import medicationManagementDosage from './settings/medicationManagementDosage';
import medicationManagementFrequency from './settings/medicationManagementFrequency';
import medicationManagementRoute from './settings/medicationManagementRoute';
import physicianType from './settings/physicianType';
import specialistType from './settings/specialistType';
import primaryPhysician from './settings/primaryPhysician';
import primarySpecialist from './settings/primarySpecialist';
import primaryContact from './settings/primaryContact';
import insuranceType from './settings/insuranceType';
import relationship from './settings/relationship';
import mentalStatus from './settings/mentalStatus';
import mood from './settings/mood';
import activitiesPermitted from './settings/activitiesPermitted';
import dmeSupply from './settings/dmeSupply';
import functionalLimitation from './settings/functionalLimitation';
import goal from './settings/goal';
import hoFall from './settings/hoFall';
import medicalEquipment from './settings/medicalEquipment';
import precaution from './settings/precaution';
import prognosis from './settings/prognosis';
import safetyMeasure from './settings/safetyMeasure';
import supplement from './settings/supplement';
import fluid from './settings/fluid';
import authorizationContractType from './settings/authorizationContractType';
import authorizationServiceType from './settings/authorizationServiceType';
import authorizationRequestedService from './settings/authorizationRequestedService';
import authorizationSource from './settings/authorizationSource';
import livingAlone from './settings/livingAlone';
import livingWith from './settings/livingWith';
import livingSituation from './settings/livingSituation';
import symptoms from './settings/symptoms';
import treatments from './settings/treatments';
import boosterRequired from './settings/boosterRequired';
import taskActivityTask from './settings/taskActivityTask';
import taskActivitySubtask from './settings/taskActivitySubtask';
import taskActivityFrequency from './settings/taskActivityFrequency';
import taskActivityApplicable from './settings/taskActivityApplicable';


const state = {
    client: {},
    clients: [],
    workers: [],
    userWorker: {},
    client_details: {},
    client_incidents: [],
    client_dashboard: {},
    clientDependant: {},
    clientDependantList: [],
    clientEmergencyContact: {},
    clientEmergencyContactList: [],
    clientDiagnosis: {},
    clientDiagnosisList: [],
    clientImmunization: {},
    clientImmunizationList: [],
    // state properties for the admin care team
    adminCareTeamRoles: [],
    adminCareTeamSettings: {},
    clientAdminCareTeamX: [],
    clientHospitalizationHistory: {},
    clientHospitalizationHistoryList: [],
    clientIllnessHistory: {},
    clientIllnessHistoryList: [],
    clientMedicationManagement: {},
    clientMedicationManagementList: [],
    clientSupply: {},
    clientSupplyList: [],
    clientHealthItem: {},
    clientNutritionList: [],
    clientAllergyList: [],
    clientMedicalEquipmentList: [],
    clientPhysician: {},
    clientPhysicianList: [],
    clientSpecialist: {},
    clientSpecialistList: [],
    clientInsurance: {},
    clientInsuranceList: [],
    clientAuthorization: {},
    clientAuthorizationList: [],
    clientM11q: {},
    clientM11qList: [],
    clientHealthProxy: {},
    clientHealthProxyList: [],
    clientCarePlanSummary: {},
    clientCarePlanSummaryList: [],
    clientCarePlanBasicInfo: {},
    clientCarePlanBasicInfoList: [],
    clientCarePlanPatient: {},
    clientCarePlanPatientList: [],
    clientCarePlanOtherInfo: {},
    clientCarePlanOtherInfoList: [],
    clientCarePlanTaskActivity: {},
    clientCarePlanTaskActivityList: [],

    clientCarePlanPersonalCareList: [],
    clientCarePlanTransferList: [],
    clientCarePlanHouseKeepingList: [],
    clientCarePlanMedicationList: [],
    clientCarePlanMiscellaneousList: [],
    clientCarePlanNutritionList: [],
    clientCarePlanMobilityList: [],
    clientCarePlanOthersList: [],



    clientForm485Summary: {},
    clientForm485SummaryList: [],
    clientForm485BasicInfo: {},
    clientForm485BasicInfoList: [],
    clientForm485OtherInfo: {},
    clientForm485OtherInfoList: [],
    clientForm485EligibilityInfo: {},
    clientForm485EligibilityInfoList: [],
    clientForm485PatientInfo: {},
    clientForm485PatientInfoList: [],

    clientForm485Diagnosis: {},
    clientForm485DiagnosisList: [],
    clientForm485Medication: {},
    clientForm485MedicationList: [],

    clientAssignedNursesList: [],
    clientAssignedHomecareworkersList: [],

    //SETTINGS
    ...adminCareTeam.state,
    ...hospitals.state,
    ...diagnosis.state,
    ...diagnosisTypes.state,
    ...vaccineTypes.state,
    ...vaccineBrands.state,
    ...vaccineDoses.state,
    ...recurrenceRates.state,
    ...newProblems.state,
    ...supplyTypes.state,
    ...nutrition.state,
    ...allergy.state,
    ...currentUsage.state,
    ...medicationManagement.state,
    ...medicationManagementDosage.state,
    ...medicationManagementFrequency.state,
    ...medicationManagementRoute.state,
    ...physicianType.state,
    ...specialistType.state,
    ...primaryPhysician.state,
    ...primarySpecialist.state,
    ...primaryContact.state,
    ...insuranceType.state,
    ...relationship.state,
    ...mentalStatus.state,
    ...mood.state,
    ...activitiesPermitted.state,
    ...dmeSupply.state,
    ...functionalLimitation.state,
    ...goal.state,
    ...hoFall.state,
    ...medicalEquipment.state,
    ...precaution.state,
    ...prognosis.state,
    ...safetyMeasure.state,
    ...supplement.state,
    ...fluid.state,
    ...authorizationContractType.state,
    ...authorizationServiceType.state,
    ...authorizationRequestedService.state,
    ...authorizationSource.state,
    ...livingAlone.state,
    ...livingWith.state,
    ...livingSituation.state,
    ...symptoms.state,
    ...treatments.state,
    ...boosterRequired.state,
    ...taskActivityTask.state,
    ...taskActivitySubtask.state,
    ...taskActivityFrequency.state,
    ...taskActivityApplicable.state,

}

const getters = {
    getClient: state => state.client,
    listClients: state => state.clients,
    listClientDetails: state => state.client_details,
    listWorkers: state => state.workers,
    getWorker: state => state.worker,
    getUserWorker: state => state.userWorker,
    listClientIncidents: state => state.client_incidents,
    getClientDependant: state => state.clientDependant,
    getClientDependantList: state => state.clientDependantList,
    getClientEmergencyContact: state => state.clientEmergencyContact,
    getClientEmergencyContactList: state => state.clientEmergencyContactList,
    getClientDiagnosis: state => state.clientDiagnosis,
    getClientDiagnosisList: state => state.clientDiagnosisList,
    getClientImmunization: state => state.clientImmunization,
    getClientImmunizationList: state => state.clientImmunizationList,
    // admin care team roles
    getAdminCareTeamRoles: state => state.adminCareTeamRoles,
    getAdminCareTeamSettings: state => state.adminCareTeamSettings,
    getClientAdminCareTeamX: state => state.clientAdminCareTeamX,
    getClientHospitalizationHistory: state => state.clientHospitalizationHistory,
    getClientHospitalizationHistoryList: state => state.clientHospitalizationHistoryList,
    getClientIllnessHistory: state => state.clientIllnessHistory,
    getClientIllnessHistoryList: state => state.clientIllnessHistoryList,
    getClientMedicationManagement: state => state.clientMedicationManagement,
    getClientMedicationManagementList: state => state.clientMedicationManagementList,
    getClientSupply: state => state.clientSupply,
    getClientSupplyList: state => state.clientSupplyList,
    getClientHealthItem: state => state.clientHealthItem,
    getClientNutritionList: state => state.clientNutritionList,
    getClientAllergyList: state => state.clientAllergyList,
    getClientMedicalEquipmentList: state => state.clientMedicalEquipmentList,
    getClientPhysician: state => state.clientPhysician,
    getClientPhysicianList: state => state.clientPhysicianList,
    getClientSpecialist: state => state.clientSpecialist,
    getClientSpecialistList: state => state.clientSpecialistList,
    getClientInsurance: state => state.clientInsurance,
    getClientInsuranceList: state => state.clientInsuranceList,
    getClientAuthorization: state => state.clientAuthorization,
    getClientAuthorizationList: state => state.clientAuthorizationList,
    getClientM11q: state => state.clientM11q,
    getClientM11qList: state => state.clientM11qList,
    getClientHealthProxy: state => state.clientHealthProxy,
    getClientHealthProxyList: state => state.clientHealthProxyList,
    getClientCarePlanSummary: state => state.clientCarePlanSummary,
    getClientCarePlanSummaryList: state => state.clientCarePlanSummaryList,
    getClientCarePlanBasicInfo: state => state.clientCarePlanBasicInfo,
    getClientCarePlanBasicInfoList: state => state.clientCarePlanBasicInfoList,
    getClientCarePlanPatient: state => state.clientCarePlanPatient,
    getClientCarePlanPatientList: state => state.clientCarePlanPatientList,
    getClientCarePlanOtherInfo: state => state.clientCarePlanOtherInfo,
    getClientCarePlanOtherInfoList: state => state.clientCarePlanOtherInfoList,
    getClientCarePlanTaskActivity: state => state.clientCarePlanTaskActivity,
    getClientCarePlanTaskActivityList: state => state.clientCarePlanTaskActivityList,

    getClientCarePlanPersonalCareList: state => state.clientCarePlanPersonalCareList,
    getClientCarePlanTransferList: state => state.clientCarePlanTransferList,
    getClientCarePlanHouseKeepingList: state => state.clientCarePlanHouseKeepingList,
    getClientCarePlanMedicationList: state => state.clientCarePlanMedicationList,
    getClientCarePlanMiscellaneousList: state => state.clientCarePlanMiscellaneousList,
    getClientCarePlanNutritionList: state => state.clientCarePlanNutritionList,
    getClientCarePlanMobilityList: state => state.clientCarePlanMobilityList,
    getClientCarePlanOthersList: state => state.clientCarePlanOthersList,



    /**
     * FORM 485
     */

    getClientForm485Summary: state => state.clientForm485Summary,
    getClientForm485SummaryList: state => state.clientForm485SummaryList,

    getClientForm485BasicInfo: state => state.clientForm485BasicInfo,
    getClientForm485BasicInfoList: state => state.clientForm485BasicInfoList,

    getClientForm485OtherInfo: state => state.clientForm485OtherInfo,
    getClientForm485OtherInfoList: state => state.clientForm485OtherInfoList,

    getClientForm485EligibilityInfo: state => state.clientForm485EligibilityInfo,
    getClientForm485EligibilityInfoList: state => state.clientForm485EligibilityInfoList,

    getClientForm485PatientInfo: state => state.clientForm485PatientInfo,
    getClientForm485PatientInfoList: state => state.clientForm485PatientInfoList,

    getClientForm485Diagnosis: state => state.clientForm485Diagnosis,
    getClientForm485DiagnosisList: state => state.clientForm485DiagnosisList,

    getClientForm485Medication: state => state.clientForm485Medication,
    getClientForm485MedicationList: state => state.clientForm485MedicationList,
    
    listClientDashboardData: state => state.client_dashboard,
    
    getClientAssignedNursesList: state => state.clientAssignedNursesList,
    getClientAssignedHomecareworkersList: state => state.clientAssignedHomecareworkersList,

    //SETTINGS
    ...hospitals.getters,
    ...adminCareTeam.getters,
    ...diagnosis.getters,
    ...diagnosisTypes.getters,
    ...vaccineTypes.getters,
    ...vaccineBrands.getters,
    ...vaccineDoses.getters,
    ...recurrenceRates.getters,
    ...newProblems.getters,
    ...supplyTypes.getters,
    ...nutrition.getters,
    ...allergy.getters,
    ...currentUsage.getters,
    ...medicationManagement.getters,
    ...medicationManagementDosage.getters,
    ...medicationManagementFrequency.getters,
    ...medicationManagementRoute.getters,
    ...physicianType.getters,
    ...specialistType.getters,
    ...primaryPhysician.getters,
    ...primarySpecialist.getters,
    ...primaryContact.getters,
    ...insuranceType.getters,
    ...relationship.getters,
    ...mentalStatus.getters,
    ...mood.getters,
    ...activitiesPermitted.getters,
    ...dmeSupply.getters,
    ...functionalLimitation.getters,
    ...goal.getters,
    ...hoFall.getters,
    ...medicalEquipment.getters,
    ...precaution.getters,
    ...prognosis.getters,
    ...safetyMeasure.getters,
    ...supplement.getters,
    ...fluid.getters,
    ...authorizationContractType.getters,
    ...authorizationServiceType.getters,
    ...authorizationRequestedService.getters,
    ...authorizationSource.getters,
    ...livingAlone.getters,
    ...livingWith.getters,
    ...livingSituation.getters,
    ...symptoms.getters,
    ...treatments.getters,
    ...boosterRequired.getters,
    ...taskActivityTask.getters,
    ...taskActivitySubtask.getters,
    ...taskActivityFrequency.getters,
    ...taskActivityApplicable.getters,

}
const actions = {

     async getClientDashboardData({ commit }, params) {
        try {
            const response = await api.getClientDashboardData(params);
            commit('setClientDashboardData', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchAllClients({commit}, params) {
        try {
            const response = await api.getClients(params);
            commit('setClients', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchAllWorkers({ commit }, params) {
        try {
            const response = await api.getWorkers(params);
            commit('setWorkers', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchWorker({ commit }, params) {
        try {
            const response = await api.getWorker(params);
            commit('setWorker', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchUserWorker({ commit }) {
        try {
            const response = await api.getUserWorker();
            commit('setUserWorker', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchClientLog({commit}, params) {
        try {
            const response = await api.getClientLog(params);
            commit('setClients', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    // async fetchClientLog({ commit }, { page, perPage }) {
    //     try {
    //         const response = await api.getClientLog({ page, perPage });
    //         console.log(response.data.data);
    //         commit('setClients', response.data.data);
    //         return response.data.data;
    //     } catch (error) {
    //         throw error.response;
    //     }
    // },

    async fetchClientIncidents({commit},id) {
        const res = await api.getClientIncidents(id);
        commit('setClientIncidents',res.data.data);
        return res.data.data;
    },

    async addClient({commit},params) {
        try {
            const response = await api.storeClient(params);

            commit('setClient', response.data);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateClientInfo({commit}, params) {
        try {
            const response = await api.updateClientInfo(params);
            commit('setClient', response.data);
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateClientAvatar(content, params) {
        try {
            const response = await api.updateClientAvatar(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async deleteClient(content, id) {
        try {
            const response = await api.deleteClient(id);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateClientServiceInfo(content, params) {
        try {
            const response = await api.updateClientServiceInfo(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateClientPhysicianInfo(content, params) {
        try {
            const response = await api.updateClientPhysicianInfo(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateClientProxies(content, params) {
        try {
            const response = await api.updateClientProxies(params);
          
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchClientDetails({commit}, params) {
        try {
            const response = await api.getClientDetails(params);
            commit('setClientDetails', response.data.data);
            return response.data.data;
        } catch (error) {
            
            throw error;
        }
    },

    async assignNurse({commit}, params) {
        try {
            const response = await api.assignClientNurse(params);
            
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async assignCaseCoordinator(content, params) {
        try {
            const response = await api.assignCoord(params);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async assignHomecareworker({commit}, params) {
        try {
            const response = await api.assignHomecareworker(params);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },


    async unassignNurse({ commit }, id) {
        const res = await api.removeNurse(id);
        
        return res;
    },

    async unassignHcw({commit}, id) {
        const res = await api.removeHomecareworker(id);
        
        return res;
    },

    async unassignCoord(content, params) {
        try {
            const response = await api.removeCoord(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async fetchClientAssignedNurses({commit},id) {
        const res = await api.clientAssignedNurses(id);
        
        commit('setClientAssignedNursesList',res.data.data);
    },

    async fetchClientAssignedHomecareworkers({commit},id) {
        const res = await api.clientAssignedHomecareworkers(id);
        
        commit('setClientAssignedHomecareworkersList',res.data.data);
    },

    async saveClientDiagnosis({ commit }, payload) {
        const res = await api.storeClientDiagnosis(payload);
        
        commit('setClientDiagnosis', res.data)
    },

    async updateClientDiagnosis({ commit }, payload) {
        const res = await api.updateClientDiagnosis(payload);
        
        commit('setClientDiagnosis', res.data)
    },

    async deleteClientDiagnosis({ commit }, id) {
        const res = await api.deleteClientDiagnosis(id);
        
        return res.data;
    },

    async showClientDiagnosis({ commit }, id) {
        const res = await api.showClientDiagnosis(id);
        
        return res.data;
    },

    async fetchClientDiagnosisLog({ commit }, id) {
        const res = await api.clientDiagnosisLog(id);
        
        commit('setClientDiagnosisList', res.data.data);
        return res.data.data
    },


    async saveClientImmunization({ commit }, payload) {
        const res = await api.storeClientImmunization(payload);
        
        commit('setClientImmunization', res.data)
    },

    async updateClientImmunization({ commit }, payload) {
        const res = await api.updateClientImmunization(payload);
        
        commit('setClientImmunization', res.data)
    },

    async deleteClientImmunization({ commit }, id) {
        const res = await api.deleteClientImmunization(id);
        
        return res.data;
    },

    async showClientImmunization({ commit }, id) {
        const res = await api.showClientImmunization(id);
        
        return res.data;
    },

    async fetchClientImmunizationLog({ commit }, id) {
        const res = await api.clientImmunizationLog(id);
        
        commit('setClientImmunizationList', res.data.data);
        return res.data.data;
    },

    async saveClientIllnessHistory({ commit }, payload) {
        const res = await api.storeClientIllnessHistory(payload);
        
        commit('setClientIllnessHistory', res.data)
    },

    async updateClientIllnessHistory({ commit }, payload) {
        const res = await api.updateClientIllnessHistory(payload);
        
        commit('setClientIllnessHistory', res.data)
    },

    async deleteClientIllnessHistory({ commit }, id) {
        const res = await api.deleteClientIllnessHistory(id);
        
        return res.data;
    },

    async showClientIllnessHistory({ commit }, id) {
        const res = await api.showClientIllnessHistory(id);
        
        return res.data;
    },

    async fetchClientIllnessHistoryLog({ commit }, id) {
        const res = await api.clientIllnessHistoryLog(id);
        
        commit('setClientIllnessHistoryList', res.data.data);
        return res.data.data;
    },

    async saveClientHospitalizationHistory({ commit }, payload) {
        const res = await api.storeClientHospitalizationHistory(payload);
        
        commit('setClientHospitalizationHistory', res.data)
    },

    async updateClientHospitalizationHistory({ commit }, payload) {
        const res = await api.updateClientHospitalizationHistory(payload);
        
        commit('setClientHospitalizationHistory', res.data)
    },

    async deleteClientHospitalizationHistory({ commit }, id) {
        const res = await api.deleteClientHospitalizationHistory(id);
        
        return res.data;
    },

    async showClientHospitalizationHistory({ commit }, id) {
        const res = await api.showClientHospitalizationHistory(id);
        
        return res.data;
    },

    async fetchClientHospitalizationHistoryLog({ commit }, id) {
        const res = await api.clientHospitalizationHistoryLog(id);
        
        commit('setClientHospitalizationHistoryList', res.data.data);
        return res.data.data;
    },

    // 
     async fetchAdminCareTeamRoles({ commit }) {
        const res = await api.fetchAdminCareTeamRoles(); 
        commit('setAdminCareTeamRoles', res.data);
    },

    async saveAdminCareTeamSetting({ commit }, payload) {
        const res = await api.saveAdminCareTeamSetting(payload); 
        commit('setAdminCareTeamSettings', res.data);
    },

    async updateAdminCareTeamSetting({ commit }, payload) {
        const res = await api.updateAdminCareTeamSetting(payload); 
        commit('setAdminCareTeamSettings', res.data);
    },
    
    async fetchClientAdminCareTeamX({ commit }) {
        const res = await api.fetchClientAdminCareTeam(); 
        commit('setClientAdminCareTeamX', res.data);
    },

    async deleteAdminCareTeamSetting({ commit }, id) {
        const res = await api.deleteAdminCareTeamSetting(id); 
        return res.data;
    },

    async showAdminCareTeamSetting({ commit }, id) {
        const res = await api.showAdminCareTeamSetting(id); 
        return res.data;
    },

    async fetchAdminCareTeamSettings({ commit }) {
        const res = await api.fetchAdminCareTeamSettings(); 
        commit('setAdminCareTeamSettings', res.data);
    },

    async saveClientMedicationManagement({ commit }, payload) {
        const res = await api.storeClientMedicationManagement(payload);
        
        commit('setClientMedicationManagement', res.data)
    },

    async updateClientMedicationManagement({ commit }, payload) {
        const res = await api.updateClientMedicationManagement(payload);
        
        commit('setClientMedicationManagement', res.data)
    },

    async deleteClientMedicationManagement({ commit }, id) {
        const res = await api.deleteClientMedicationManagement(id);
        
        return res.data;
    },

    async showClientMedicationManagement({ commit }, id) {
        const res = await api.showClientMedicationManagement(id);
        
        return res.data;
    },

    async fetchClientMedicationManagementLog({ commit }, id) {
        const res = await api.clientMedicationManagementLog(id);
        commit('setClientMedicationManagementList', res.data.data);
        return res.data.data;
    },


    //SUPPLY
    async saveClientSupply({ commit }, payload) {
        const res = await api.storeClientSupply(payload);
        
        commit('setClientSupply', res.data)
    },

    async updateClientSupply({ commit }, payload) {
        const res = await api.updateClientSupply(payload);
        
        commit('setClientSupply', res.data)
    },

    async deleteClientSupply({ commit }, id) {
        const res = await api.deleteClientSupply(id);
        
        return res.data;
    },

    async showClientSupply({ commit }, id) {
        const res = await api.showClientSupply(id);
        
        return res.data;
    },

    async fetchClientSupplyLog({ commit }, id) {
        const res = await api.clientSupplyLog(id);
        
        commit('setClientSupplyList', res.data.data);
        return res.data.data;
    },

    //HEALTH ITEM
    async saveClientHealthItem({ commit }, payload) {
        const res = await api.storeClientHealthItem(payload);
        
        commit('setClientHealthItem', res.data)
    },

    async updateClientHealthItem({ commit }, payload) {
        const res = await api.updateClientHealthItem(payload);
        
        commit('setClientHealthItem', res.data)
    },

    async deleteClientHealthItem({ commit }, id) {
        const res = await api.deleteClientHealthItem(id);
        
        return res.data;
    },

    async showClientHealthItem({ commit }, id) {
        const res = await api.showClientHealthItem(id);
        
        return res.data;
    },

    async fetchClientNutritionLog({ commit }, id) {
        const res = await api.clientNutritionLog(id);
        
        commit('setClientNutritionList', res.data.data);
        return res.data.data;
    },

    async fetchClientAllergyLog({ commit }, id) {
        const res = await api.clientAllergyLog(id);
        
        commit('setClientAllergyList', res.data.data);
        return res.data.data;
    },

    async fetchClientMedicalEquipmentLog({ commit }, id) {
        const res = await api.clientMedicalEquipmentLog(id);
        
        commit('setClientMedicalEquipmentList', res.data);
        return res.data;
    },

 
    async saveClientPhysician({ commit }, payload) {
        const res = await api.storeClientPhysician(payload);
        
        commit('setClientPhysician', res.data)
    },

    async updateClientPhysician({ commit }, payload) {
        const res = await api.updateClientPhysician(payload);
        
        commit('setClientPhysician', res.data)
    },

    async deleteClientPhysician({ commit }, id) {
        const res = await api.deleteClientPhysician(id);
        
        return res.data;
    },

    async showClientPhysician({ commit }, id) {
        const res = await api.showClientPhysician(id);
        
        return res.data;
    },

    async fetchClientPhysicianLog({ commit }, id) {
        const res = await api.clientPhysicianLog(id);
        
        commit('setClientPhysicianList', res.data.data);
        return res.data.data;
    },


    async saveClientSpecialist({ commit }, payload) {
        const res = await api.storeClientSpecialist(payload);
        
        commit('setClientSpecialist', res.data)
    },

    async updateClientSpecialist({ commit }, payload) {
        const res = await api.updateClientSpecialist(payload);
        
        commit('setClientSpecialist', res.data)
    },

    async deleteClientSpecialist({ commit }, id) {
        const res = await api.deleteClientSpecialist(id);
        
        return res.data;
    },

    async showClientSpecialist({ commit }, id) {
        const res = await api.showClientSpecialist(id);
        
        return res.data;
    },

    async fetchClientSpecialistLog({ commit }, id) {
        const res = await api.clientSpecialistLog(id);
        
        commit('setClientSpecialistList', res.data.data);
        return res.data.data;
    },


    async saveClientInsurance({ commit }, payload) {
        const res = await api.storeClientInsurance(payload);
        
        commit('setClientInsurance', res.data)
    },

    async updateClientInsurance({ commit }, payload) {
        const res = await api.updateClientInsurance(payload);
        
        commit('setClientInsurance', res.data)
    },

    async deleteClientInsurance({ commit }, id) {
        const res = await api.deleteClientInsurance(id);
        
        return res.data;
    },

    async showClientInsurance({ commit }, id) {
        const res = await api.showClientInsurance(id);
        
        return res.data;
    },

    async fetchClientInsuranceLog({ commit }, id) {
        const res = await api.clientInsuranceLog(id);
        
        commit('setClientInsuranceList', res.data.data);
        return res.data.data;
    },

    async saveClientAuthorization({ commit }, payload) {
        const res = await api.storeClientAuthorization(payload);
        
        commit('setClientInsurance', res.data)
    },

    async updateClientAuthorization({ commit }, payload) {
        const res = await api.updateClientAuthorization(payload);
        
        commit('setClientAuthorization', res.data)
    },

    async deleteClientAuthorization({ commit }, id) {
        const res = await api.deleteClientAuthorization(id);
        
        return res.data;
    },

    async showClientAuthorization({ commit }, id) {
        const res = await api.showClientAuthorization(id);
        
        return res.data;
    },

    async fetchClientAuthorizationLog({ commit }, id) {
        const res = await api.clientAuthorizationLog(id);
        
        commit('setClientAuthorizationList', res.data.data);
        return res.data.data;
    },

    async saveClientM11q({ commit }, payload) {
        const res = await api.storeClientM11q(payload);
        
        commit('setClientM11q', res.data)
    },

    async updateClientM11q({ commit }, payload) {
        const res = await api.updateClientM11q(payload);
        
        commit('setClientM11q', res.data)
    },

    async deleteClientM11q({ commit }, id) {
        const res = await api.deleteClientM11q(id);
        
        return res.data;
    },

    async showClientM11q({ commit }, id) {
        const res = await api.showClientM11q(id);
        
        return res.data;
    },

    async fetchClientM11qLog({ commit }, id) {
        const res = await api.clientM11qLog(id);
        
        commit('setClientM11qList', res.data.data);
        return res.data.data;
    },


    async saveClientHealthProxy({ commit }, payload) {
        const res = await api.storeClientHealthProxy(payload);
        
        commit('setClientHealthProxy', res.data)
    },

    async updateClientHealthProxy({ commit }, payload) {
        const res = await api.updateClientHealthProxy(payload);
        
        commit('setClientHealthProxy', res.data)
    },

    async deleteClientHealthProxy({ commit }, id) {
        const res = await api.deleteClientHealthProxy(id);
        
        return res.data;
    },

    async showClientHealthProxy({ commit }, id) {
        const res = await api.showClientHealthProxy(id);
        
        return res.data;
    },

    async fetchClientHealthProxyLog({ commit }, id) {
        const res = await api.clientHealthProxyLog(id);
        
        commit('setClientHealthProxyList', res.data.data);
        return res.data.data;
    },

    async saveClientDependant({ commit }, payload) {
        const res = await api.storeClientDependant(payload);
        
        commit('setClientDependant', res.data)
    },

    async updateClientDependant({ commit }, payload) {
        const res = await api.updateClientDependant(payload);
        
        commit('setClientDependant', res.data)
    },

    async deleteClientDependant({ commit }, id) {
        const res = await api.deleteClientDependant(id);
        
        return res.data;
    },

    async showClientDependant({ commit }, id) {
        const res = await api.showClientDependant(id);
        
        return res.data;
    },

    async fetchClientDependantLog({ commit }, id) {
        const res = await api.clientDependantLog(id);
        
        commit('setClientDependantList', res.data.data);
        return res.data.data;
    },


    async saveClientEmergencyContact({ commit }, payload) {
        const res = await api.storeClientEmergencyContact(payload);
        
        commit('setClientEmergencyContact', res.data)
    },

    async updateClientEmergencyContact({ commit }, payload) {
        const res = await api.updateClientEmergencyContact(payload);
        
        commit('setClientEmergencyContact', res.data)
    },

    async deleteClientEmergencyContact({ commit }, id) {
        const res = await api.deleteClientEmergencyContact(id);
        
        return res.data;
    },

    async showClientEmergencyContact({ commit }, id) {
        const res = await api.showClientEmergencyContact(id);
        
        return res.data;
    },

    async fetchClientEmergencyContactLog({ commit }, id) {
        const res = await api.clientEmergencyContactLog(id);
        
        commit('setClientEmergencyContactList', res.data.data);
        return res.data.data;
    },


    async saveClientCarePlanSummary({ commit }, payload) {
        const res = await api.storeClientCarePlanSummary(payload);
        
        commit('setClientCarePlanSummary', res.data)
    },

    async updateClientCarePlanSummary({ commit }, payload) {
        const res = await api.updateClientCarePlanSummary(payload);
        
        commit('setClientCarePlanSummary', res.data)
    },

    async deleteClientCarePlanSummary({ commit }, id) {
        const res = await api.deleteClientCarePlanSummary(id);
        
        return res.data;
    },

    async showClientCarePlanSummary({ commit }, id) {
        const res = await api.showClientCarePlanSummary(id);
        
        return res.data;
    },

    async fetchClientCarePlanSummaryLog({ commit }, id) {
        const res = await api.clientCarePlanSummaryLog(id);
        
        commit('setClientCarePlanSummaryList', res.data.data);
        return res.data.data;
    },


    async saveClientCarePlanBasicInfo({ commit }, payload) {
        const res = await api.storeClientCarePlanBasicInfo(payload);
        
        commit('setClientCarePlanBasicInfo', res.data)
    },

    async updateClientCarePlanBasicInfo({ commit }, payload) {
        const res = await api.updateClientCarePlanBasicInfo(payload);
        
        commit('setClientCarePlanBasicInfo', res.data)
    },

    async deleteClientCarePlanBasicInfo({ commit }, id) {
        const res = await api.deleteClientCarePlanBasicInfo(id);
        
        return res.data;
    },

    async showClientCarePlanBasicInfo({ commit }, id) {
        const res = await api.showClientCarePlanBasicInfo(id);
        
        return res.data;
    },

    async fetchClientCarePlanBasicInfoLog({ commit }, id) {
        const res = await api.clientCarePlanBasicInfoLog(id);
        
        commit('setClientCarePlanBasicInfoList', res.data.data);
        return res.data.data;
    },


    async saveClientCarePlanPatient({ commit }, payload) {
        const res = await api.storeClientCarePlanPatient(payload);
        
        commit('setClientCarePlanPatient', res.data)
    },

    async updateClientCarePlanPatient({ commit }, payload) {
        const res = await api.updateClientCarePlanPatient(payload);
        
        commit('setClientCarePlanPatient', res.data)
    },

    async deleteClientCarePlanPatient({ commit }, id) {
        const res = await api.deleteClientCarePlanPatient(id);
        
        return res.data;
    },

    async showClientCarePlanPatient({ commit }, id) {
        const res = await api.showClientCarePlanPatient(id);
        
        return res.data;
    },

    async fetchClientCarePlanPatientLog({ commit }, id) {
        const res = await api.clientCarePlanPatientLog(id);
        
        commit('setClientCarePlanPatientList', res.data.data);
        return res.data.data;
    },

    //CLIENT CARE PLAN OtherInfo
    async saveClientCarePlanOtherInfo({ commit }, payload) {
        const res = await api.storeClientCarePlanOtherInfo(payload);
        
        commit('setClientCarePlanOtherInfo', res.data)
    },

    async updateClientCarePlanOtherInfo({ commit }, payload) {
        const res = await api.updateClientCarePlanOtherInfo(payload);
        
        commit('setClientCarePlanOtherInfo', res.data)
    },

    async deleteClientCarePlanOtherInfo({ commit }, id) {
        const res = await api.deleteClientCarePlanOtherInfo(id);
        
        return res.data;
    },

    async showClientCarePlanOtherInfo({ commit }, id) {
        const res = await api.showClientCarePlanOtherInfo(id);
        
        return res.data;
    },

    async fetchClientCarePlanOtherInfoLog({ commit }, id) {
        const res = await api.clientCarePlanOtherInfoLog(id);
        
        commit('setClientCarePlanOtherInfoList', res.data.data);
        return res.data.data;
    },

    //CLIENT CARE PLAN TaskActivity
    async saveClientCarePlanTaskActivity({ commit }, payload) {
        const res = await api.storeClientCarePlanTaskActivity(payload);
        
        commit('setClientCarePlanTaskActivity', res.data)
    },

    async updateClientCarePlanTaskActivity({ commit }, payload) {
        const res = await api.updateClientCarePlanTaskActivity(payload);
        
        commit('setClientCarePlanTaskActivity', res.data)
    },

    async deleteClientCarePlanTaskActivity({ commit }, id) {
        const res = await api.deleteClientCarePlanTaskActivity(id);
        
        return res.data;
    },

    async showClientCarePlanTaskActivity({ commit }, id) {
        const res = await api.showClientCarePlanTaskActivity(id);
        
        return res.data;
    },

    async fetchClientCarePlanTaskActivityLog({ commit }, id) {
        const res = await api.clientCarePlanTaskActivityLog(id);
        
        commit('setClientCarePlanTaskActivityList', res.data);
        return res.data;
    },

    async fetchClientCarePlanPersonalCareLog({ commit }, id) {
        const res = await api.clientCarePlanPersonalCareLog(id);
        commit('setClientCarePlanPersonalCareList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanTransferLog({ commit }, id) {
        const res = await api.clientCarePlanTransferLog(id);
        commit('setClientCarePlanTransferList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanHouseKeepingLog({ commit }, id) {
        const res = await api.clientCarePlanHouseKeepingLog(id);
        commit('setClientCarePlanHouseKeepingList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanMedicationLog({ commit }, id) {
        const res = await api.clientCarePlanMedicationLog(id);
        commit('setClientCarePlanMedicationListList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanMiscellaneousLog({ commit }, id) {
        const res = await api.clientCarePlanMiscellaneousLog(id);
        commit('setClientCarePlanMiscellaneousListList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanNutritionLog({ commit }, id) {
        const res = await api.clientCarePlanNutritionLog(id);
        commit('setClientCarePlanNutritionListList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanMobilityLog({ commit }, id) {
        const res = await api.clientCarePlanMobilityLog(id);
        commit('setClientCarePlanMobilityListList', res.data.data);
        return res.data.data;
    },

    async fetchClientCarePlanOthersLog({ commit }, id) {
        const res = await api.clientCarePlanOthersLog(id);
        commit('setClientCarePlanOthersListList', res.data.data);
        return res.data.data;
    },

 
        async saveClientForm485Summary({ commit }, payload) {
            const res = await api.storeClientForm485Summary(payload);
            
            commit('setClientForm485Summary', res.data)
        },
    
        async updateClientForm485Summary({ commit }, payload) {
            const res = await api.updateClientForm485Summary(payload);
            
            commit('setClientForm485Summary', res.data)
        },
    
        async deleteClientForm485Summary({ commit }, id) {
            const res = await api.deleteClientForm485Summary(id);
            
            return res.data;
        },
    
        async showClientForm485Summary({ commit }, id) {
            const res = await api.showClientForm485Summary(id);
            
            return res.data;
        },
    
        async fetchClientForm485SummaryLog({ commit }, id) {
            const res = await api.clientForm485SummaryLog(id);
            
            commit('setClientForm485SummaryList', res.data.data);
            return res.data.data;
        },

    async saveClientForm485BasicInfo({ commit }, payload) {
        const res = await api.storeClientForm485BasicInfo(payload);
        
        commit('setClientForm485BasicInfo', res.data)
    },

    async updateClientForm485BasicInfo({ commit }, payload) {
        const res = await api.updateClientForm485BasicInfo(payload);
        
        commit('setClientForm485BasicInfo', res.data)
    },

    async deleteClientForm485BasicInfo({ commit }, id) {
        const res = await api.deleteClientForm485BasicInfo(id);
        
        return res.data;
    },

    async showClientForm485BasicInfo({ commit }, id) {
        const res = await api.showClientForm485BasicInfo(id);
        
        return res.data;
    },

    async fetchClientForm485BasicInfoLog({ commit }, id) {
        const res = await api.clientForm485BasicInfoLog(id);
        
        commit('setClientForm485BasicInfoList', res.data.data);
        return res.data.data;
    },

        async saveClientForm485OtherInfo({ commit }, payload) {
            const res = await api.storeClientForm485OtherInfo(payload);
            
            commit('setClientForm485OtherInfo', res.data)
        },
    
        async updateClientForm485OtherInfo({ commit }, payload) {
            const res = await api.updateClientForm485OtherInfo(payload);
            
            commit('setClientForm485OtherInfo', res.data)
        },
    
        async deleteClientForm485OtherInfo({ commit }, id) {
            const res = await api.deleteClientForm485OtherInfo(id);
            
            return res.data;
        },
    
        async showClientForm485OtherInfo({ commit }, id) {
            const res = await api.showClientForm485OtherInfo(id);
            
            return res.data;
        },
    
        async fetchClientForm485OtherInfoLog({ commit }, id) {
            const res = await api.clientForm485OtherInfoLog(id);
            
            commit('setClientForm485OtherInfoList', res.data.data);
            return res.data.data;
        },

       
        async saveClientForm485EligibilityInfo({ commit }, payload) {
            const res = await api.storeClientForm485EligibilityInfo(payload);
            
            commit('setClientForm485EligibilityInfo', res.data)
        },
    
        async updateClientForm485EligibilityInfo({ commit }, payload) {
            const res = await api.updateClientForm485EligibilityInfo(payload);
            
            commit('setClientForm485EligibilityInfo', res.data)
        },
    
        async deleteClientForm485EligibilityInfo({ commit }, id) {
            const res = await api.deleteClientForm485EligibilityInfo(id);
            
            return res.data;
        },
    
        async showClientForm485EligibilityInfo({ commit }, id) {
            const res = await api.showClientForm485EligibilityInfo(id);
            
            return res.data;
        },
    
        async fetchClientForm485EligibilityInfoLog({ commit }, id) {
            const res = await api.clientForm485EligibilityInfoLog(id);
            
            commit('setClientForm485EligibilityInfoList', res.data.data);
            return res.data.data;
        },


        async saveClientForm485PatientInfo({ commit }, payload) {
            const res = await api.storeClientForm485PatientInfo(payload);
            
            commit('setClientForm485PatientInfo', res.data)
        },
    
        async updateClientForm485PatientInfo({ commit }, payload) {
            const res = await api.updateClientForm485PatientInfo(payload);
            
            commit('setClientForm485PatientInfo', res.data)
        },
    
        async deleteClientForm485PatientInfo({ commit }, id) {
            const res = await api.deleteClientForm485PatientInfo(id);
            
            return res.data;
        },
    
        async showClientForm485PatientInfo({ commit }, id) {
            const res = await api.showClientForm485PatientInfo(id);
            
            return res.data;
        },
    
        async fetchClientForm485PatientInfoLog({ commit }, id) {
            const res = await api.clientForm485PatientInfoLog(id);
            
            commit('setClientForm485PatientInfoList', res.data.data);
            return res.data.data;
        },

        async saveClientForm485Diagnosis({ commit }, payload) {
            const res = await api.storeClientForm485Diagnosis(payload);
            
            commit('setClientForm485Diagnosis', res.data)
        },
    
        async updateClientForm485Diagnosis({ commit }, payload) {
            const res = await api.updateClientForm485Diagnosis(payload);
            
            commit('setClientForm485Diagnosis', res.data)
        },
    
        async deleteClientForm485Diagnosis({ commit }, id) {
            const res = await api.deleteClientForm485Diagnosis(id);
            
            return res.data;
        },
    
        async showClientForm485Diagnosis({ commit }, id) {
            const res = await api.showClientForm485Diagnosis(id);
            
            return res.data;
        },
    
        async fetchClientForm485DiagnosisLog({ commit }, id) {
            const res = await api.clientForm485DiagnosisLog(id);
            
            commit('setClientForm485DiagnosisList', res.data.data);
            return res.data.data;
        },
        
        async saveClientForm485Medication({ commit }, payload) {
            const res = await api.storeClientForm485Medication(payload);
            
            commit('setClientForm485Medication', res.data)
        },
    
        async updateClientForm485Medication({ commit }, payload) {
            const res = await api.updateClientForm485Medication(payload);
            
            commit('setClientForm485Medication', res.data)
        },
    
        async deleteClientForm485Medication({ commit }, id) {
            const res = await api.deleteClientForm485Medication(id);
            
            return res.data;
        },
    
        async showClientForm485Medication({ commit }, id) {
            const res = await api.showClientForm485Medication(id);
            
            return res.data;
        },
    
        async fetchClientForm485MedicationLog({ commit }, id) {
            const res = await api.clientForm485MedicationLog(id);
            
            commit('setClientForm485MedicationList', res.data.data);
            return res.data.data;
        },

        // SETTINGS
        ...adminCareTeam.actions,
        ...hospitals.actions,
        ...diagnosis.actions,
        ...diagnosisTypes.actions,
        ...vaccineTypes.actions,
        ...vaccineBrands.actions,
        ...vaccineDoses.actions,
        ...recurrenceRates.actions,
        ...newProblems.actions,
        ...supplyTypes.actions,
        ...nutrition.actions,
        ...allergy.actions,
        ...currentUsage.actions,
        ...medicationManagement.actions,
        ...medicationManagementDosage.actions,
        ...medicationManagementFrequency.actions,
        ...medicationManagementRoute.actions,
        ...physicianType.actions,
        ...specialistType.actions,
        ...primaryPhysician.actions,
        ...primarySpecialist.actions,
        ...primaryContact.actions,
        ...insuranceType.actions,
        ...relationship.actions,
        ...mentalStatus.actions,
        ...mood.actions,
        ...activitiesPermitted.actions,
        ...dmeSupply.actions,
        ...functionalLimitation.actions,
        ...goal.actions,
        ...hoFall.actions,
        ...medicalEquipment.actions,
        ...precaution.actions,
        ...prognosis.actions,
        ...safetyMeasure.actions,
        ...supplement.actions,
        ...fluid.actions,
        ...authorizationContractType.actions,
        ...authorizationServiceType.actions,
        ...authorizationRequestedService.actions,
        ...authorizationSource.actions,
        ...livingAlone.actions,
        ...livingWith.actions,
        ...livingSituation.actions,
        ...symptoms.actions,
        ...treatments.actions,
        ...boosterRequired.actions,
        ...taskActivityTask.actions,
        ...taskActivitySubtask.actions,
        ...taskActivityFrequency.actions,
        ...taskActivityApplicable.actions,
}

const mutations = {

    setClient(state, client) {
        state.client = client
    },

    setClients(state, clients) {
        state.clients = clients
    },

    
    setWorkers(state, workers) {
        state.workers = workers
    },

    setWorker(state, worker) {
        state.worker = worker
    },


    setUserWorker(state, worker) {
        state.userWorker = worker
    },

    setClientDetails(state, client) {
        state.client_details = client
    },

    setClientIncidents(state, client) {
        state.client_incidents = client
    },
    setClientDashboardData(state, data) {
        state.client_dashboard = data
    },
   

    setClientDependant(state, client_dependant) {
        state.clientDependant = client_dependant
    },

    setClientDependantList(state, client_dependant_list) {
        state.clientDependantList = client_dependant_list
    },

    setClientEmergencyContact(state, client_emergency_contact) {
        state.clientEmergencyContact = client_emergency_contact
    },

    setClientEmergencyContactList(state, client_emergency_contact_list) {
        state.clientEmergencyContactList = client_emergency_contact_list
    },

    setClientHealthProxy(state, client_health_proxy) {
        state.clientHealthProxy = client_health_proxy
    },

    setClientHealthProxyList(state, client_health_proxy_list) {
        state.clientHealthProxyList = client_health_proxy_list
    },

    setClientInsurance(state, client_insurance) {
        state.clientInsurance = client_insurance
    },

    setClientInsuranceList(state, client_insurance_list) {
        state.clientInsuranceList = client_insurance_list
    },

    setClientAuthorization(state, client_authorization) {
        state.clientAuthorization = client_authorization
    },

    setClientAuthorizationList(state, client_authorization_list) {
        state.clientAuthorizationList = client_authorization_list
    },

    setClientM11q(state, client_m11q) {
        state.clientM11q = client_m11q
    },

    setClientM11qList(state, client_m11q_list) {
        state.clientM11qList = client_m11q_list
    },

    setClientPhysician(state, client_physician) {
        state.clientPhysician = client_physician
    },

    setClientPhysicianList(state, client_physician_list) {
        state.clientPhysicianList = client_physician_list
    },

    setClientSpecialist(state, client_specialist) {
        state.clientSpecialist = client_specialist
    },

    setClientSpecialistList(state, client_specialist_list) {
        state.clientSpecialistList = client_specialist_list
    },

    setClientDiagnosis(state, client_diagnosis) {
        state.clientDiagnosis = client_diagnosis
    },

    setClientDiagnosisList(state, client_diagnosis_list) {
        state.clientDiagnosisList = client_diagnosis_list
    },

    setClientImmunization(state, client_immunization) {
        state.clientImmunization = client_immunization
    },

    setClientImmunizationList(state, client_immunization_list) {
        state.clientImmunizationList = client_immunization_list
    },

    setClientHospitalizationHistory(state, client_hospitalization_history) {
        state.clientHospitalizationHistory = client_hospitalization_history
    },

    setClientHospitalizationHistoryList(state, client_hospitalization_history_list) {
        state.clientHospitalizationHistoryList = client_hospitalization_history_list
    },

    setAdminCareTeamRoles(state, roles) {
        state.adminCareTeamRoles = roles;
    },

    setAdminCareTeamSettings(state, settings) {
        state.adminCareTeamSettings = settings;
    },
    
    setClientAdminCareTeamX(state, clients) {
        state.clientAdminCareTeamX = clients;
    },

    setClientIllnessHistory(state, client_illness_history) {
        state.clientIllnessHistory = client_illness_history
    },

    setClientIllnessHistoryList(state, client_illness_history_list) {
        state.clientIllnessHistoryList = client_illness_history_list
    },

    setClientMedicationManagement(state, client_medication_management) {
        state.clientMedicationManagement = client_medication_management
    },

    setClientMedicationManagementList(state, client_medication_management_list) {
        state.clientMedicationManagementList = client_medication_management_list
    },

    setClientSupply(state, client_supply) {
        state.clientSupply = client_supply
    },

    setClientSupplyList(state, client_supply_list) {
        state.clientSupplyList = client_supply_list
    },

    setClientHealthItem(state, client_health_item) {
        state.clientHealthItem = client_health_item
    },

    setClientNutritionList(state, client_nutrition_list) {
        state.clientNutritionList = client_nutrition_list
    },

    setClientAllergyList(state, client_allergy_list) {
        state.clientAllergyList = client_allergy_list
    },

    setClientMedicalEquipmentList(state, client_medical_equipment_list) {
        state.clientMedicalEquipmentList = client_medical_equipment_list
    },

    setClientCarePlanSummary(state, client_care_plan_summary) {
        state.clientCarePlanSummary = client_care_plan_summary
    },

    setClientCarePlanSummaryList(state, client_care_plan_summary_list) {
        state.clientCarePlanSummaryList = client_care_plan_summary_list
    },

    setClientCarePlanBasicInfo(state, client_care_plan_basic_info) {
        state.clientCarePlanBasicInfo = client_care_plan_basic_info
    },

    setClientCarePlanBasicInfoList(state, client_care_plan_basic_info_list) {
        state.clientCarePlanBasicInfoList = client_care_plan_basic_info_list
    },

    setClientCarePlanPatient(state, client_care_plan_patient) {
        state.clientCarePlanPatient = client_care_plan_patient
    },

    setClientCarePlanPatientList(state, client_care_plan_patient_list) {
        state.clientCarePlanPatientList = client_care_plan_patient_list
    },

    setClientCarePlanOtherInfo(state, client_care_plan_other_info) {
        state.clientCarePlanOtherInfo = client_care_plan_other_info
    },

    setClientCarePlanOtherInfoList(state, client_care_plan_other_info_list) {
        state.clientCarePlanOtherInfoList = client_care_plan_other_info_list
    },

    setClientCarePlanTaskActivity(state, client_care_plan_task_activity) {
        state.clientCarePlanPatient = client_care_plan_task_activity
    },

    setClientCarePlanTaskActivityList(state, client_care_plan_task_activity_list) {
        state.clientCarePlanTaskActivityList = client_care_plan_task_activity_list
    },

    setClientCarePlanPersonalCareList(state, data) {
        state.clientCarePlanPersonalCareList = data
    },
    setClientCarePlanTransferList(state, data) {
        state.clientCarePlanTransferList = data
    },
    setClientCarePlanHouseKeepingList(state, data) {
        state.clientCarePlanHouseKeepingList = data
    },
    setClientCarePlanMedicationListList(state, data) {
        state.clientCarePlanMedicationList = data
    },
    setClientCarePlanMiscellaneousListList(state, data) {
        state.clientCarePlanMiscellaneousList = data
    },
    setClientCarePlanNutritionListList(state, data) {
        state.clientCarePlanNutritionList = data
    },
    setClientCarePlanMobilityListList(state, data) {
        state.clientCarePlanMobilityList = data
    },
    setClientCarePlanOthersListList(state, data) {
        state.clientCarePlanOthersList = data
    },



    setClientForm485Summary(state, client_form485_summary) {
        state.clientForm485Summary = client_form485_summary
    },

    setClientForm485SummaryList(state, client_form485_summary_list) {
        state.clientForm485SummaryList = client_form485_summary_list
    },

    setClientForm485BasicInfo(state, client_form485_basic_info) {
        state.clientForm485BasicInfo = client_form485_basic_info
    },

    setClientForm485BasicInfoList(state, client_form485_basic_info_list) {
        state.clientForm485BasicInfoList = client_form485_basic_info_list
    },

    setClientForm485OtherInfo(state, client_form485_other_info) {
        state.clientForm485OtherInfo = client_form485_other_info
    },

    setClientForm485OtherInfoList(state, client_form485_other_info_list) {
        state.clientForm485OtherInfoList = client_form485_other_info_list
    },

    setClientForm485EligibilityInfo(state, client_form485_eligibility_info) {
        state.clientForm485EligibilityInfo = client_form485_eligibility_info
    },

    setClientForm485EligibilityInfoList(state, client_form485_eligibility_info_list) {
        state.clientForm485EligibilityInfoList = client_form485_eligibility_info_list
    },

    setClientForm485PatientInfo(state, data) {
        state.clientForm485PatientInfo = data
    },

    setClientForm485PatientInfoList(state, data) {
        state.clientForm485PatientInfoList = data
    },


    setClientForm485Diagnosis(state, data) {
        state.clientForm485Diagnosis = data
    },

    setClientForm485DiagnosisList(state, data) {
        state.clientForm485DiagnosisList = data
    },

    setClientForm485Medication(state, data) {
        state.clientForm485Medication = data
    },

    setClientForm485MedicationList(state, data) {
        state.clientForm485MedicationList = data
    },

    setClientAssignedNursesList(state, data) {
        state.clientAssignedNursesList = data
    },

    setClientAssignedHomecareworkersList(state, data) {
        state.clientAssignedHomecareworkersList = data
    },

    //SETTINGS
    ...hospitals.mutations,
    ...adminCareTeam.mutations,
    ...diagnosis.mutations,
    ...diagnosisTypes.mutations,
    ...vaccineTypes.mutations,
    ...vaccineBrands.mutations,
    ...vaccineDoses.mutations,
    ...recurrenceRates.mutations,
    ...newProblems.mutations,
    ...supplyTypes.mutations,
    ...nutrition.mutations,
    ...allergy.mutations,
    ...currentUsage.mutations,
    ...medicationManagement.mutations,
    ...medicationManagementDosage.mutations,
    ...medicationManagementFrequency.mutations,
    ...medicationManagementRoute.mutations,
    ...physicianType.mutations,
    ...specialistType.mutations,
    ...primaryPhysician.mutations,
    ...primarySpecialist.mutations,
    ...primaryContact.mutations,
    ...insuranceType.mutations,
    ...relationship.mutations,
    ...mentalStatus.mutations,
    ...mood.mutations,
    ...activitiesPermitted.mutations,
    ...dmeSupply.mutations,
    ...functionalLimitation.mutations,
    ...goal.mutations,
    ...hoFall.mutations,
    ...medicalEquipment.mutations,
    ...precaution.mutations,
    ...prognosis.mutations,
    ...safetyMeasure.mutations,
    ...supplement.mutations,
    ...fluid.mutations,
    ...authorizationContractType.mutations,
    ...authorizationServiceType.mutations,
    ...authorizationRequestedService.mutations,
    ...authorizationSource.mutations,
    ...livingAlone.mutations,
    ...livingWith.mutations,
    ...livingSituation.mutations,
    ...symptoms.mutations,
    ...treatments.mutations,
    ...boosterRequired.mutations,
    ...taskActivityTask.mutations,
    ...taskActivitySubtask.mutations,
    ...taskActivityFrequency.mutations,
    ...taskActivityApplicable.mutations,
}

export default {
    state,
    getters,
    actions,
    mutations
}