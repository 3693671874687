import * as api from '@/utils/services/clients/settings/functionalLimitation';

const state = {
    functionalLimitation: {},
    functionalLimitationList: [],
}

const getters = {
    getClientFunctionalLimitation: state => state.functionalLimitation,
    getClientFunctionalLimitationList: state => state.functionalLimitationList
}

const actions = {

    async fetchClientFunctionalLimitation({ commit }) {
        const res = await api.getClientFunctionalLimitation();
        commit('setClientFunctionalLimitationList', res.data)
        return res.data;
    },

    async saveClientFunctionalLimitation({ commit }, payload) {
        const res = await api.storeClientFunctionalLimitation(payload);
        commit('setClientFunctionalLimitation', res.data)
    },

    async updateClientFunctionalLimitation({ commit }, payload, id) {
        const res = await api.updateClientFunctionalLimitation(payload, id);
        commit('setClientFunctionalLimitation', res.data)
    },

    async showClientFunctionalLimitation({ commit }, id) {
        const res = await api.showClientFunctionalLimitation(id);
        commit('setClientFunctionalLimitation', res.data)
        return res.data;
    },

    async deleteClientFunctionalLimitation({ commit }, id) {
        const res = await api.deleteClientFunctionalLimitation(id);
        commit('setClientFunctionalLimitationList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientFunctionalLimitation(state, payload) {
        state.functionalLimitation = payload
    },

    setClientFunctionalLimitationList(state, payload) {
        state.functionalLimitationList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}