import * as api from '@/utils/services/users/settings/petFriendly';

const state = {
    userPetFriendly: {},
    userPetFriendlyList: [],
}

const getters = {
    getUserPetFriendly: state => state.userPetFriendly,
    getUserPetFriendlyList: state => state.userPetFriendlyList
}

const actions = {

    async fetchUserPetFriendly({ commit }) {
        const res = await api.getUserPetFriendly();
        commit('setUserPetFriendlyList', res.data)
        return res.data;
    },

    async saveUserPetFriendly({ commit }, payload) {
        const res = await api.storeUserPetFriendly(payload);
        commit('setUserPetFriendly', res.data)
    },

    async updateUserPetFriendly({ commit }, payload, id) {
        const res = await api.updateUserPetFriendly(payload, id);
        commit('setUserPetFriendly', res.data)
    },

    async showUserPetFriendly({ commit }, id) {
        const res = await api.showUserPetFriendly(id);
        commit('setUserPetFriendly', res.data)
        return res.data;
    },

    async deleteUserPetFriendly({ commit }, id) {
        const res = await api.deleteUserPetFriendly(id);
        commit('setUserPetFriendlyList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserPetFriendly(state, payload) {
        state.userPetFriendly = payload
    },

    setUserPetFriendlyList(state, payload) {
        state.userPetFriendlyList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}