export default
  [
    {
      path: '/management',
      name: 'Management',
      component: () => import('@/views/management_modules/meetings'),
    },
    {
        path: '/meetings',
        name: 'MeetingsManagement',
        component: () => import('@/views/management_modules/meetings'),
      }
  ];