import * as api from '@/utils/services/users/settings/maritalStatus';

const state = {
    userMaritalStatus: {},
    userMaritalStatusList: [],
}

const getters = {
    getUserMaritalStatus: state => state.userMaritalStatus,
    getUserMaritalStatusList: state => state.userMaritalStatusList
}

const actions = {

    async fetchUserMaritalStatus({ commit }) {
        const res = await api.getUserMaritalStatus();
        commit('setUserMaritalStatusList', res.data)
        return res.data;
    },

    async saveUserMaritalStatus({ commit }, payload) {
        const res = await api.storeUserMaritalStatus(payload);
        commit('setUserMaritalStatus', res.data)
    },

    async updateUserMaritalStatus({ commit }, payload, id) {
        const res = await api.updateUserMaritalStatus(payload, id);
        commit('setUserMaritalStatus', res.data)
    },

    async showUserMaritalStatus({ commit }, id) {
        const res = await api.showUserMaritalStatus(id);
        commit('setUserMaritalStatus', res.data)
        return res.data;
    },

    async deleteUserMaritalStatus({ commit }, id) {
        const res = await api.deleteUserMaritalStatus(id);
        commit('setUserMaritalStatusList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserMaritalStatus(state, payload) {
        state.userMaritalStatus = payload
    },

    setUserMaritalStatusList(state, payload) {
        state.userMaritalStatusList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}