import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: '/other-staff',
        name: 'OtherStaff',
        component: () =>
            import ('@/views/otherstaff'),
        // meta: {
        //         check: 'client_management_access',
        //     },
        // beforeEnter: checkPermissions,
    },
    {
        path: '/other-staff/add',
        name: 'Add OtherStaff',
        component: () =>
            import ('@/views/otherstaff/create'),
        // meta: {
        //         check: 'client_management_access',
        //     },
        // beforeEnter: checkPermissions,
    },
    {
        path: 'other-staff/update/:id',
        name: 'Edit OtherStaff',
        component: () =>
            import ('@/views/otherstaff/edit'),
    },
]