import * as api from '@/utils/services/clients/settings/currentUsage';

const state = {
    currentUsage: {},
    currentUsageList: [],
}

const getters = {
    getClientCurrentUsage: state => state.currentUsage,
    getClientCurrentUsageList: state => state.currentUsageList
}

const actions = {

    async fetchClientCurrentUsages({ commit }) {
        const res = await api.getClientCurrentUsages();
        commit('setClientCurrentUsageList', res.data)
        return res.data;
    },

    async saveClientCurrentUsage({ commit }, payload) {
        const res = await api.storeClientCurrentUsage(payload);
        commit('setClientCurrentUsage', res.data)
    },

    async updateClientCurrentUsage({ commit }, payload, id) {
        const res = await api.updateClientCurrentUsage(payload, id);
        commit('setClientCurrentUsage', res.data)
    },

    async showClientCurrentUsage({ commit }, id) {
        const res = await api.showClientCurrentUsage(id);
        commit('setClientCurrentUsage', res.data)
        return res.data;
    },

    async deleteClientCurrentUsage({ commit }, id) {
        const res = await api.deleteClientCurrentUsage(id);
        commit('setClientCurrentUsageList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientCurrentUsage(state, payload) {
        state.currentUsage = payload
    },

    setClientCurrentUsageList(state, payload) {
        state.currentUsageList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}