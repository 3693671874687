import * as api from '@/utils/services/roles';


const state = {
    userRolePermissionInfo: null,
    roleInfo: {},
}

const getters = {

    listAuthUserRoleInfo: state => state.userRolePermissionInfo,
    listRoleInfo: state => state.roleInfo,
}

const actions = {

    //this is the real one
    async getAuthRolePermissionInfo({ commit }) {


        const res = await api.getAuthRolePermissionInfo();
        commit('setUserRolePermissionInfo', res.data)
        return res.data;

        // const res = await api.getAuthRolePermissionInfo();
        // if(!res)
        // {
        //    return res;
        // }else
        // {
        //     commit('setUserRolePermissionInfo', res.data);
        //     return res.data;
        // }        
       
    },

    async getRoleDetails({ commit }, id) {
        const res = await api.getRoleDetails(id);
        console.log('res:',res);
        commit('setRoleDetails', res.data)
        return res.data;
    },

}

const mutations = {

    setUserRolePermissionInfo(state, rolePerms) {
        state.userRolePermissionInfo = rolePerms
    },

    setRoleDetails(state, details) {
        state.roleInfo = details
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}