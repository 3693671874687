import axios from "axios";
import config from "@/config";
const { baseAPI, Header, PhotoHeader, AuthHeader } = config;

export const deleteUser = async (id) => {
    try {
        const res = await axios.post(`${baseAPI}/user/delete/${id}`, null, Header);

        return res
    } catch (error) {
        return error
    }
}

export const getUsers = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/user`, payload, Header);
    } catch (error) {
        return error
    }
}

export const getUserDetails = async (payload) => {
    try {
        return await axios.get(`${baseAPI}/user/details`, Header);
    } catch (error) {
        return error
    }
}

export const getUserStatus = async () => {
    try {
        return await axios.get(`${baseAPI}/statuses`, Header);
    } catch (error) {
        return error;
    }
}


export const updateStatus = async (params) => {
    try {
        return await axios.post(`${baseAPI}/statuses/edit-user/${params.user_id}`, params.status_id, Header);
    } catch (error) {
        return error;
    }
}

export const getAllNurses = async (params) => {
    if (params) {
        try {
            return await axios.get(`${baseAPI}/nurses?name=${params.name}&client_id=${params.client_id}`, Header);
        } catch (error) {
            return error;
        }
    } else {
        try {
            return await axios.get(`${baseAPI}/nurses?name=&client_id=`, Header);
        } catch (error) {
            return error;
        }
    }
};

export const getRoles = async () => {
    try {
        return await axios.get(`${baseAPI}/user/roles`, Header);
    } catch (error) {
        return error;
    }
};

export const getRoleDetails = async (params) => {
    try {
        return await axios.get(`${baseAPI}/user/roles/` + params, Header);
    } catch (error) {
        return error;
    }
};

export const storeUser = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/create`, params, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const importUser = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/user/import-user`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const getAllUsers = async () => {
    try {
        return await axios.get(`${baseAPI}/user/all`, Header);
    } catch (error) {
        return error;
    }
};

export const getSelectedUsers = async (params) => {
    try {
        return await axios.post(`${baseAPI}/permission/selected`, params, Header);
    } catch (error) {
        return error;
    }
};

export const getUserDetailsById = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/details/` + id, Header);
    } catch (error) {
        return error;
    }
};

export const getUserDetailsByIdentifier = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/details-from-id/` + id, Header);
    } catch (error) {
        return error;
    }
};

export const getUserProfile = async () => {
    try {
        return await axios.get(`${baseAPI}/user/profile`, Header);
    } catch (error) {
        return error
    }
};


export const getUserById = async (params) => {
    try {
        return await axios.get(`${baseAPI}/user/find/` + params, Header);
    } catch (error) {
        return error;
    }
};

export const updateUserById = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/user/update/${id}`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const storeEmergencyContact = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/emergency-contacts`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateEmergencyContact = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/emergency-contacts/${params.id}/update`, params, Header);
    } catch (error) {
        return error;
    }
};

export const showEmergencyContact = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/emergency-contacts/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteEmergencyContact = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user/emergency-contacts/` + id + '/delete', null, Header);
    } catch (error) {
        return error;
    }
};

export const getUserEmergencyContacts = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/emergency-contacts/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//education section
export const storeEducationDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/education-details`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateEducationDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/education-details/${params.id}/update`, params, Header);
    } catch (error) {
        return error;
    }
};

export const deleteEducationDetails = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user/education-details/` + id + '/delete', null, Header);
    } catch (error) {
        return error;
    }
};

export const showEducationDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/education-details/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const userEducationDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/education-details/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//medical section

export const storeMedicalDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/medical-details`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateMedicalDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/medical-details/${params.id}/update`, params, Header);
    } catch (error) {
        return error;
    }
};

export const deleteMedicalDetails = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user/medical-details/` + id + '/delete', null, Header);
    } catch (error) {
        return error;
    }
};

// transportation

export const storeTransportationMode = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/transportation-mode`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateTransportationMode = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/transportation-mode/${params.id}/update`, params, Header);
    } catch (error) {
        return error;
    }
};

//work section

export const storeWorkDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/work-details`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateWorkDetails = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/work-details/${params.id}/update`, params, Header);
    } catch (error) {
        return error;
    }
};

export const showWorkDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-details/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteWorkDetails = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user/work-details/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const getUserWorkDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-details/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

export const getWorkLocationByWork = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-location/work/${id}`, Header);
    } catch (error) {
        return error;
    }
};


/**
 * ================================================================
 * BEGIN USER GROUPS CALLS
 * ================================================================
 */
export const fetchAllUserGroups = async () => {
    try {
        return await axios.get(`${baseAPI}/user-groups`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserGroupDetailsById = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user-groups/` + id, Header);
    } catch (error) {
        return error;
    }
};


export const saveUserGroup = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-groups`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateUserGroup = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-groups/` + params['id'] + '/update', params, Header);
    } catch (error) {
        return error;
    }
};

export const deleteUserGroup = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user-groups/` + id + '/delete', null, Header);
    } catch (error) {
        return error;
    }
};

export const assignSupervisor = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-groups/` + params['id'] + '/assign-supervisor', params, Header);
    } catch (error) {
        return error;
    }
};

export const allUsers = async (search = null) => {
    try {
        return await axios.get(`${baseAPI}/user/all-users?search=${search}`, Header);
    } catch (error) {
        console.log('Error getting all users:', error);
        throw error;
    }
};

export const getUsersInGroups = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-groups/users`, params, Header);
    } catch (error) {
        return error;
    }
};

export const getUsersInGroup = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user-groups/${id}/users-in-group`, Header);
    } catch (error) {
        return error;
    }
};

export const getUsersNotInGroup = async (id, search = null) => {
    try {
        const params = {
            search: search,
        };

        return await axios.get(`${baseAPI}/user-groups/${id}/users-not-in-group`, {
            params: params,
            ...Header,
        });
    } catch (error) {
        console.error('Error getting user not in group:', error);
        throw error;
    }
};

export const addUserToGroup = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-groups/` + params['id'] + '/group-user', params, Header);
    } catch (error) {
        return error;
    }
};
/**
 * ================================================================
 * BEGIN USER LEVEL CALLS
 * ================================================================
 */
export const fetchAllUserLevels = async () => {
    try {
        return await axios.get(`${baseAPI}/user-levels`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserLevelDetailsById = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user-levels/` + id, Header);
    } catch (error) {
        return error;
    }
};


export const saveUserLevel = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-levels`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateUserLevel = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-levels/` + params['id'] + '/update', params, Header);
    } catch (error) {
        return error;
    }
};

export const deleteUserLevel = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user-levels/` + id + '/delete', null, Header);
    } catch (error) {
        return error;
    }
};

export const getUsersInLevel = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user-levels/${id}/users-in-level`, Header);
    } catch (error) {
        return error;
    }
};

export const getUsersNotInLevel = async (id, search = null) => {
    try {
        return await axios.get(`${baseAPI}/user-levels/${id}/users-not-in-level?search=${search}`, Header);
    } catch (error) {
        console.error('Error getting user not in level:', error);
        throw error;
    }
};

export const addUserToLevel = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user-levels/` + params['id'] + '/addUser-to-level', params, Header);
    } catch (error) {
        return error;
    }
};


//system roles section
export const getSystemRoles = async () => {
    try {
        return await axios.get(`${baseAPI}/user/system-roles`, Header);
    } catch (error) {
        return error;
    }
};

export const storeSystemRole = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/system-role/add`, params, Header);
    } catch (error) {
        console.error('Error storing system role:', error);
        throw error;
    }
};

export const updateSystemRole = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/update-system-role/${params.id}`, params.params, Header);
    } catch (error) {
        console.error('Error updating system role:', error);
        throw error;
    }
};

export const deleteSystemRole = async (id) => {
    try {
        return await axios.post(`${baseAPI}/user/delete-system-role/${id}`, null, Header);
    } catch (error) {
        console.error('Error deleting system role:', error);
        throw error;
    }
};

export const getUsersInSystemRole = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/users-in-system-role/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUsersNotInSystemRole = async (id, search = null) => {
    try {
        return await axios.get(`${baseAPI}/user/users-not-in-system-role/${id}?search=${search}`, Header);
    } catch (error) {
        console.error('Error getting user not in system role:', error);
        throw error;
    }
};

export const getSystemRoleDetails = async (id) => {
    try {
        return await axios.get(`${baseAPI}/user/system-role/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const addUserToSystemRole = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/add-user-to-system-role`, params, Header);
    } catch (error) {
        console.error('Error adding user to system role:', error);
        throw error;
    }
};

export const deleteUserFromSystemRole = async (params) => {
    try {
        return await axios.post(`${baseAPI}/user/remove-user-from-system-role`, params, Header);
    } catch (error) {
        console.error('Error removing user from system role:', error);
        throw error;
    }
};

//AUTH USER SYSTEM ROLE INFO
export const getAuthSystemRoleInfo = async () => {
    try {
        return await axios.get(`${baseAPI}/user/user-system-roles`, Header);
    } catch (error) {
        console.error('Error getting auth user system roles info:', error);
        throw error;
    }
};



// for clients
export const getUserDetailedReq = async (id) => {
    try {
        return await axios.get(`${baseAPI}/clients/${id}/details`, Header);
    } catch (error) {
        throw error;
    }
};

// for clients
export const assingedUserDetailedReq = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/clients/${id}/assign-caregiver`, payload, Header);
    } catch (error) {
        throw error;
    }
};

// assign admin care team
export const assignAdminCareTeam = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/clients/${id}/assign-caregiver`, payload, Header);
    } catch (error) {
        console.error("API request error:", error); // Improved error logging
        throw error;
    }
};

// homecareworkers / view / a6139b9b - 4bd6 - 465e-8bb6 - 4f5dd64321ca