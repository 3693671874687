import * as api from '@/utils/services/users/settings/complianceResolution';

const state = {
    userComplianceResolution: {},
    userComplianceResolutionList: [],
}

const getters = {
    getUserComplianceResolution: state => state.userComplianceResolution,
    getUserComplianceResolutionList: state => state.userComplianceResolutionList
}

const actions = {

    async fetchUserComplianceResolution({ commit }) {
        const res = await api.getUserComplianceResolution();
        commit('setUserComplianceResolutionList', res.data)
        return res.data;
    },

    async saveUserComplianceResolution({ commit }, payload) {
        const res = await api.storeUserComplianceResolution(payload);
        commit('setUserComplianceResolution', res.data)
    },

    async updateUserComplianceResolution({ commit }, payload, id) {
        const res = await api.updateUserComplianceResolution(payload, id);
        commit('setUserComplianceResolution', res.data)
    },

    async showUserComplianceResolution({ commit }, id) {
        const res = await api.showUserComplianceResolution(id);
        commit('setUserComplianceResolution', res.data)
        return res.data;
    },

    async deleteUserComplianceResolution({ commit }, id) {
        const res = await api.deleteUserComplianceResolution(id);
        commit('setUserComplianceResolutionList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserComplianceResolution(state, payload) {
        state.userComplianceResolution = payload
    },

    setUserComplianceResolutionList(state, payload) {
        state.userComplianceResolutionList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}