import { ref } from 'vue';
import { useStore } from 'vuex';
import { useLoading } from 'vue-loading-overlay';

  const permCheck = ref([]);
  const $loading = useLoading();

  export const checkPermissions = async (to, from, next) => {
    try {
      const store = useStore();
      const reqPermCheck = store.getters.listAuthUserRoleInfo;


      if (reqPermCheck) {
        permCheck.value = reqPermCheck;
      } else {
        const loader = $loading.show({
          text: 'Doing permission checks...',
        });
        const permissionCheck = await store.dispatch('getAuthRolePermissionInfo'); //this is important some how
        // console.log("permissionCheck", permissionCheck);
        permCheck.value = store.getters.listAuthUserRoleInfo;
        loader.hide();
      }

      const requestedSlug = to.meta?.check;
    //   const getPermission = permCheck.value.some(
    //     (permission) => permission.name === requestedSlug && permission.hasPermissions
    //   );


      const matchingPermission = permCheck.value.find(permission => permission.name.trim() === requestedSlug.trim());
      const getPermission = matchingPermission ? matchingPermission.hasPermission : false;

      //console.log(permCheck.value);

      if (requestedSlug === undefined || getPermission) {
        next();
      } else {
        console.log('User does not have permission');
        next({ name: 'NotAuthorized' });
      }
    } catch (error) {
    //   console.error('Failed to fetch user permissions:', error);
      next({ name: 'Error' });
    }
  };
