import * as api from '@/utils/services/clients/settings/nutrition';

const state = {
    nutritionSettings: {},
    nutritionSettingsList: [],
}

const getters = {
    getClientNutritionSettings: state => state.nutritionSettings,
    getClientNutritionSettingsList: state => state.nutritionSettingsList
}

const actions = {

    async fetchClientNutritionSettings({ commit }) {
        const res = await api.getClientNutritions();
        commit('setClientNutritionSettingsList', res.data)
        return res.data;
    },

    async saveClientNutritionSettings({ commit }, payload) {
        const res = await api.storeClientNutrition(payload);
        commit('setClientNutritionSettings', res.data)
    },

    async updateClientNutritionSettings({ commit }, payload, id) {
        const res = await api.updateClientNutrition(payload, id);
        commit('setClientNutritionSettings', res.data)
    },

    async showClientNutritionSettings({ commit }, id) {
        const res = await api.showClientNutrition(id);
        commit('setClientNutritionSettings', res.data)
        return res.data;
    },

    async deleteClientNutritionSettings({ commit }, id) {
        const res = await api.deleteClientNutrition(id);
        commit('setClientNutritionSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientNutritionSettings(state, payload) {
        state.nutritionSettings = payload
    },

    setClientNutritionSettingsList(state, payload) {
        state.nutritionSettingsList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}