export const mutations = {

    setTask(state, tasks) {
        state.tasks = tasks
    },

    setTaskUser(state, tasks) {
        state.tasks_user = tasks
    },

    setTaskTeam(state, tasks) {
        state.tasks_team = tasks
    },

    setTaskTemplate(state, template) {
        state.templates = template
    },

    setTaskModule(state, mod) {
        state.modules = mod
    },

    setTaskOverview(state, mod) {
        state.overview = mod
    },

    setTaskSelected(state, mod) {
        state.tasks_selected = mod
    },

    setTaskFormData(state, mod) {
        state.task_form_data = mod
    },

    setTaskItems(state, mod) {
        state.task_item = mod
    },

    setTaskItemsComplete(state, mod) {
        state.task_item_completed = mod
    },

    setTaskItemsOverdue(state, mod) {
        state.task_item_overdue = mod
    },

    setTaskSubItems(state, mod) {
        state.task_sub_item_selected = mod
    },

    setTaskSubItemsOpen(state, mod) {
        state.task_sub_item_open = mod
    },

    setTaskRelatedSubItems(state, mod) {
        state.task_sub_item_related = mod
    },

    setTaskCategories(state, category) {
        state.categories = category
    },

       setTaskStatuses(state, status) {
        state.statuses = status;
    },

    setFieldTemplate(state, field) {
        state.field_templates = field
    },

    setTaskTemplateDetails(state, template_detail) {
        state.taskTemplateDetails = template_detail;
    },

    setTaskTemplateField(state, detail) {
        state.task_template_detail_fields = detail
    },

    setAllTaskPriorities(state, data) {
        state.task_priorities = data
    },

    setTaskPriorityDetails(state, data) {
        state.task_priority_details = data
    },

    setAllTaskFrequencies(state, data) {
        state.task_frequencies = data
    },

    setTaskFrequencyDetails(state, data) {
        state.task_frequency_details = data
    },

    setTaskDetails(state, data) {
        state.task_details = data
    },
    setVisibilityState(state, data) {
        state.visibilityState = data
    },
    setFilter(state, data) {
        state.FilterState = data
    },
    setTaskDashboardData(state, data) {
        state.task_dashboard = data
    },
        setUserTaskTotalData(state, data) {
        state.task_user_count = data
    },
    setHappinessDashboardData(state, data) {
        state.happiness_dashboard = data
    },
    setValueBaseDashboardData(state, data) {
        state.value_base_dashboard = data
    },
    setMetricDashboardData(state, data) {
        state.metric_dashboard = data
    },
    setTaskForReviewSupervisor(state, taskReviews) {
        state.taskForReviewSupervisor = taskReviews
    },
    setSampleTask(state, data) {
        state.sampleTask = data
    },

    }