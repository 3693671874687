import * as api from '@/utils/services/clients/settings/medicalEquipment';

const state = {
    medicalEquipment: {},
    medicalEquipmentList: [],
}

const getters = {
    getClientMedicalEquipment: state => state.medicalEquipment,
    getClientMedicalEquipmentList: state => state.medicalEquipmentList
}

const actions = {

    async fetchClientMedicalEquipment({ commit }) {
        const res = await api.getClientMedicalEquipment();
        commit('setClientMedicalEquipmentList', res.data)
        return res.data;
    },

    async saveClientMedicalEquipment({ commit }, payload) {
        const res = await api.storeClientMedicalEquipment(payload);
        commit('setClientMedicalEquipment', res.data)
    },

    async updateClientMedicalEquipment({ commit }, payload, id) {
        const res = await api.updateClientMedicalEquipment(payload, id);
        commit('setClientMedicalEquipment', res.data)
    },

    async showClientMedicalEquipment({ commit }, id) {
        const res = await api.showClientMedicalEquipment(id);
        commit('setClientMedicalEquipment', res.data)
        return res.data;
    },

    async deleteClientMedicalEquipment({ commit }, id) {
        const res = await api.deleteClientMedicalEquipment(id);
        commit('setClientMedicalEquipmentList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMedicalEquipment(state, payload) {
        state.medicalEquipment = payload
    },

    setClientMedicalEquipmentList(state, payload) {
        state.medicalEquipmentList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}