import * as api from '@/utils/services/clients/settings/primaryContact';

const state = {
    primaryContact: {},
    primaryContactList: [],
}

const getters = {
    getClientPrimaryContact: state => state.primaryContact,
    getClientPrimaryContactList: state => state.primaryContactList
}

const actions = {

    async fetchClientPrimaryContacts({ commit }) {
        const res = await api.getClientPrimaryContacts();
        commit('setClientPrimaryContactList', res.data)
        return res.data;
    },

    async saveClientPrimaryContact({ commit }, payload) {
        const res = await api.storeClientPrimaryContact(payload);
        commit('setClientPrimaryContact', res.data)
    },

    async updateClientPrimaryContact({ commit }, payload, id) {
        const res = await api.updateClientPrimaryContact(payload, id);
        commit('setClientPrimaryContact', res.data)
    },

    async showClientPrimaryContact({ commit }, id) {
        const res = await api.showClientPrimaryContact(id);
        commit('setClientPrimaryContact', res.data)
        return res.data;
    },

    async deleteClientPrimaryContact({ commit }, id) {
        const res = await api.deleteClientPrimaryContact(id);
        commit('setClientPrimaryContactList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPrimaryContact(state, payload) {
        state.primaryContact = payload
    },

    setClientPrimaryContactList(state, payload) {
        state.primaryContactList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}