import * as api from '@/utils/services/users/settings/skillCertification';

const state = {
    userSkillCertification: {},
    userSkillCertificationList: [],
}

const getters = {
    getUserSkillCertification: state => state.userSkillCertification,
    getUserSkillCertificationList: state => state.userSkillCertificationList
}

const actions = {

    async fetchUserSkillCertifications({ commit }) {
        const res = await api.getUserSkillCertifications();
        commit('setUserSkillCertificationList', res.data)
        return res.data;
    },

    async saveUserSkillCertification({ commit }, payload) {
        const res = await api.storeUserSkillCertification(payload);
        commit('setUserSkillCertification', res.data)
    },

    async updateUserSkillCertification({ commit }, payload, id) {
        const res = await api.updateUserSkillCertification(payload, id);
        commit('setUserSkillCertification', res.data)
    },

    async showUserSkillCertification({ commit }, id) {
        const res = await api.showUserSkillCertification(id);
        commit('setUserSkillCertification', res.data)
        return res.data;
    },

    async deleteUserSkillCertification({ commit }, id) {
        const res = await api.deleteUserSkillCertification(id);
        commit('setUserSkillCertificationList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserSkillCertification(state, payload) {
        state.userSkillCertification = payload
    },

    setUserSkillCertificationList(state, payload) {
        state.userSkillCertificationList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}