import * as api from '@/utils/services/clients/settings/primarySpecialist';

const state = {
    primarySpecialist: {},
    primarySpecialistList: [],
}

const getters = {
    getClientPrimarySpecialist: state => state.primarySpecialist,
    getClientPrimarySpecialistList: state => state.primarySpecialistList
}

const actions = {

    async fetchClientPrimarySpecialists({ commit }) {
        const res = await api.getClientPrimarySpecialists();
        commit('setClientPrimarySpecialistList', res.data)
        return res.data;
    },

    async saveClientPrimarySpecialist({ commit }, payload) {
        const res = await api.storeClientPrimarySpecialist(payload);
        commit('setClientPrimarySpecialist', res.data)
    },

    async updateClientPrimarySpecialist({ commit }, payload, id) {
        const res = await api.updateClientPrimarySpecialist(payload, id);
        commit('setClientPrimarySpecialist', res.data)
    },

    async showClientPrimarySpecialist({ commit }, id) {
        const res = await api.showClientPrimarySpecialist(id);
        commit('setClientPrimarySpecialist', res.data)
        return res.data;
    },

    async deleteClientPrimarySpecialist({ commit }, id) {
        const res = await api.deleteClientPrimarySpecialist(id);
        commit('setClientPrimarySpecialistList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPrimarySpecialist(state, payload) {
        state.primarySpecialist = payload
    },

    setClientPrimarySpecialistList(state, payload) {
        state.primarySpecialistList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}