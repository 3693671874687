import * as api from '@/utils/services/clients/settings/supplement';

const state = {
    supplement: {},
    supplementList: [],
}

const getters = {
    getClientSupplement: state => state.supplement,
    getClientSupplementList: state => state.supplementList
}

const actions = {

    async fetchClientSupplement({ commit }) {
        const res = await api.getClientSupplement();
        commit('setClientSupplementList', res.data)
        return res.data;
    },

    async saveClientSupplement({ commit }, payload) {
        const res = await api.storeClientSupplement(payload);
        commit('setClientSupplement', res.data)
    },

    async updateClientSupplement({ commit }, payload, id) {
        const res = await api.updateClientSupplement(payload, id);
        commit('setClientSupplement', res.data)
    },

    async showClientSupplement({ commit }, id) {
        const res = await api.showClientSupplement(id);
        commit('setClientSupplement', res.data)
        return res.data;
    },

    async deleteClientSupplement({ commit }, id) {
        const res = await api.deleteClientSupplement(id);
        commit('setClientSupplementList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientSupplement(state, payload) {
        state.supplement = payload
    },

    setClientSupplementList(state, payload) {
        state.supplementList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}