import * as api from '@/utils/services/nurse_assesment';

export default {
    state: {
	    client_assessment_dashboard: {},
	    client_assessments: [],
        
    },

    getters: {
        listClientAssessments: state => state.client_assessments,
        listClientAssessmentDashboardData: state => state.client_assessment_dashboard,
    },

    mutations: {

        setClientAssessmentDashboardData(state, data) {
            state.client_assessment_dashboard = data
        },

        setClientAssessments(state, data) {
            state.client_assessments = data
        },
    
    },

    actions: {

          async getClientAssessmentDashboardData({ commit }, params) {
            const res = await api.getClientAssessmentDashboardData(params);
            if (res && res.data) { // Check if 'res' and 'res.data' are not null
            //  console.log("getIncidentDashboardData", res.data);
              commit('setClientAssessmentDashboardData', res.data);
            } else {
            //   console.log("getClientAssessmentDashboardData returned null or undefined");
              commit('setClientAssessmentDashboardData', res);
            }
          },

          async fetchClientAssessments({ commit }) {
            const res = await api.getAllClientAssessments();
            console.log('clients:', res);
              commit('setClientAssessments', res.data.data);
              return res.data.data;
          },

    }
}
