import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'user/settings/benefit-medical';

export const getUserBenefitMedicals = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const storeUserBenefitMedical = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateUserBenefitMedical = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showUserBenefitMedical = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteUserBenefitMedical = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
