// settings 
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [

    {
        path: '/setting/add/company',
        name: 'AddCompany',
        component: () =>
            import ('@/views/settings/companies/addCompany.vue'),
        meta: {
            title: 'Add Company',
        },
    },
    {
        path: '/setting/companies',
        name: 'ListCompanies',
        component: () =>
            import ('@/views/settings/companies/index.vue'),
        meta: {
            check: 'show_companies',
            title: 'Companies Log',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/setting/company/update/:id',
        name: 'UpdateCompany',
        component: () =>
            import ('@/views/settings/companies/updateCompany.vue'),
        meta: {
            title: 'Update Company',
        },
    },
    {
        path: '/settingcompany/detail/:id',
        name: 'DetailCompany',
        component: () =>
            import ('@/views/settings/companies/detailCompany.vue')
    },
    {
        path: '/setting/email/smtp',
        name: 'EmailSMTP',
        component: () =>
            import ('@/views/settings/emails/smtp.vue'),
        meta: {
            check: 'show_emails',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/setting/integrations',
        name: 'IntegrationsLog',
        component: () =>
            import ('@/views/settings/integration'),
        meta: {
            check: 'show_emails',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/roles',
        name: 'Role',
        component: () =>
            import ('@/views/settings/roles')
    },
    {
        path: '/users',
        name: 'UserManagenment',
        component: () =>
            import ('@/views/users')
    },
    {
        path: '/users',
        name: 'ModuleManager',
        component: () =>
            import ('@/views/users')
    },
    {
        path: '/settings/2fa',
        name: '2FASettings',
        component: () =>
            import ('@/views/settings/2FASettings')
    },
    


];