import axios from "axios";
import config from "@/config"
const { baseAPI, Header, AuthHeader } = config;

export const postLoginReq = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/login`, payload);
    } catch (error) {
        return error
    }
}

const logout = () => {
    //localStorage.clear();
    localStorage.removeItem("user-token")
    location.href = "/auth/login"
}
export const logoutUserReq = async () => {
    try { 
        const res = await axios.post(`${baseAPI}/logout`, AuthHeader);
        logout()
    } catch (error) {
        logout()
        return error
    }
}

export const twoFactorAuthReq = async (req) => {
    try {
        return await axios.post(`${baseAPI}/2fa/sendtoken`, req, Header);
    } catch (error) {
        return error
    }
}
export const validateTwoFactorAuthReq = async (req) => {
    try {
        return await axios.post(`${baseAPI}/2fa/validatetoken`, req, Header);
    } catch (error) {
        return error
    }
}

export const authUserReq = async () => {
    try {
        return await axios.get(`${baseAPI}/auth-user`, Header);
    } catch (error) {
        return error
    }
}
export const forgotPassword = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/forgot-password`,payload, Header);
    } catch (error) {
        return error
    }
}
export const newPassword = async (payload,token) => {
    try {
        return await axios.post(`${baseAPI}/new-password/${token}`,payload, Header);
    } catch (error) {
        return error
    }
}
