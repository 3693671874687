import * as api from '@/utils/services/clients/settings/dmeSupply';

const state = {
    dmeSupply: {},
    dmeSupplyList: [],
}

const getters = {
    getClientDMESupply: state => state.dmeSupply,
    getClientDMESupplyList: state => state.dmeSupplyList
}

const actions = {

    async fetchClientDMESupply({ commit }) {
        const res = await api.getClientDMESupply();
        commit('setClientDMESupplyList', res.data)
        return res.data;
    },

    async saveClientDMESupply({ commit }, payload) {
        const res = await api.storeClientDMESupply(payload);
        commit('setClientDMESupply', res.data)
    },

    async updateClientDMESupply({ commit }, payload, id) {
        const res = await api.updateClientDMESupply(payload, id);
        commit('setClientDMESupply', res.data)
    },

    async showClientDMESupply({ commit }, id) {
        const res = await api.showClientDMESupply(id);
        commit('setClientDMESupply', res.data)
        return res.data;
    },

    async deleteClientDMESupply({ commit }, id) {
        const res = await api.deleteClientDMESupply(id);
        commit('setClientDMESupplyList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientDMESupply(state, payload) {
        state.dmeSupply = payload
    },

    setClientDMESupplyList(state, payload) {
        state.dmeSupplyList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}