import axios from "axios";
import config from "@/config";
const { baseAPI, Header, PhotoHeader, AuthHeader } = config;


//ADMINISTRATIVE STAFF SECTION

export const getAdminStaffUsers = async() => {
    try {
        return await axios.get(`${baseAPI}/admin-staff`, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const getOtherStaffUsers = async() => {
    try {
        return await axios.get(`${baseAPI}/other-staff/list`, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const storeAdminStaff = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/admin-staff`, payload, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const storeOtherStaff = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/other-staff`, payload, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const updateAdminStaff = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/admin-staff/${payload.id}/update`, payload, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const updateOtherStaff = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/other-staff/${payload.id}/update`, payload, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const deleteAdminStaff = async(id) => {
    try {
        return await axios.post(`${baseAPI}/admin-staff/${id}/delete`, null, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const deleteOtherStaff = async(id) => {
    try {
        return await axios.post(`${baseAPI}/other-staff/${id}/delete`, null, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}


export const showAdminStaff = async(id) => {
    try {
        return await axios.get(`${baseAPI}/admin-staff/${id}`, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const showOtherStaff = async(id) => {
    try {
        return await axios.get(`${baseAPI}/other-staff/${id}`, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}


export const getAdminStaffDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/admin-staff/data/dashboard?type=${type}&period=${period}`;
        
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        
        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);
        
        return res;
    } catch (error) {
        console.error('Error getting admin staff dashboard details:', error);
        throw error; 
    }
}

export const importAdminStaff = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/admin-staff/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
}

//ADMINISTRATIVE ROLE SECTION
export const storeAdministrativeRole = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/administrative-role`, payload, Header);
    } catch (error) {
        console.log(error);
        return error
    }
}

export const updateAdministrativeRole = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/administrative-role/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteAdministrativeRole = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/administrative-role/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showAdministrativeRole = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/administrative-role/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserAdministrativeRoles = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/administrative-role/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//JOB EXPERIENCE SECTION
export const storeJobExperience = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/job-experience`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateJobExperience = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/job-experience/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteJobExperience = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/job-experience/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showJobExperience = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/job-experience/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserJobExperience = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/job-experience/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};


//SKILLS SECTION
export const storeSkills = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/skills`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateSkills = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/skills/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteSkills = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/skills/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showSkills = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/skills/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserSkills = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/skills/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};


//BANK INFO SECTION
export const storeBankInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/bank-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateBankInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/bank-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteBankInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/bank-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showBankInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/bank-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserBankInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/bank-info/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//COMPENSATION SECTION
export const storeCompensation = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compensation`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateCompensation = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compensation/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteCompensation = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/compensation/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showCompensation = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compensation/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserCompensation = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compensation/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//DEPENDANTS SECTION
export const storeDependant = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/dependant`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateDependant = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/dependant/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteDependant = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/dependant/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showDependant = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/dependant/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserDependant = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/dependant/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};



//BENEFITS SECTION
export const storeBenefit = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/benefit`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateBenefit = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/benefit/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteBenefit = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/benefit/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showBenefit = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/benefit/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserBenefit = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/benefit/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};


//WORK LOCATION SECTION
export const storeWorkLocation = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-location`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateWorkLocation = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-location/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteWorkLocation = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/work-location/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const getUserWorkLocation = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-location/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const userWorkLocationLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-location/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

export const userRecentWorkLocationLog = async(userId) => {
    try {
        return await axios.get(`${baseAPI}/user/work-location/${userId}/last-work-locations`, Header);
    } catch (error) {
        return error;
    }
};


//WORK SCHEDULE SECTION
export const storeWorkSchedule = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-schedule`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateWorkSchedule = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-schedule/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteWorkSchedule = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/work-schedule/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const getUserWorkSchedule = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-schedule/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const userWorkScheduleLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-schedule/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

export const userRecentWorkScheduleLog = async(userId) => {
    try {
        return await axios.get(`${baseAPI}/user/work-schedule/${userId}/last-work-schedules`, Header);
    } catch (error) {
        return error;
    }
};

//WORK LICENSE SECTION
export const storeWorkLicense = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-license`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateWorkLicense = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/work-license/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const getUserWorkLicense = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-license/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteWorkLicense = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/work-license/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const userWorkLicenseLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-license/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

export const userAdminRoleLicenseLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/work-license/${id}/admin-role`, Header);
    } catch (error) {
        return error;
    }
};

export const userRecentAdminRoleLicenseLog = async(userId) => {
    try {
        return await axios.get(`${baseAPI}/user/work-license/${userId}/role-licenses`, Header);
    } catch (error) {
        return error;
    }
};

export const userRecentWorkLicenseLog = async(userId) => {
    try {
        return await axios.get(`${baseAPI}/user/work-license/${userId}/recent-licenses`, Header);
    } catch (error) {
        return error;
    }
};



//COMPLIANCE SECTION
export const storeCompliance = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateCompliance = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const deleteCompliance = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const getUserCompliance = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compliance/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};

//COMPLIANCE IN SERVICE SECTION
export const storeComplianceInService = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/in-service`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const updateComplianceInService = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/in-service/${payload.id}/update`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const deleteComplianceInService = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/in-service/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showComplianceInService = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compliance/in-service/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserComplianceInservice = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compliance/in-service/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};


//COMPLIANCE ANNUAL PHYSICAL SECTION
export const storeComplianceAnnualPhysical = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/annual-physical`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const updateComplianceAnnualPhysical = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/annual-physical/${payload.id}/update`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const deleteComplianceAnnualPhysical = async(id) => {
    try {
        return await axios.post(`${baseAPI}/user/compliance/annual-physical/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const showComplianceAnnualPhysical = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compliance/annual-physical/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserComplianceAnnualPhysical = async(id) => {
    try {
        return await axios.get(`${baseAPI}/user/compliance/annual-physical/${id}/user`, Header);
    } catch (error) {
        return error;
    }
};
