import * as api from '@/utils/services/clients/settings/medicationManagementFrequency';

const state = {
    medicationManagementFrequency: {},
    medicationManagementFrequencyList: [],
}

const getters = {
    getClientMedicationManagementFrequency: state => state.medicationManagementFrequency,
    getClientMedicationManagementFrequencyList: state => state.medicationManagementFrequencyList
}

const actions = {

    async fetchClientMedicationManagementFrequencies({ commit }) {
        const res = await api.getClientMedicationManagementFrequencies();
        commit('setClientMedicationManagementFrequencyList', res.data)
        return res.data;
    },

    async saveClientMedicationManagementFrequency({ commit }, payload) {
        const res = await api.storeClientMedicationManagementFrequency(payload);
        commit('setClientMedicationManagementFrequency', res.data)
    },

    async updateClientMedicationManagementFrequency({ commit }, payload, id) {
        const res = await api.updateClientMedicationManagementFrequency(payload, id);
        commit('setClientMedicationManagementFrequency', res.data)
    },

    async showClientMedicationManagementFrequency({ commit }, id) {
        const res = await api.showClientMedicationManagementFrequency(id);
        commit('setClientMedicationManagementFrequency', res.data)
        return res.data;
    },

    async deleteClientMedicationManagementFrequency({ commit }, id) {
        const res = await api.deleteClientMedicationManagementFrequency(id);
        commit('setClientMedicationManagementFrequencyList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMedicationManagementFrequency(state, payload) {
        state.medicationManagementFrequency = payload
    },

    setClientMedicationManagementFrequencyList(state, payload) {
        state.medicationManagementFrequencyList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}