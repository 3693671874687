import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'complaint/reasons';

export const fetchComplaintReasonsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const fetchComplaintReasonRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}

export const importComplaintReasonsRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/import`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveComplaintReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateComplaintReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteComplaintReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}