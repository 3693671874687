import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

// dashboards
export default
    [
        // {
        //     path: '/',
        //     name: 'MainDashboard', 
        //     // component: () => import('@/views/dashboard/incidents/index.vue'),
        //     // component: () => import('@/views/dashboard/task/index.vue'),
        //       component: () => import('@/views/homecareworkers/view/dashboard.vue'),
        //     meta: {
        //         // requiresAuth: true,
        //          title: 'Home Dashboard',
        //          check: 'show_task',
        //     },
        //     beforeEnter: checkPermissions,
        // },
        {
            path: '/',
            name: 'HomeMainDashboard',
            // component: () => import('@/views/dashboard/incidents/index.vue'),
            // component: () => import('@/views/dashboard/task/index.vue'),
            component: () => import('@/views/homecareworkers/view/dashboard.vue'),
            meta: {
                // requiresAuth: true,
                title: 'Home Dashboard',
                check: 'show_task',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'case-coordinator/dashboard',
                name: 'CaseCoordinatorDashboard', 
                component: () => import('@/views/dashboard/caseCoordinator'),
                meta: {
                    // requiresAuth: true,
                     title: 'Case Coordinator Dashboard',
                     check: 'show_case_coordinators',
                },
                beforeEnter: checkPermissions,
        },
        {
        path: '/incident/dashboard',
            name: 'IncidentDashboard', 
            component: () => import('@/views/dashboard/incidents/index.vue'),
            meta: {
                // requiresAuth: true,
                 title: 'Issue Dashboard',
                 check: 'show_incidents',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/complaint/dashboard',
                name: 'ComplaintDashboard', 
                component: () => import('@/views/dashboard/complaints/index.vue'),
                meta: {
                     title: 'Complaint Dashboard',
                     check: 'show_complaints',
                },
                beforeEnter: checkPermissions,
            },
        {
            path: '/nurse/dashboard',
                name: 'NurseDashboard', 
                component: () => import('@/views/dashboard/nurse/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Nurse Dashboard',
                     check: 'show_nurses',
                },
                beforeEnter: checkPermissions,
        },
        {
            path: '/intake-coordinator/dashboard',
                name: 'IntakeCoordinatorDashboard', 
                component: () => import('@/views/dashboard/intakeCoordinator/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'IntakeCoordinatorDashboard',
                     check: 'show_intake_coordinators'
                },
                beforeEnter: checkPermissions,
        },
        {
            path: '/home-care-worker/dashboard',
                name: 'HomeCareWorkerDashboard', 
                component: () => import('@/views/dashboard/homeCareWorkers/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Home Care Worker Dashboard',
                     check: 'show_home_care_workers',
                },
                beforeEnter: checkPermissions,
        },
        {
            path: '/home-care-worker',
                name: 'HomeCareWorkerScreen', 
                component: () => import('@/views/dashboard/homeCareWorkers/welcome.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Home Care Worker Dashboard'
                },
        },
        {
            path: '/client/dashboard',
                name: 'ClientDashboard', 
                component: () => import('@/views/dashboard/client/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Client Dashboard',
                     check: 'show_clients',
                },
                beforeEnter: checkPermissions,
        },
        {
            path: '/tasks/dashboard',
                name: 'TaskDashboard', 
                component: () => import('@/views/dashboard/task/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Task Dashboard',
                     check: 'show_task',
                },
                beforeEnter: checkPermissions,
        },
        {
            path: '/admin-staff/dashboard',
                name: 'AdminStaffDashboard', 
                component: () => import('@/views/dashboard/adminStaff/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Admin Staff Dashboard'
                },
        },
        {
            path: '/home-care/dashboard',
                name: 'HomeCareDashboard', 
                component: () => import('@/views/dashboard/homeCare/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Home Care Dashboard',
                     check: 'show_home_care_workers',
                },
                beforeEnter: checkPermissions,
        },

        {
            path: '/care-managenmennt/dashboard',
                name: 'CareMangenmenntDashboard', 
                component: () => import('@/views/dashboard/homeCare/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Home Care Dashboard'
                }
        },

        {
            path: '/intellectual-development-disability/dashboard',
                name: 'IntellectualDevelopmentDisabilityDashboard', 
                component: () => import('@/views/dashboard/clientAssessment/IntellectualDevelopmentDisability/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Intellectual Development Disability Dashboard'
                }
        },

        {
            path: '/mental-health/dashboard',
                name: 'MentalHealthDashboard', 
                component: () => import('@/views/dashboard/clientAssessment/mentalHealth/index.vue'),
                meta: {
                    // requiresAuth: true,
                     title: 'Mental Health'
                }
        },
            
            
            
    ];