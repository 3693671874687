import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: 'clients',
        name: 'Client',
        component: () =>
            import ('@/views/clients'),
        meta: {
            // requiresAuth: true,
             title: 'Clients Log',
             check: 'show_clients',
        },
        beforeEnter: checkPermissions,
    },
    // {
    //     path: '/clients/details',
    //     name: 'ClientDetails',
    //     component: () =>
    //         import('@/views/clients/Details'),
    // },
    // {
    //     path: '/clients/management/:id',
    //     name: 'ClientManagements',
    //     component: () =>
    //         import('@/views/clients/management'),
    // },
    {
        path: '/clients/settings',
        name: 'ClientSettings',
        component: () =>
            import('@/views/clients/settings'),
        meta: {
                check: 'show_settings',
                title: 'Client Settings'
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/clients/hospital-settings',
        name: 'ClientHospitalSettings',
        component: () =>
            import('@/views/clients/settings/hospitals'),
        meta: {
                check: 'show_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/clients/hospital-settings/:id',
        name: 'EditClientHospitalSettings',
        component: () =>
            import('@/views/clients/settings/hospitals/components/EditClientHospital'),
        meta: {
                check: 'edit_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/clients/add-hospital',
        name: 'AddClientHospital',
        component: () =>
            import('@/views/clients/settings/hospitals/components/AddClientHospital.vue'),
        meta: {
                check: 'create_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/clients/diagnosis-settings',
        name: 'ClientDiagnosisSettings',
        component: () =>
            import('@/views/clients/settings/diagnosis'),
        meta: {
                check: 'show_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/clients/diagnosis/:id',
        name: 'FindClientDiagnosis',
        component: () =>
            import('@/views/clients/settings/diagnosis/subComponents')
    },
    {
        path: '/clients/diagnosis-settings/:id',
        name: 'EditClientDiagnosisSettings',
        component: () =>
            import('@/views/clients/settings/diagnosis/components/EditClientDiagnosisSettings')
    },
    {
        path: '/clients/add-diagnosis',
        name: 'AddClientDiagnosis',
        component: () =>
            import('@/views/clients/settings/diagnosis/components/AddClientDiagnosisSettings.vue')
    },
    {
        path: '/clients/diagnosis-type-settings',
        name: 'ClientDiagnosisTypeSettings',
        component: () =>
            import('@/views/clients/settings/diagnosisTypes')
    },
    {
        path: '/clients/diagnosis-type-settings/:id',
        name: 'EditClientDiagnosisTypeSettings',
        component: () =>
            import('@/views/clients/settings/diagnosisTypes/components/EditClientDiagnosisType')
    },
    {
        path: '/clients/add-diagnosis-type',
        name: 'AddClientDiagnosisType',
        component: () =>
            import('@/views/clients/settings/diagnosisTypes/components/AddClientDiagnosisType')
    },
    {
        path: '/clients/vaccine-type-settings',
        name: 'ClientVaccineTypeSettings',
        component: () =>
            import('@/views/clients/settings/vaccineTypes')
    },
    {
        path: '/clients/vaccine-type-settings/:id',
        name: 'EditClientVaccineTypeSettings',
        component: () =>
            import('@/views/clients/settings/vaccineTypes/components/EditClientVaccineType')
    },
    {
        path: '/clients/add-vaccine-type',
        name: 'AddClientVaccineType',
        component: () =>
            import('@/views/clients/settings/vaccineTypes/components/AddClientVaccineType')
    },
    {
        path: '/clients/vaccine-brand-settings',
        name: 'ClientVaccineBrandSettings',
        component: () =>
            import('@/views/clients/settings/vaccineBrands')
    },
    {
        path: '/clients/vaccine-brand-settings/:id',
        name: 'EditClientVaccineBrandSettings',
        component: () =>
            import('@/views/clients/settings/vaccineBrands/components/EditClientVaccineBrand')
    },
    {
        path: '/clients/add-vaccine-brand',
        name: 'AddClientVaccineBrand',
        component: () =>
            import('@/views/clients/settings/vaccineBrands/components/AddClientVaccineBrand')
    },
    {
        path: '/clients/vaccine-dose-settings',
        name: 'ClientVaccineDoseSettings',
        component: () =>
            import('@/views/clients/settings/vaccineDoses')
    },
    {
        path: '/clients/vaccine-dose-settings/:id',
        name: 'EditClientVaccineDoseSettings',
        component: () =>
            import('@/views/clients/settings/vaccineDoses/components/EditClientVaccineDose')
    },
    {
        path: '/clients/add-vaccine-dose',
        name: 'AddClientVaccineDose',
        component: () =>
            import('@/views/clients/settings/vaccineDoses/components/AddClientVaccineDose')
    },

    {
        path: '/clients/recurrence-rate-settings',
        name: 'ClientRecurrenceRateSettings',
        component: () =>
            import('@/views/clients/settings/recurrenceRates')
    },
    {
        path: '/clients/recurrence-rate-settings/:id',
        name: 'EditClientRecurrenceRateSettings',
        component: () =>
            import('@/views/clients/settings/recurrenceRates/components/EditClientRecurrenceRate')
    },
    {
        path: '/clients/add-recurrence-rate',
        name: 'AddClientRecurrenceRate',
        component: () =>
            import('@/views/clients/settings/recurrenceRates/components/AddClientRecurrenceRate')
    },

    {
        path: '/clients/new-problem-settings',
        name: 'ClientNewProblemSettings',
        component: () =>
            import('@/views/clients/settings/newProblems')
    },
    {
        path: '/clients/new-problem-settings/:id',
        name: 'EditClientNewProblemSettings',
        component: () =>
            import('@/views/clients/settings/newProblems/components/EditClientNewProblem')
    },
    {
        path: '/clients/add-new-problem',
        name: 'AddClientNewProblem',
        component: () =>
            import('@/views/clients/settings/newProblems/components/AddClientNewProblem')
    },

    {
        path: '/clients/supply-type-settings',
        name: 'ClientSupplyTypeSettings',
        component: () =>
            import('@/views/clients/settings/supplyTypes')
    },
    {
        path: '/clients/supply-type-settings/:id',
        name: 'EditClientSupplyTypeSettings',
        component: () =>
            import('@/views/clients/settings/supplyTypes/components/EditClientSupplyType')
    },
    {
        path: '/clients/add-supply-type',
        name: 'AddClientSupplyType',
        component: () =>
            import('@/views/clients/settings/supplyTypes/components/AddClientSupplyType')
    },

    {
        path: '/clients/current-usage-settings',
        name: 'ClientCurrentUsageSettings',
        component: () =>
            import('@/views/clients/settings/currentUsage')
    },
    {
        path: '/clients/current-usage-settings/:id',
        name: 'EditClientCurrentUsageSettings',
        component: () =>
            import('@/views/clients/settings/currentUsage/components/EditClientCurrentUsage')
    },
    {
        path: '/clients/add-current-usage',
        name: 'AddClientCurrentUsage',
        component: () =>
            import('@/views/clients/settings/currentUsage/components/AddClientCurrentUsage')
    },

    {
        path: '/clients/medication-management-settings',
        name: 'ClientMedicationManagementSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagement')
    },
    {
        path: '/clients/medication-management-settings/:id',
        name: 'EditClientMedicationManagementSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagement/components/EditClientMedicationManagement')
    },
    {
        path: '/clients/add-medication-management',
        name: 'AddClientMedicationManagement',
        component: () =>
            import('@/views/clients/settings/medicationManagement/components/AddClientMedicationManagement')
    },

    {
        path: '/clients/medication-management-dosage-settings',
        name: 'ClientMedicationManagementDosageSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementDosage')
    },
    {
        path: '/clients/medication-management-dosage-settings/:id',
        name: 'EditClientMedicationManagementDosageSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementDosage/components/EditClientMedicationManagementDosage')
    },
    {
        path: '/clients/add-medication-management-dosage',
        name: 'AddClientMedicationManagementDosage',
        component: () =>
            import('@/views/clients/settings/medicationManagementDosage/components/AddClientMedicationManagementDosage')
    },

    {
        path: '/clients/medication-management-frequency-settings',
        name: 'ClientMedicationManagementFrequencySettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementFrequency')
    },
    {
        path: '/clients/medication-management-frequency-settings/:id',
        name: 'EditClientMedicationManagementFrequencySettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementFrequency/components/EditClientMedicationManagementFrequency')
    },
    {
        path: '/clients/add-medication-management-frequency',
        name: 'AddClientMedicationManagementFrequency',
        component: () =>
            import('@/views/clients/settings/medicationManagementFrequency/components/AddClientMedicationManagementFrequency')
    },

    {
        path: '/clients/medication-management-route-settings',
        name: 'ClientMedicationManagementRouteSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementRoute')
    },
    {
        path: '/clients/medication-management-route-settings/:id',
        name: 'EditClientMedicationManagementRouteSettings',
        component: () =>
            import('@/views/clients/settings/medicationManagementRoute/components/EditClientMedicationManagementRoute')
    },
    {
        path: '/clients/add-medication-management-route',
        name: 'AddClientMedicationManagementRoute',
        component: () =>
            import('@/views/clients/settings/medicationManagementRoute/components/AddClientMedicationManagementRoute')
    },

    {
        path: '/clients/physician-type-settings',
        name: 'ClientPhysicianTypeSettings',
        component: () =>
            import('@/views/clients/settings/physicianType')
    },
    {
        path: '/clients/physician-type-settings/:id',
        name: 'EditClientPhysicianTypeSettings',
        component: () =>
            import('@/views/clients/settings/physicianType/components/EditClientPhysicianType')
    },
    {
        path: '/clients/add-physician-type',
        name: 'AddClientPhysicianType',
        component: () =>
            import('@/views/clients/settings/physicianType/components/AddClientPhysicianType')
    },

    {
        path: '/clients/specialist-type-settings',
        name: 'ClientSpecialistTypeSettings',
        component: () =>
            import('@/views/clients/settings/specialistType')
    },
    {
        path: '/clients/specialist-type-settings/:id',
        name: 'EditClientSpecialistTypeSettings',
        component: () =>
            import('@/views/clients/settings/specialistType/components/EditClientSpecialistType')
    },
    {
        path: '/clients/add-specialist-type',
        name: 'AddClientSpecialistType',
        component: () =>
            import('@/views/clients/settings/specialistType/components/AddClientSpecialistType')
    },

    {
        path: '/clients/insurance-type-settings',
        name: 'ClientInsuranceTypeSettings',
        component: () =>
            import('@/views/clients/settings/insuranceType')
    },
    {
        path: '/clients/insurance-type-settings/:id',
        name: 'EditClientInsuranceTypeSettings',
        component: () =>
            import('@/views/clients/settings/insuranceType/components/EditClientInsuranceType')
    },
    {
        path: '/clients/add-insurance-type',
        name: 'AddClientInsuranceType',
        component: () =>
            import('@/views/clients/settings/insuranceType/components/AddClientInsuranceType')
    },

    {
        path: '/clients/authorization-contract-type-settings',
        name: 'ClientAuthorizationContractTypeSettings',
        component: () =>
            import('@/views/clients/settings/authorizationContractType')
    },
    {
        path: '/clients/authorization-contract-type-settings/:id',
        name: 'EditClientAuthorizationContractTypeSettings',
        component: () =>
            import('@/views/clients/settings/authorizationContractType/components/EditClientAuthorizationContractType')
    },
    {
        path: '/clients/add-authorization-contract-type',
        name: 'AddClientAuthorizationContractType',
        component: () =>
            import('@/views/clients/settings/authorizationContractType/components/AddClientAuthorizationContractType')
    },

    {
        path: '/clients/authorization-service-type-settings',
        name: 'ClientAuthorizationServiceTypeSettings',
        component: () =>
            import('@/views/clients/settings/authorizationServiceType')
    },
    {
        path: '/clients/authorization-service-type-settings/:id',
        name: 'EditClientAuthorizationServiceTypeSettings',
        component: () =>
            import('@/views/clients/settings/authorizationServiceType/components/EditClientAuthorizationServiceType')
    },
    {
        path: '/clients/add-authorization-service-type',
        name: 'AddClientAuthorizationServiceType',
        component: () =>
            import('@/views/clients/settings/authorizationServiceType/components/AddClientAuthorizationServiceType')
    },

    {
        path: '/clients/authorization-requested-service-settings',
        name: 'ClientAuthorizationRequestedServiceSettings',
        component: () =>
            import('@/views/clients/settings/authorizationRequestedService')
    },
    {
        path: '/clients/authorization-requested-service-settings/:id',
        name: 'EditClientAuthorizationRequestedServiceSettings',
        component: () =>
            import('@/views/clients/settings/authorizationRequestedService/components/EditClientAuthorizationRequestedService')
    },
    {
        path: '/clients/add-authorization-requested-service',
        name: 'AddClientAuthorizationRequestedService',
        component: () =>
            import('@/views/clients/settings/authorizationRequestedService/components/AddClientAuthorizationRequestedService')
    },

    {
        path: '/clients/authorization-source-settings',
        name: 'ClientAuthorizationSourceSettings',
        component: () =>
            import('@/views/clients/settings/authorizationSource')
    },
    {
        path: '/clients/authorization-source-settings/:id',
        name: 'EditClientAuthorizationSourceSettings',
        component: () =>
            import('@/views/clients/settings/authorizationSource/components/EditClientAuthorizationSource')
    },
    {
        path: '/clients/add-authorization-source',
        name: 'AddClientAuthorizationSource',
        component: () =>
            import('@/views/clients/settings/authorizationSource/components/AddClientAuthorizationSource')
    },

    {
        path: '/clients/living-alone-settings',
        name: 'ClientLivingAloneSettings',
        component: () =>
            import('@/views/clients/settings/livingAlone')
    },
    {
        path: '/clients/living-alone-settings/:id',
        name: 'EditClientLivingAloneSettings',
        component: () =>
            import('@/views/clients/settings/livingAlone/components/EditClientLivingAlone')
    },
    {
        path: '/clients/add-living-alone',
        name: 'AddClientLivingAlone',
        component: () =>
            import('@/views/clients/settings/livingAlone/components/AddClientLivingAlone')
    },

    {
        path: '/clients/living-with-settings',
        name: 'ClientLivingWithSettings',
        component: () =>
            import('@/views/clients/settings/livingWith')
    },
    {
        path: '/clients/living-with-settings/:id',
        name: 'EditClientLivingWithSettings',
        component: () =>
            import('@/views/clients/settings/livingWith/components/EditClientLivingWith')
    },
    {
        path: '/clients/add-living-with',
        name: 'AddClientLivingWith',
        component: () =>
            import('@/views/clients/settings/livingWith/components/AddClientLivingWith')
    },

    {
        path: '/clients/living-situation-settings',
        name: 'ClientLivingSituationSettings',
        component: () =>
            import('@/views/clients/settings/livingSituation')
    },
    {
        path: '/clients/living-situation-settings/:id',
        name: 'EditClientLivingSituationSettings',
        component: () =>
            import('@/views/clients/settings/livingSituation/components/EditClientLivingSituation')
    },
    {
        path: '/clients/add-living-situation',
        name: 'AddClientLivingSituation',
        component: () =>
            import('@/views/clients/settings/livingSituation/components/AddClientLivingSituation')
    },

    {
        path: '/clients/task-activity-task-settings',
        name: 'ClientTaskActivityTaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityTask')
    },
    {
        path: '/clients/task-activity-task-settings/:id',
        name: 'EditClientTaskActivityTaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityTask/components/Edit')
    },
    {
        path: '/clients/add-task-activity-task',
        name: 'AddClientTaskActivityTaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityTask/components/Add')
    },
    {
        path: '/clients/task-activity-subtask-settings',
        name: 'ClientTaskActivitySubtaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivitySubtask')
    },
    {
        path: '/clients/task-activity-subtask-settings/:id',
        name: 'EditClientTaskActivitySubtaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivitySubtask/components/Edit')
    },
    {
        path: '/clients/add-task-activity-subtask',
        name: 'AddClientTaskActivitySubtaskSettings',
        component: () =>
            import('@/views/clients/settings/taskActivitySubtask/components/Add')
    },
    {
        path: '/clients/task-activity-applicable-settings',
        name: 'ClientTaskActivityApplicableSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityApplicable')
    },
    {
        path: '/clients/task-activity-applicable-settings/:id',
        name: 'EditClientTaskActivityApplicableSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityApplicable/components/Edit')
    },
    {
        path: '/clients/add-task-activity-applicable',
        name: 'AddClientTaskActivityApplicableSettings',
        component: () =>
            import('@/views/clients/settings/taskActivityApplicable/components/Add')
    },
    {
        path: '/clients/task-activity-frequency-settings',
        name: 'ClientTaskActivityFrequencySettings',
        component: () =>
            import('@/views/clients/settings/taskActivityFrequency')
    },
    {
        path: '/clients/task-activity-frequency-settings/:id',
        name: 'EditClientTaskActivityFrequencySettings',
        component: () =>
            import('@/views/clients/settings/taskActivityFrequency/components/Edit')
    },
    {
        path: '/clients/add-task-activity-frequency',
        name: 'AddClientTaskActivityFrequencySettings',
        component: () =>
            import('@/views/clients/settings/taskActivityFrequency/components/Add')
    },

    {
        path: '/clients/primary-physician-settings',
        name: 'ClientPrimaryPhysicianSettings',
        component: () =>
            import('@/views/clients/settings/primaryPhysician')
    },
    {
        path: '/clients/primary-physician-settings/:id',
        name: 'EditClientPrimaryPhysicianSettings',
        component: () =>
            import('@/views/clients/settings/primaryPhysician/components/EditClientPrimaryPhysician')
    },
    {
        path: '/clients/add-primary-physician',
        name: 'AddClientPrimaryPhysician',
        component: () =>
            import('@/views/clients/settings/primaryPhysician/components/AddClientPrimaryPhysician')
    },

    {
        path: '/clients/primary-specialist-settings',
        name: 'ClientPrimarySpecialistSettings',
        component: () =>
            import('@/views/clients/settings/primarySpecialist')
    },
    {
        path: '/clients/primary-specialist-settings/:id',
        name: 'EditClientPrimarySpecialistSettings',
        component: () =>
            import('@/views/clients/settings/primarySpecialist/components/EditClientPrimarySpecialist')
    },
    {
        path: '/clients/add-primary-specialist',
        name: 'AddClientPrimarySpecialist',
        component: () =>
            import('@/views/clients/settings/primarySpecialist/components/AddClientPrimarySpecialist')
    },

    {
        path: '/clients/primary-contact-settings',
        name: 'ClientPrimaryContactSettings',
        component: () =>
            import('@/views/clients/settings/primaryContact')
    },
    {
        path: '/clients/primary-contact-settings/:id',
        name: 'EditClientPrimaryContactSettings',
        component: () =>
            import('@/views/clients/settings/primaryContact/components/EditClientPrimaryContact')
    },
    {
        path: '/clients/add-primary-contact',
        name: 'AddClientPrimaryContact',
        component: () =>
            import('@/views/clients/settings/primaryContact/components/AddClientPrimaryContact')
    },

    {
        path: '/clients/relationship-settings',
        name: 'ClientRelationshipSettings',
        component: () =>
            import('@/views/clients/settings/relationship')
    },
    {
        path: '/clients/relationship-settings/:id',
        name: 'EditClientRelationshipSettings',
        component: () =>
            import('@/views/clients/settings/relationship/components/EditClientRelationship')
    },
    {
        path: '/clients/add-relationship',
        name: 'AddClientRelationship',
        component: () =>
            import('@/views/clients/settings/relationship/components/AddClientRelationship')
    },

    {
        path: '/clients/nutrition-settings',
        name: 'ClientNutritionSettings',
        component: () =>
            import('@/views/clients/settings/nutrition')
    },
    {
        path: '/clients/nutrition-settings/:id',
        name: 'EditClientNutritionSettings',
        component: () =>
            import('@/views/clients/settings/nutrition/components/EditClientNutrition')
    },
    {
        path: '/clients/add-nutrition',
        name: 'AddClientNutrition',
        component: () =>
            import('@/views/clients/settings/nutrition/components/AddClientNutrition')
    },

    {
        path: '/clients/allergy-settings',
        name: 'ClientAllergySettings',
        component: () =>
            import('@/views/clients/settings/allergy')
    },
    {
        path: '/clients/allergy-settings/:id',
        name: 'EditClientAllergySettings',
        component: () =>
            import('@/views/clients/settings/allergy/components/EditClientAllergy')
    },
    {
        path: '/clients/add-allergy',
        name: 'AddClientAllergy',
        component: () =>
            import('@/views/clients/settings/allergy/components/AddClientAllergy')
    },

    {
        path: '/clients/mental-status-settings',
        name: 'ClientMentalStatusSettings',
        component: () =>
            import('@/views/clients/settings/mentalStatus')
    },
    {
        path: '/clients/mental-status-settings/:id',
        name: 'EditClientMentalStatusSettings',
        component: () =>
            import('@/views/clients/settings/mentalStatus/components/Edit')
    },
    {
        path: '/clients/add-mental-status',
        name: 'AddClientMentalStatusSettings',
        component: () =>
            import('@/views/clients/settings/mentalStatus/components/Add')
    },
    {
        path: '/clients/mood-settings',
        name: 'ClientMoodSettings',
        component: () =>
            import('@/views/clients/settings/mood')
    },
    {
        path: '/clients/mood-settings/:id',
        name: 'EditClientMoodSettings',
        component: () =>
            import('@/views/clients/settings/mood/components/Edit')
    },
    {
        path: '/clients/add-mood',
        name: 'AddClientMoodSettings',
        component: () =>
            import('@/views/clients/settings/mood/components/Add')
    },
    {
        path: '/clients/fluid-settings',
        name: 'ClientFluidSettings',
        component: () =>
            import('@/views/clients/settings/fluid')
    },
    {
        path: '/clients/fluid-settings/:id',
        name: 'EditClientFluidSettings',
        component: () =>
            import('@/views/clients/settings/fluid/components/Edit')
    },
    {
        path: '/clients/add-fluid',
        name: 'AddClientFluidSettings',
        component: () =>
            import('@/views/clients/settings/fluid/components/Add')
    },
    {
        path: '/clients/supplement-settings',
        name: 'ClientSupplementSettings',
        component: () =>
            import('@/views/clients/settings/supplement')
    },
    {
        path: '/clients/supplement-settings/:id',
        name: 'EditClientSupplementSettings',
        component: () =>
            import('@/views/clients/settings/supplement/components/Edit')
    },
    {
        path: '/clients/add-supplement',
        name: 'AddClientSupplementSettings',
        component: () =>
            import('@/views/clients/settings/supplement/components/Add')
    },
    {
        path: '/clients/medical-equipment-settings',
        name: 'ClientMedicalEquipmentSettings',
        component: () =>
            import('@/views/clients/settings/medicalEquipment')
    },
    {
        path: '/clients/medical-equipment-settings/:id',
        name: 'EditClientMedicalEquipmentSettings',
        component: () =>
            import('@/views/clients/settings/medicalEquipment/components/Edit')
    },
    {
        path: '/clients/add-medical-equipment',
        name: 'AddClientMedicalEquipmentSettings',
        component: () =>
            import('@/views/clients/settings/medicalEquipment/components/Add')
    },
    {
        path: '/clients/precaution-settings',
        name: 'ClientPrecautionSettings',
        component: () =>
            import('@/views/clients/settings/precaution')
    },
    {
        path: '/clients/precaution-settings/:id',
        name: 'EditClientPrecautionSettings',
        component: () =>
            import('@/views/clients/settings/precaution/components/Edit')
    },
    {
        path: '/clients/add-precaution',
        name: 'AddClientPrecautionSettings',
        component: () =>
            import('@/views/clients/settings/precaution/components/Add')
    },
    {
        path: '/clients/ho-fall-settings',
        name: 'ClientHOFallSettings',
        component: () =>
            import('@/views/clients/settings/hoFall')
    },
    {
        path: '/clients/ho-fall-settings/:id',
        name: 'EditClientHOFallSettings',
        component: () =>
            import('@/views/clients/settings/hoFall/components/Edit')
    },
    {
        path: '/clients/add-ho-fall',
        name: 'AddClientHOFallSettings',
        component: () =>
            import('@/views/clients/settings/hoFall/components/Add')
    },
    {
        path: '/clients/functional-limitation-settings',
        name: 'ClientFunctionalLimitationSettings',
        component: () =>
            import('@/views/clients/settings/functionalLimitation')
    },
    {
        path: '/clients/functional-limitation-settings/:id',
        name: 'EditClientFunctionalLimitationSettings',
        component: () =>
            import('@/views/clients/settings/functionalLimitation/components/Edit')
    },
    {
        path: '/clients/add-functional-limitation',
        name: 'AddClientFunctionalLimitationSettings',
        component: () =>
            import('@/views/clients/settings/functionalLimitation/components/Add')
    },
    {
        path: '/clients/goal-settings',
        name: 'ClientGoalSettings',
        component: () =>
            import('@/views/clients/settings/goal')
    },
    {
        path: '/clients/goal-settings/:id',
        name: 'EditClientGoalSettings',
        component: () =>
            import('@/views/clients/settings/goal/components/Edit')
    },
    {
        path: '/clients/add-goal',
        name: 'AddClientGoalSettings',
        component: () =>
            import('@/views/clients/settings/goal/components/Add')
    },
    {
        path: '/clients/activities-permitted-settings',
        name: 'ClientActivitiesPermittedSettings',
        component: () =>
            import('@/views/clients/settings/activitiesPermitted')
    },
    {
        path: '/clients/activities-permitted-settings/:id',
        name: 'EditClientActivitiesPermittedSettings',
        component: () =>
            import('@/views/clients/settings/activitiesPermitted/components/Edit')
    },
    {
        path: '/clients/add-activities-permitted',
        name: 'AddClientActivitiesPermittedSettings',
        component: () =>
            import('@/views/clients/settings/activitiesPermitted/components/Add')
    },
    {
        path: '/clients/dme-supply-settings',
        name: 'ClientDMESupplySettings',
        component: () =>
            import('@/views/clients/settings/dmeSupply')
    },
    {
        path: '/clients/dme-supply-settings/:id',
        name: 'EditClientDMESupplySettings',
        component: () =>
            import('@/views/clients/settings/dmeSupply/components/Edit')
    },
    {
        path: '/clients/add-dme-supply',
        name: 'AddClientDMESupplySettings',
        component: () =>
            import('@/views/clients/settings/dmeSupply/components/Add')
    },
    {
        path: '/clients/safety-measure-settings',
        name: 'ClientSafetyMeasureSettings',
        component: () =>
            import('@/views/clients/settings/safetyMeasure')
    },
    {
        path: '/clients/safety-measure-settings/:id',
        name: 'EditClientSafetyMeasureSettings',
        component: () =>
            import('@/views/clients/settings/safetyMeasure/components/Edit')
    },
    {
        path: '/clients/add-safety-measure',
        name: 'AddClientSafetyMeasureSettings',
        component: () =>
            import('@/views/clients/settings/safetyMeasure/components/Add')
    },
    {
        path: '/clients/prognosis-settings',
        name: 'ClientPrognosisSettings',
        component: () =>
            import('@/views/clients/settings/prognosis')
    },
    {
        path: '/clients/prognosis-settings/:id',
        name: 'EditClientPrognosisSettings',
        component: () =>
            import('@/views/clients/settings/prognosis/components/Edit')
    },
    {
        path: '/clients/add-prognosis',
        name: 'AddClientPrognosisSettings',
        component: () =>
            import('@/views/clients/settings/prognosis/components/Add')
    },

    {
        path: '/clients/symptom-settings',
        name: 'ClientSymptomSettings',
        component: () =>
            import('@/views/clients/settings/symptoms')
    },
    {
        path: '/clients/symptom-settings/:id',
        name: 'EditClientSymptomSettings',
        component: () =>
            import('@/views/clients/settings/symptoms/components/Edit')
    },
    {
        path: '/clients/add-symptom',
        name: 'AddClientSymptomSettings',
        component: () =>
            import('@/views/clients/settings/symptoms/components/Add')
    },

    {
        path: '/clients/treatment-settings',
        name: 'ClientTreatmentSettings',
        component: () =>
            import('@/views/clients/settings/treatments')
    },
    {
        path: '/clients/treatment-settings/:id',
        name: 'EditClientTreatmentSettings',
        component: () =>
            import('@/views/clients/settings/treatments/components/Edit')
    },
    {
        path: '/clients/add-treatment',
        name: 'AddClientTreatmentSettings',
        component: () =>
            import('@/views/clients/settings/treatments/components/Add')
    },

    {
        path: '/clients/booster-required-settings',
        name: 'ClientBoosterRequiredSettings',
        component: () =>
            import('@/views/clients/settings/boosterRequired')
    },
    {
        path: '/clients/booster-required-settings/:id',
        name: 'EditClientBoosterRequiredSettings',
        component: () =>
            import('@/views/clients/settings/boosterRequired/components/EditClientBoosterRequired')
    },
    {
        path: '/clients/add-booster-required',
        name: 'AddClientBoosterRequiredSettings',
        component: () =>
            import('@/views/clients/settings/boosterRequired/components/AddClientBoosterRequired')
    },

    // {
    //     path: 'clients/new',
    //     name: 'ClientNew',
    //     component: () =>
    //         import ('@/views/clients/newindex.vue'),
    // },
    // {
    //     path: 'clients/login',
    //     name: 'ClientLogin',
    //     component: () =>
    //         import ('@/views/clients/login.vue'),
    // },
    // {
    //     path: 'clients/view/:id',
    //     component: () =>
    //         import ('@/views/clients/Details.vue'),
    // },
    {
        path: 'clients/update/:id',
        name: 'Update Client',
        component: () =>
            import ('@/views/clients/edit'),
    },
    {
        path: 'clients/add',
        name: 'Add Client',
        component: () =>
            import ('@/views/clients/create'),
        meta: {
            // requiresAuth: true,
             title: 'Add Client'
        }
    },
    // {
    //     path: 'clients/see/:id',
    //     name: 'See Client',
    //     component: () =>
    //         import ('@/views/clients/clientUpdate.vue'),
    // },
    {
        path: '/clients/welcome',
        name: 'WelcomeClients',
        component: () =>
            import('@/views/dashboard/client/welcome.vue')
    },
]