import { ref } from 'vue';

const userRoles = ref([]);

export function setUserPerms(roles) {
    userRoles.value = roles;
}

export function hasPermission(requiredPermission) {
    if (userRoles.value && userRoles.value.data) {
        for (const role of userRoles.value.data) {
            const hasPerm = role.permissions.some((permission) => permission.name === requiredPermission);
            if (hasPerm) {
                return true;
            }
        }
    }
    return false;
}
