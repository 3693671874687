import {fetchComplaintReasonRequest} from '@/utils/services/complaints/settings/reasons';

const state = {
    reason: {},
}

const getters = {
    getComplaintReason: state => state.reason
}

const actions = {
    async fetchComplaintReason({ commit }, id) {
        const res = await fetchComplaintReasonRequest(id)
        commit('setComplaintReason', res.data.data)

    },
}

const mutations = {
    setComplaintReason(state, payload) {
        state.reason = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}