// incidents module  

export default
    [

        // Program categories start
        {
            path: '/nurse-assessment/settings/categories',
            name: 'CAProgramCategories',
            component: () => import('@/views/nurse_assessment/settings/categories'),
            meta: { requiresAuth: true }
        },
        {
            path: '/nurse-assessment/settings/categories/:id',
            name: 'CAProgramCategory',
            component: () => import('@/views/nurse_assessment/settings/categories/Details'),
            meta: { requiresAuth: true }
        },

        // Program Categories end

        // types start
        {
            path: '/nurse-assessment/settings/types',
            name: 'CAProgramTypes',
            component: () => import('@/views/nurse_assessment/settings/types'),
            meta: { requiresAuth: true }
        },

        {
            path: '/nurse-assessment/settings/types/:id',
            name: 'CATypes',
            component: () => import('@/views/nurse_assessment/settings/types/Details'),
            meta: { requiresAuth: true }
        },
        // types end


        // reasons start

        {
            path: '/nurse-assessment/settings/activity-type',
            name: 'CAType',
            component: () => import('@/views/nurse_assessment/settings/reasons'),
            meta: { requiresAuth: true }
        },

        {
            path: '/nurse-assessment/settings/activity-type/:id',
            name: 'CAReasons',
            component: () => import('@/views/nurse_assessment/settings/reasons/Details'),
            meta: { requiresAuth: true }
        },

        // assesment form start

        {
            path: '/nurse-assessment/settings/activity-type',
            name: 'CAForms',
            component: () => import('@/views/nurse_assessment/settings/reasons'),
            meta: { requiresAuth: true }
        },

        {
            path: '/nurse-assessment/settings/activity-type/:id',
            name: 'CAForm',
            component: () => import('@/views/nurse_assessment/settings/reasons/Details'),
            meta: { requiresAuth: true }
        },


        // service-phase 1 start

        {
            path: '/nurse-assessment/settings/phases',
            name: 'CAPhases',
            component: () => import('@/views/nurse_assessment/settings/investigation-types'),
            meta: { requiresAuth: true }
        },

        // service-phase 1 end

        //service-phase 2 start 

        {
            path: '/nurse-assessment/settings/service-phases-2',
            name: 'CAPhases2',
            component: () => import('@/views/nurse_assessment/settings/service-phase-2'),
            meta: { requiresAuth: true }
        },

        //service-phase 2 end 

        //report start
        {
            path: '/nurse-assessment/settings/reports',
            name: 'CAReport',
            component: () => import('@/views/nurse_assessment/settings/report-types'),
            meta: { requiresAuth: true }
        },

        {
            path: '/nurse-assessment/settings/report',
            name: 'CAReportDetails',
            component: () => import('@/views/nurse_assessment/settings/report-types/Details'),
            meta: { requiresAuth: true }
        },
        //report end

        //service type start
        {
            path: '/nurse-assessment/settings/service-type',
            name: 'CAService',
            component: () => import('@/views/nurse_assessment/settings/service-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/nurse-assessment/settings/service-type/details',
            name: 'CAServiceTypeDetails',
            component: () => import('@/views/nurse_assessment/settings/service-types/Details'),
            meta: { requiresAuth: true }
        },
        //service type end

        //billing start
        {
            path: '/nurse-assessment/settings/billing',
            name: 'CABilling',
            component: () => import('@/views/nurse_assessment/settings/billing-types'),
            meta: { requiresAuth: true }
        },
        {
            path: '/nurse-assessment/settings/billing-setup',
            name: 'CABillingTypeDetails',
            component: () => import('@/views/nurse_assessment/settings/billing-types/Details'),
            meta: { requiresAuth: true }
        },
        //billing end

        //service category start
        {
            path: '/nurse-assessment/settings/service-category',
            name: 'CAServiceCategory',
            component: () => import('@/views/nurse_assessment/settings/service-category'),
            meta: { requiresAuth: true }
        },
        {
            path: '/nurse-assessment/settings/service-category/details',
            name: 'CAServiceCategoryDetails',
            component: () => import('@/views/nurse_assessment/settings/service-category/Details'),
            meta: { requiresAuth: true }
        },
        //service category end

        {
            path: '/nurse-assessment/settings/assessment-forms',
            name: 'CAForms',
            component: () => import('@/views/nurse_assessment/settings/assessmentForms'),
            meta: { requiresAuth: true }
        }, 

         //Task Journey start
         {
            path: '/nurse-assessment/settings/task-journey',
            name: 'CATaskJourney',
            component: () => import('@/views/nurse_assessment/settings/task-journey'),
            meta: { requiresAuth: true }
        },
        //Task Journney end

    ];