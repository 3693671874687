import * as api from '@/utils/services/clients/settings/mood';

const state = {
    mood: {},
    moodList: [],
}

const getters = {
    getClientMood: state => state.mood,
    getClientMoodList: state => state.moodList
}

const actions = {

    async fetchClientMood({ commit }) {
        const res = await api.getClientMood();
        commit('setClientMoodList', res.data)
        return res.data;
    },

    async saveClientMood({ commit }, payload) {
        const res = await api.storeClientMood(payload);
        commit('setClientMood', res.data)
    },

    async updateClientMood({ commit }, payload, id) {
        const res = await api.updateClientMood(payload, id);
        commit('setClientMood', res.data)
    },

    async showClientMood({ commit }, id) {
        const res = await api.showClientMood(id);
        commit('setClientMood', res.data)
        return res.data;
    },

    async deleteClientMood({ commit }, id) {
        const res = await api.deleteClientMood(id);
        commit('setClientMoodList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientMood(state, payload) {
        state.mood = payload
    },

    setClientMoodList(state, payload) {
        state.moodList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}