//TO DO: Import Services
import { getRoleUser } from '../../utils/services/permission/index';

import {
    getSystemActivityLog,    
    getBuildVersion,
} from "@/utils/services/systemactivities";


//UPDATE 1

export const actions = {


  async fetchSystemActivityLog({ commit }, payload) {
    const res = await getSystemActivityLog(payload);
    commit('setSystemActivityLog', res.data);
    return res.data;
  },

  async fetchBuildVersion({ commit }) {   
    const res = await getBuildVersion();
    commit('setBuildVersion', res);
    return res;
    },

}

