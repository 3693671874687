const base ="/nurse-assessment/settings"
import assessment_settings from "./assessment_settings"
export default
  [
    {
      path: '/nurse-assessment',
      name: 'NurseAssessment',
      component: () => import('@/views/nurse_assessment'),
      meta: { requiresAuth: true }
    },
    {
      path: '/nurse-assessment/questions',
      name: 'NurseAssessmentQuestion',
      component: () => import('@/views/nurse_assessment/completeAssessment/customTabComponent/components/CompleteInvestigationDetails'),
      meta: { requiresAuth: true }
    },

    {
        path: '/client-assessment-log',
        name: 'ClientAssessmentLog',
        component: () => import('@/views/nurse_assessment/log'),
        meta: { requiresAuth: true }
    },

    {
        path: '/client-assessment-dashboard',
        name: 'ClientAssessmentDashboard',
        component: () => import('@/views/dashboard/clientAssessment'),
        meta: { requiresAuth: true }
    },

    {
      path: '/social-care-network',
      name: 'SocialCareNetwork',
      component: () => import('@/views/nurse_assessment/social-care'),
      meta: { requiresAuth: true }
    },

    // settings
    {
      path: `${base}`,
      name: 'NASettings',
      component: () => import('@/views/nurse_assessment/settings'),
      meta: { requiresAuth: true }
    },
     
    // spread the settings assessment
    ...assessment_settings
  ];