import * as api from '@/utils/services/clients/settings/precaution';

const state = {
    precaution: {},
    precautionList: [],
}

const getters = {
    getClientPrecaution: state => state.precaution,
    getClientPrecautionList: state => state.precautionList
}

const actions = {

    async fetchClientPrecaution({ commit }) {
        const res = await api.getClientPrecaution();
        commit('setClientPrecautionList', res.data)
        return res.data;
    },

    async saveClientPrecaution({ commit }, payload) {
        const res = await api.storeClientPrecaution(payload);
        commit('setClientPrecaution', res.data)
    },

    async updateClientPrecaution({ commit }, payload, id) {
        const res = await api.updateClientPrecaution(payload, id);
        commit('setClientPrecaution', res.data)
    },

    async showClientPrecaution({ commit }, id) {
        const res = await api.showClientPrecaution(id);
        commit('setClientPrecaution', res.data)
        return res.data;
    },

    async deleteClientPrecaution({ commit }, id) {
        const res = await api.deleteClientPrecaution(id);
        commit('setClientPrecautionList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPrecaution(state, payload) {
        state.precaution = payload
    },

    setClientPrecautionList(state, payload) {
        state.precautionList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}