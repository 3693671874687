import * as api from '@/utils/services/users/settings/gender';

const state = {
    userGender: {},
    userGenderList: [],
}

const getters = {
    getUserGender: state => state.userGender,
    getUserGenderList: state => state.userGenderList
}

const actions = {

    async fetchUserGenders({ commit }) {
        const res = await api.getUserGenders();
        commit('setUserGenderList', res.data)
        return res.data;
    },

    async saveUserGender({ commit }, payload) {
        const res = await api.storeUserGender(payload);
        commit('setUserGender', res.data)
    },

    async updateUserGender({ commit }, payload, id) {
        const res = await api.updateUserGender(payload, id);
        commit('setUserGender', res.data)
    },

    async showUserGender({ commit }, id) {
        const res = await api.showUserGender(id);
        commit('setUserGender', res.data)
        return res.data;
    },

    async deleteUserGender({ commit }, id) {
        const res = await api.deleteUserGender(id);
        commit('setUserGenderList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserGender(state, payload) {
        state.userGender = payload
    },

    setUserGenderList(state, payload) {
        state.userGenderList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}