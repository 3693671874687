export const getters = {
    listTasks: state => state.tasks,
    listTasksUser: state => state.tasks_user,
    listTaskOverview: state => state.overview,
    listTasksTeam: state => state.tasks_team,
    listTaskSelected: state => state.tasks_selected,
    listTaskItem: state => state.task_item,
    listTaskItemCompleted: state => state.task_item_completed,
    listTaskItemOverdue: state => state.task_item_overdue,
    listTaskSubItemSelected: state => state.task_sub_item_selected,
    listTaskFormData: state => state.task_form_data,
    listTaskSubItemOpen: state => state.task_sub_item_open,
    listTaskSubItemRelated: state => state.task_sub_item_related,
    listTaskSubItemReviewed: state => state.task_sub_item_reviewed,
    listTaskSubItemEscalated: state => state.task_sub_item_escalated,
    listTaskDashboardData: state => state.task_dashboard,
     listTaskUserCountData: state => state.task_user_count,
    listHappinessDashboardData: state => state.happiness_dashboard,
    listValueBaseDashboardData: state => state.value_base_dashboard,
    listMetricDashboardData: state => state.metric_dashboard,
    listTaskTemplates: state => state.templates,
    listTaskModules: state => state.modules,
    listTaskCategories: state => state.categories,
    listTaskStatuses: state => state.statuses,
    listFieldTemplates: state => state.field_templates,
    listTaskTemplateDetails: state => state.taskTemplateDetails,
    listTaskTemplateFields: state => state.task_template_detail_fields,
    listAllTaskPriorities: state => state.task_priorities,
    listTaskPriorityDetails: state => state.task_priority_details,
    listAllTaskFrequencies: state => state.task_frequencies,
    listTaskFrequencyDetails: state => state.task_frequency_details,
    listTaskDetails: state => state.task_details,
    visibilityState: state => state.visibilityState,
    sampleTaskInfo: state => state.sampleTask,
    listTaskForReviewSupervisor: state => state.taskForReviewSupervisor,
    FilterState: state => state.FilterState
}