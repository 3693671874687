import * as api from '@/utils/services/users/settings/mobileStatus';

const state = {
    userMobileStatus: {},
    userMobileStatusList: [],
}

const getters = {
    getUserMobileStatus: state => state.userMobileStatus,
    getUserMobileStatusList: state => state.userMobileStatusList
}

const actions = {

    async fetchUserMobileStatus({ commit }) {
        const res = await api.getUserMobileStatus();
        commit('setUserMobileStatusList', res.data)
        return res.data;
    },

    async saveUserMobileStatus({ commit }, payload) {
        const res = await api.storeUserMobileStatus(payload);
        commit('setUserMobileStatus', res.data)
    },

    async updateUserMobileStatus({ commit }, payload, id) {
        const res = await api.updateUserMobileStatus(payload, id);
        commit('setUserMobileStatus', res.data)
    },

    async showUserMobileStatus({ commit }, id) {
        const res = await api.showUserMobileStatus(id);
        commit('setUserMobileStatus', res.data)
        return res.data;
    },

    async deleteUserMobileStatus({ commit }, id) {
        const res = await api.deleteUserMobileStatus(id);
        commit('setUserMobileStatusList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserMobileStatus(state, payload) {
        state.userMobileStatus = payload
    },

    setUserMobileStatusList(state, payload) {
        state.userMobileStatusList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}