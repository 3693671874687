import * as api from '@/utils/services/clients/settings/taskActivitySubtask';

const state = {
    subTask: {},
    subTaskList: [],
}

const getters = {
    getClientTaskActivitySubtask: state => state.subTask,
    getClientTaskActivitySubtaskList: state => state.subTaskList
}

const actions = {

    async fetchClientTaskActivitySubtask({ commit }) {
        const res = await api.getClientTaskActivitySubtask();
        commit('setClientTaskActivitySubtaskList', res.data)
        return res.data;
    },

    async saveClientTaskActivitySubtask({ commit }, payload) {
        const res = await api.storeClientTaskActivitySubtask(payload);
        commit('setClientTaskActivitySubtask', res.data)
    },

    async updateClientTaskActivitySubtask({ commit }, payload, id) {
        const res = await api.updateClientTaskActivitySubtask(payload, id);
        commit('setClientTaskActivitySubtask', res.data)
    },

    async showClientTaskActivitySubtask({ commit }, id) {
        const res = await api.showClientTaskActivitySubtask(id);
        commit('setClientTaskActivitySubtask', res.data)
        return res.data;
    },

    async deleteClientTaskActivitySubtask({ commit }, id) {
        const res = await api.deleteClientTaskActivitySubtask(id);
        commit('setClientTaskActivitySubtaskList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientTaskActivitySubtask(state, payload) {
        state.subTask = payload
    },

    setClientTaskActivitySubtaskList(state, payload) {
        state.subTaskList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}