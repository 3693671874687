export const mutations = {
    setSystemActivityLog(state, logs) {
		state.systemActivityLogList = logs
	},
    setBuildVersion(state, build) {
		state.buildVersion = build
	},

    

}