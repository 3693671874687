import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import store from './store';
import routes from './routes';
import VueLoading from 'vue-loading-overlay';
import './sass/main.scss';
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import vueCountryRegionSelect from 'vue3-country-region-select';
import mitt from 'mitt';
import { runtimeError } from './config/SystemErrorHandler';
import { usePermissions } from '@/gates/usePermissions';
import Tooltip from 'primevue/tooltip';
import UserProfile from './components/UserProfile.vue';
import BuildVersion from './components/BuildVersion.vue';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';



// Use Global import
const app = createApp(App);

// Add the $can function as a global property
app.config.globalProperties.$can = usePermissions().hasPermission;

// This function will handle component runtime errors
runtimeError(app);

// Add Event Bus
const emitter = mitt();
app.provide('emitter', emitter);

app.use(PrimeVue)
   .use(routes)
   .use(store)
   .use(ToastService)
   .use(ConfirmationService)
   .use(VueLoading)
   .use(vueCountryRegionSelect);

   app.directive('tooltip', Tooltip);

   app.component('UserProfile', UserProfile);
   app.component('BuildVersion', BuildVersion);


// // Fetch authUserSystemRolesInfo before mounting the app
// (async () => {
//   try {

//    const getPermissions =  await store.dispatch('getAuthRolePermissionInfo');

//   } catch (error) {
//     // Handle the error here (e.g., log it, show a custom error message)
//      console.error(error);
//   }
// })();

app.mount('#app');

