import * as api from '@/utils/services/users/settings/relationship';

const state = {
    userRelationship: {},
    userRelationshipList: [],
}

const getters = {
    getUserRelationship: state => state.userRelationship,
    getUserRelationshipList: state => state.userRelationshipList
}

const actions = {

    async fetchUserRelationships({ commit }) {
        const res = await api.getUserRelationships();
        commit('setUserRelationshipList', res.data)
        return res.data;
    },

    async saveUserRelationship({ commit }, payload) {
        const res = await api.storeUserRelationship(payload);
        commit('setUserRelationship', res.data)
    },

    async updateUserRelationship({ commit }, payload, id) {
        const res = await api.updateUserRelationship(payload, id);
        commit('setUserRelationship', res.data)
    },

    async showUserRelationship({ commit }, id) {
        const res = await api.showUserRelationship(id);
        commit('setUserRelationship', res.data)
        return res.data;
    },

    async deleteUserRelationship({ commit }, id) {
        const res = await api.deleteUserRelationship(id);
        commit('setUserRelationshipList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserRelationship(state, payload) {
        state.userRelationship = payload
    },

    setUserRelationshipList(state, payload) {
        state.userRelationshipList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}