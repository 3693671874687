// incidents module 
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default
    [
        {
            path: 'incidents/dashboard',
            name: 'IncidentDashboard',
            component: () => import('@/views/incidents/IncidentDashboard.vue'),
        },
        {
            path: 'incidents/add',
            name: 'CreateIncident',
            component: () => import('@/views/incidents/CreateIncident.vue'),
            meta: {
              check: 'create_incidents',
              title: 'Create Issue'
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'incidents',
            name: 'IncidentsLog',
            component: () => import('@/views/incidents/index.vue'),
            meta: {
              check: 'show_incidents',
              title: 'Incidents Log'
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'incidents/:id',
            name: 'IncidentDetails',
            component: () => import('@/views/incidents/Details.vue'),
            meta: {
              check: 'show_incidents',
              title: 'Issue Details'
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/incident-settings',
            name: 'IncidentSettings',
            component: () =>
                import('@/views/incidents/settings'),
            meta: {
                  check: 'show_settings',
            },
            beforeEnter: checkPermissions,
        }, 
        {
            path: '/review-grades',
            name: 'ReviewGrades',
            component: () =>
                import('@/views/incidents/settings/reviews/grades'),
        },

        {
            path: '/incident-settings/incident-categories',
            name: 'IncidentCategories',
            component: () => import('@/views/incidents/settings/categories')
          },

          {
            path: '/incident-settings/incident-categories/:id',
            name: 'IncidentCategory',
            component: () => import('@/views/incidents/settings/categories/Details')
          },

          {
            path: '/incident-settings/incident-types',
            name: 'IncidentTypes',
            component: () => import('@/views/incidents/settings/types')
          },

          {
            path: '/incident-settings/incident-types/:id',
            name: 'IncidentType',
            component: () => import('@/views/incidents/settings/types/Details')
          },

          {
            path: '/incident-settings/incident-reasons',
            name: 'IncidentReasons',
            component: () => import('@/views/incidents/settings/reasons')
          },

          {
            path: '/incident-settings/incident-reasons/:id',
            name: 'IncidentReason',
            component: () => import('@/views/incidents/settings/reasons/Details')
          },

          {
            path: '/incident-settings/investigation-types',
            name: 'IncidentInvestigationTypes',
            component: () => import('@/views/incidents/settings/investigation-types')
          },

          {
            path: '/incident-settings/incident-locations',
            name: 'IncidentLocations',
            component: () => import('@/views/incidents/settings/locations')
          },

          {
            path: '/incident-settings/incident-hours',
            name: 'IncidentHours',
            component: () => import('@/views/incidents/settings/hours')
          },

          {
            path: '/incident-settings/incident-priorities',
            name: 'IncidentPriorities',
            component: () => import('@/views/incidents/settings/priorities')
          },

          {
            path: '/incident-settings/incident-injuries',
            name: 'IncidentInjuries',
            component: () => import('@/views/incidents/settings/injuries')
          },

          {
            path: '/incident-settings/client-relationships',
            name: 'IncidentClientRelationships',
            component: () => import('@/views/incidents/settings/relationships')
          },
          {
            path: '/incident-settings/resolution-timelines',
            name: 'IncidentResolutionTimelines',
            component: () => import('@/views/incidents/settings/resolution-timelines')
          },
          {
            path: '/incident-settings/involved-persons',
            name: 'IncidentInvolvedPersons',
            component: () => import('@/views/incidents/settings/involved-persons')
          },
          {
            path: '/incident-settings/occurred-when-actions',
            name: 'IncidentOccurredWhenActions',
            component: () => import('@/views/incidents/settings/occurred-when-actions')
          },
        
    ];