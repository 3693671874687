import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

// complaints module 
export default
    [
        {
            path: 'complaints/dashboard',
            name: 'ComplaintsDashboard',
            component: () => import('@/views/dashboard/complaints/index.vue'),
            meta: {
               // requiresAuth: true,
                title: 'Complaint Dashboard',
                check: 'show_complaints',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'complaints/add',
            name: 'AddComplaint',
            component: () => import('@/views/complaints/CreateComplaint.vue'),
            meta: {
              check: 'create_complaints',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'complaints/',
            name: 'Complaints',
            component: () => import('@/views/complaints'),
            meta: {
              check: 'show_complaints',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: 'complaints/:id',
            name: 'ComplaintDetails',
            component: () => import('@/views/complaints/Details.vue'),
            meta: {
              check: 'edit_complaints',
            },
            beforeEnter: checkPermissions,
        },
        {
            path: '/complaint-settings',
            name: 'ComplaintSettings',
            component: () =>
                import('@/views/complaints/settings'),
            meta: {
                  check: 'show_settings',
            },
            beforeEnter: checkPermissions,
        }, 

        {
            path: '/complaint-settings/complaint-categories',
            name: 'ComplaintCategories',
            component: () => import('@/views/complaints/settings/categories'),
            meta: {
              check: 'show_settings',
            },
            beforeEnter: checkPermissions,
          },

          {
            path: '/complaint-settings/complaint-categories/:id',
            name: 'ComplaintCategory',
            component: () => import('@/views/complaints/settings/categories/Details')
          },
       
          {
            path: '/complaint-settings/complaint-types',
            name: 'ComplaintTypes',
            component: () => import('@/views/complaints/settings/types')
          },

          {
            path: '/complaint-settings/complaint-types/:id',
            name: 'ComplaintType',
            component: () => import('@/views/complaints/settings/types/Details')
          },

          {
            path: '/complaint-settings/complaint-reasons',
            name: 'ComplaintReasons',
            component: () => import('@/views/complaints/settings/reasons')
          },

          {
            path: '/complaint-settings/complaint-reasons/:id',
            name: 'ComplaintReason',
            component: () => import('@/views/complaints/settings/reasons/Details')
          },

          {
            path: '/complaint-settings/investigation-types',
            name: 'ComplaintInvestigationTypes',
            component: () => import('@/views/complaints/settings/investigation-types')
          },

          {
            path: '/complaint-settings/complaint-hours',
            name: 'ComplaintHours',
            component: () => import('@/views/complaints/settings/hours')
          },
          {
            path: '/complaint-settings/risk-levels',
            name: 'ComplaintRiskLevels',
            component: () => import('@/views/complaints/settings/risk-levels')
          },

          {
            path: '/complaint-settings/client-relationships',
            name: 'ComplaintClientRelationships',
            component: () => import('@/views/complaints/settings/relationships')
          },
       
          {
            path: '/complaint-settings/reporting-timelines',
            name: 'ComplaintReportingTimelines',
            component: () => import('@/views/complaints/settings/reporting-timelines')
          },
    ];