import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: '/admin-staff',
        name: 'AdminStaff',
        component: () =>
            import ('@/views/adminstaff'),
        // meta: {
        //         check: 'client_management_access',
        //     },
        // beforeEnter: checkPermissions,
    },
    {
        path: '/admin-staff/add',
        name: 'Add AdminStaff',
        component: () =>
            import ('@/views/adminstaff/create'),
        // meta: {
        //         check: 'client_management_access',
        //     },
        // beforeEnter: checkPermissions,
    },
    {
        path: 'admin-staff/update/:id',
        name: 'Edit AdminStaff',
        component: () =>
            import ('@/views/adminstaff/edit'),
    },
]