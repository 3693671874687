import { state } from "./formState.js"
import { actions } from "./formActions.js"
import { getters } from "./formGetters.js"
import { mutations } from "./formMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}