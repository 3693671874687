import * as api from '@/utils/services/users/settings/benefitMedical';

const state = {
    userBenefitMedical: {},
    userBenefitMedicalList: [],
}

const getters = {
    getUserBenefitMedical: state => state.userBenefitMedical,
    getUserBenefitMedicalList: state => state.userBenefitMedicalList
}

const actions = {

    async fetchUserBenefitMedicals({ commit }) {
        const res = await api.getUserBenefitMedicals();
        commit('setUserBenefitMedicalList', res.data)
        return res.data;
    },

    async saveUserBenefitMedical({ commit }, payload) {
        const res = await api.storeUserBenefitMedical(payload);
        commit('setUserBenefitMedical', res.data)
    },

    async updateUserBenefitMedical({ commit }, payload, id) {
        const res = await api.updateUserBenefitMedical(payload, id);
        commit('setUserBenefitMedical', res.data)
    },

    async showUserBenefitMedical({ commit }, id) {
        const res = await api.showUserBenefitMedical(id);
        commit('setUserBenefitMedical', res.data)
        return res.data;
    },

    async deleteUserBenefitMedical({ commit }, id) {
        const res = await api.deleteUserBenefitMedical(id);
        commit('setUserBenefitMedicalList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserBenefitMedical(state, payload) {
        state.userBenefitMedical = payload
    },

    setUserBenefitMedicalList(state, payload) {
        state.userBenefitMedicalList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}