import * as api from '@/utils/services/clients/settings/vaccineDoses';

const state = {
    vaccineDose: {},
    vaccineDoseList: [],
}

const getters = {
    getClientVaccineDose: state => state.vaccineDose,
    getClientVaccineDoseList: state => state.vaccineDoseList
}

const actions = {

    async fetchClientVaccineDoses({ commit }) {
        const res = await api.getClientVaccineDoses();
        commit('setClientVaccineDoseList', res.data)
        return res.data;
    },

    async saveClientVaccineDose({ commit }, payload) {
        const res = await api.storeClientVaccineDose(payload);
        commit('setClientVaccineDose', res.data)
    },

    async updateClientVaccineDose({ commit }, payload, id) {
        const res = await api.updateClientVaccineDose(payload, id);
        commit('setClientVaccineDose', res.data)
    },

    async showClientVaccineDose({ commit }, id) {
        const res = await api.showClientVaccineDose(id);
        commit('setClientVaccineDose', res.data)
        return res.data;
    },

    async deleteClientVaccineDose({ commit }, id) {
        const res = await api.deleteClientVaccineDose(id);
        commit('setClientVaccineDoseList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientVaccineDose(state, payload) {
        state.vaccineDose = payload
    },

    setClientVaccineDoseList(state, payload) {
        state.vaccineDoseList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}