import * as api from '@/utils/services/clients/settings/supplyTypes';

const state = {
    supplyType: {},
    supplyTypeList: [],
}

const getters = {
    getClientSupplyType: state => state.supplyType,
    getClientSupplyTypeList: state => state.supplyTypeList
}

const actions = {

    async fetchClientSupplyTypes({ commit }) {
        const res = await api.getClientSupplyTypes();
        commit('setClientSupplyTypeList', res.data)
        return res.data;
    },

    async saveClientSupplyType({ commit }, payload) {
        const res = await api.storeClientSupplyType(payload);
        commit('setClientSupplyType', res.data)
    },

    async updateClientSupplyType({ commit }, payload, id) {
        const res = await api.updateClientSupplyType(payload, id);
        commit('setClientSupplyType', res.data)
    },

    async showClientSupplyType({ commit }, id) {
        const res = await api.showClientSupplyType(id);
        commit('setClientSupplyType', res.data)
        return res.data;
    },

    async deleteClientSupplyType({ commit }, id) {
        const res = await api.deleteClientSupplyType(id);
        commit('setClientSupplyTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientSupplyType(state, payload) {
        state.supplyType = payload
    },

    setClientSupplyTypeList(state, payload) {
        state.supplyTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}