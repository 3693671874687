import * as api from '@/utils/services/users/settings/educationConcentration';

const state = {
    userEducationConcentration: {},
    userEducationConcentrationList: [],
}

const getters = {
    getUserEducationConcentration: state => state.userEducationConcentration,
    getUserEducationConcentrationList: state => state.userEducationConcentrationList
}

const actions = {

    async fetchUserEducationConcentrations({ commit }) {
        const res = await api.getUserEducationConcentrations();
        commit('setUserEducationConcentrationList', res.data)
        return res.data;
    },

    async saveUserEducationConcentration({ commit }, payload) {
        const res = await api.storeUserEducationConcentration(payload);
        commit('setUserEducationConcentration', res.data)
    },

    async updateUserEducationConcentration({ commit }, payload, id) {
        const res = await api.updateUserEducationConcentration(payload, id);
        commit('setUserEducationConcentration', res.data)
    },

    async showUserEducationConcentration({ commit }, id) {
        const res = await api.showUserEducationConcentration(id);
        commit('setUserEducationConcentration', res.data)
        return res.data;
    },

    async deleteUserEducationConcentration({ commit }, id) {
        const res = await api.deleteUserEducationConcentration(id);
        commit('setUserEducationConcentrationList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserEducationConcentration(state, payload) {
        state.userEducationConcentration = payload
    },

    setUserEducationConcentrationList(state, payload) {
        state.userEducationConcentrationList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}