import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'clients/settings/living-alone';

export const getClientLivingAlones = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const storeClientLivingAlone = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateClientLivingAlone = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showClientLivingAlone = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteClientLivingAlone = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
