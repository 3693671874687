
import { fetchSchedulingDropdownsRequest, fetchSystemModelsRequest, fetchSchedulingMatchFormFieldsRequest } from '@/utils/services/schedule_management';
import admin_activities from './settings/admin_activities';

export default {
    state: {
        scheduling_dropdowns: {}, 
        staffType: {},
        system_models: [],
        form_fields: [],

        ...admin_activities.state,
    },

    getters: {
        listSchedulingDropdowns: (state) => state.scheduling_dropdowns, 
        getScheduleStaffType: (state) => state.staffType, 
        getSystemModels: (state) => state.system_models,
        getScheduleMatchFormFields: (state) => state.form_fields,

        ...admin_activities.getters,
    },

    mutations: {
        setSchedulingDropdowns(state, payload) {
            state.scheduling_dropdowns = payload;
        },

        setScheduleStaffType(state, payload) {
            state.staffType = payload;
        },

        setSystemModels(state, payload) {
            state.system_models = payload;
        },

        setScheduleMatchFormFields(state, payload) {
            state.form_fields = payload;
        },
    
        ...admin_activities.mutations
    },

    actions: {
        async fetchSchedulingDropdowns({ commit }) {
            const res = await fetchSchedulingDropdownsRequest()
            commit('setSchedulingDropdowns', res.data)
        },

        async fetchSystemModels({ commit }) {
            const res = await fetchSystemModelsRequest()
            commit('setSystemModels', res.data)
        },

        async fetchScheduleMatchFormFields({ commit }) {
            const res = await fetchSchedulingMatchFormFieldsRequest()
            commit('setScheduleMatchFormFields', res.data.data)
        },

        async setScheduleStaffType({ commit }, staffType) {
            
            commit('setScheduleStaffType', staffType)
        },

        ...admin_activities.actions,

    }
}
