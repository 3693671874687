import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'clients/settings/admin-care-team';

export const getRoles = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/get-roles`, Header);
    } catch (error) {
        console.error('Error fetching roles:', error);
        throw error; // Consider throwing the error to handle it where the function is called
    }
}

export const fetchClientAdminCareTeam = async () => {
    try {
        return await axios.get(`${baseAPI}/clients/settings/admin-care-team`, Header);
    } catch (error) {
        return error;
    }
};


export const getAdminCareTeam = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        console.error('Error fetching admin care team:', error);
        throw error;
    }
}

export const storeAdminCareTeam = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        console.error('Error storing admin care team:', error);
        throw error;
    }
}

// export const updateAdminCareTeam = async (payload, id) => {
//     try {
//         return await axios.put(`${baseAPI}/${requestURI}/${id}`, payload, Header); // Changed to PUT
//     } catch (error) {
//         console.error('Error updating admin care team:', error);
//         throw error;
//     }
// }

// export const showAdminCareTeam = async (id) => {
//     try {
//         return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
//     } catch (error) {
//         console.error('Error showing admin care team:', error);
//         throw error;
//     }
// }

export const deleteClientAdminCareTeam = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header); 
    } catch (error) {
        console.error('Error deleting admin care team:', error);
        throw error;
    }
}

