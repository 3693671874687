import * as api from '@/utils/services/clients/settings/diagnosisTypes';

const state = {
    diagnosisType: {},
    diagnosisTypeList: [],
}

const getters = {
    getClientDiagnosisType: state => state.diagnosisType,
    getClientDiagnosisTypeList: state => state.diagnosisTypeList
}

const actions = {

    async fetchClientDiagnosisTypes({ commit }) {
        const res = await api.getClientDiagnosisTypes();
        commit('setClientDiagnosisTypeList', res.data)
        return res.data;
    },

    async saveClientDiagnosisType({ commit }, payload) {
        const res = await api.storeClientDiagnosisType(payload);
        commit('setClientDiagnosisType', res.data)
    },

    async updateClientDiagnosisType({ commit }, payload, id) {
        const res = await api.updateClientDiagnosisType(payload, id);
        commit('setClientDiagnosisType', res.data)
    },

    async showClientDiagnosisType({ commit }, id) {
        const res = await api.showClientDiagnosisType(id);
        commit('setClientDiagnosisType', res.data)
        return res.data;
    },

    async deleteClientDiagnosisType({ commit }, id) {
        const res = await api.deleteClientDiagnosisType(id);
        commit('setClientDiagnosisTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientDiagnosisType(state, payload) {
        state.diagnosisType = payload
    },

    setClientDiagnosisTypeList(state, payload) {
        state.diagnosisTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}