import * as api from '@/utils/services/users/settings/ethnicity';

const state = {
    userEthnicity: {},
    userEthnicityList: [],
}

const getters = {
    getUserEthnicity: state => state.userEthnicity,
    getUserEthnicityList: state => state.userEthnicityList
}

const actions = {

    async fetchUserEthnicity({ commit }) {
        const res = await api.getUserEthnicity();
        commit('setUserEthnicityList', res.data)
        return res.data;
    },

    async saveUserEthnicity({ commit }, payload) {
        const res = await api.storeUserEthnicity(payload);
        commit('setUserEthnicity', res.data)
    },

    async updateUserEthnicity({ commit }, payload, id) {
        const res = await api.updateUserEthnicity(payload, id);
        commit('setUserEthnicity', res.data)
    },

    async showUserEthnicity({ commit }, id) {
        const res = await api.showUserEthnicity(id);
        commit('setUserEthnicity', res.data)
        return res.data;
    },

    async deleteUserEthnicity({ commit }, id) {
        const res = await api.deleteUserEthnicity(id);
        commit('setUserEthnicityList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserEthnicity(state, payload) {
        state.userEthnicity = payload
    },

    setUserEthnicityList(state, payload) {
        state.userEthnicityList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}