import * as api from '@/utils/services/clients/settings/authorizationSource';

const state = {
    authorizationSource: {},
    authorizationSourceList: [],
}

const getters = {
    getClientAuthorizationSource: state => state.authorizationSource,
    getClientAuthorizationSourceList: state => state.authorizationSourceList
}

const actions = {

    async fetchClientAuthorizationSources({ commit }) {
        const res = await api.getClientAuthorizationSources();
        commit('setClientAuthorizationSourceList', res.data)
        return res.data;
    },

    async saveClientAuthorizationSource({ commit }, payload) {
        const res = await api.storeClientAuthorizationSource(payload);
        commit('setClientAuthorizationSource', res.data)
    },

    async updateClientAuthorizationSource({ commit }, payload, id) {
        const res = await api.updateClientAuthorizationSource(payload, id);
        commit('setClientAuthorizationSource', res.data)
    },

    async showClientAuthorizationSource({ commit }, id) {
        const res = await api.showClientAuthorizationSource(id);
        commit('setClientAuthorizationSource', res.data)
        return res.data;
    },

    async deleteClientAuthorizationSource({ commit }, id) {
        const res = await api.deleteClientAuthorizationSource(id);
        commit('setClientAuthorizationSourceList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientAuthorizationSource(state, payload) {
        state.authorizationSource = payload
    },

    setClientAuthorizationSourceList(state, payload) {
        state.authorizationSourceList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}