import * as api from '@/utils/services/clients/settings/authorizationContractType';

const state = {
    authorizationContractType: {},
    authorizationContractTypeList: [],
}

const getters = {
    getClientAuthorizationContractType: state => state.authorizationContractType,
    getClientAuthorizationContractTypeList: state => state.authorizationContractTypeList
}

const actions = {

    async fetchClientAuthorizationContractTypes({ commit }) {
        const res = await api.getClientAuthorizationContractTypes();
        commit('setClientAuthorizationContractTypeList', res.data)
        return res.data;
    },

    async saveClientAuthorizationContractType({ commit }, payload) {
        const res = await api.storeClientAuthorizationContractType(payload);
        commit('setClientAuthorizationContractType', res.data)
    },

    async updateClientAuthorizationContractType({ commit }, payload, id) {
        const res = await api.updateClientAuthorizationContractType(payload, id);
        commit('setClientAuthorizationContractType', res.data)
    },

    async showClientAuthorizationContractType({ commit }, id) {
        const res = await api.showClientAuthorizationContractType(id);
        commit('setClientAuthorizationContractType', res.data)
        return res.data;
    },

    async deleteClientAuthorizationContractType({ commit }, id) {
        const res = await api.deleteClientAuthorizationContractType(id);
        commit('setClientAuthorizationContractTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientAuthorizationContractType(state, payload) {
        state.authorizationContractType = payload
    },

    setClientAuthorizationContractTypeList(state, payload) {
        state.authorizationContractTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}