import * as api from '@/utils/services/clients/settings/livingSituation';

const state = {
    livingSituation: {},
    livingSituationList: [],
}

const getters = {
    getClientLivingSituation: state => state.livingSituation,
    getClientLivingSituationList: state => state.livingSituationList
}

const actions = {

    async fetchClientLivingSituations({ commit }) {
        const res = await api.getClientLivingSituations();
        commit('setClientLivingSituationList', res.data)
        return res.data;
    },

    async saveClientLivingSituation({ commit }, payload) {
        const res = await api.storeClientLivingSituation(payload);
        commit('setClientLivingSituation', res.data)
    },

    async updateClientLivingSituation({ commit }, payload, id) {
        const res = await api.updateClientLivingSituation(payload, id);
        commit('setClientLivingSituation', res.data)
    },

    async showClientLivingSituation({ commit }, id) {
        const res = await api.showClientLivingSituation(id);
        commit('setClientLivingSituation', res.data)
        return res.data;
    },

    async deleteClientLivingSituation({ commit }, id) {
        const res = await api.deleteClientLivingSituation(id);
        commit('setClientLivingSituationList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientLivingSituation(state, payload) {
        state.livingSituation = payload
    },

    setClientLivingSituationList(state, payload) {
        state.livingSituationList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}