import * as api from '@/utils/services/nurses';


const state = {
    nurses: [],
    nurse: {},
    nurse_details: {},
    nurse_dashboard: {}
}

const getters = {
    listNurses: state => state.nurses,
    getNurse: state => state.nurse,
    listNurseDetails: state => state.nurse_details,
    listNurseDashboardData: state => state.nurse_dashboard
}

const actions = {

    async getNurseDetails({ commit }, id) {
        try {
            const response = await api.nurseDetails(id);
          
            commit('setNurseDetails', response.data.data);

            return response.data.data;

        } catch (error) {
            console.log(error);
        }
    },

    async fetchNurses({ commit }, params) {
        try {
            const response = await api.getAllNurses(params);
          
            commit('setNurses', response.data.data);

            return response.data.data;

        } catch (error) {

            console.log(error);
            
        }
    },

    async addNurse({ commit }, params) {
        try {
            const response = await api.postNurse(params);

            commit('setNurse', response.data);

            console.log('addnurse:', response.data);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateNurse({ commit }, params) {
        try {
            const response = await api.updateNurse(params);

            commit('setNurse', response.data);
          
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async deleteNurse({ commit }, id) {
        try {
            const response = await api.deleteNurse(id);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getNurseDashboardData({ commit }, params) {
        const res = await api.getNurseDashboardData(params);
        if (res && res.data) {
          commit('setNurseDashboardData', res.data);
        } else {
          console.log("getNurseDashboardData returned null or undefined");
          commit('setNurseDashboardData', res);
        }
    },

}

const mutations = {

    setNurses(state, nurse) {
        state.nurses = nurse
    },

    setNurse(state, get_nurse) {
        state.nurse = get_nurse
    },

    setNurseDetails(state, nurse) {
        state.nurse_details = nurse
    },

    setNurseDashboardData(state, data) {
        state.nurse_dashboard = data
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}