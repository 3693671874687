import * as api from '@/utils/services/clients/settings/taskActivityFrequency';

const state = {
    frequency: {},
    frequencyList: [],
}

const getters = {
    getClientTaskActivityFrequency: state => state.frequency,
    getClientTaskActivityFrequencyList: state => state.frequencyList
}

const actions = {

    async fetchClientTaskActivityFrequency({ commit }) {
        const res = await api.getClientTaskActivityFrequency();
        commit('setClientTaskActivityFrequencyList', res.data)
        return res.data;
    },

    async saveClientTaskActivityFrequency({ commit }, payload) {
        const res = await api.storeClientTaskActivityFrequency(payload);
        commit('setClientTaskActivityFrequency', res.data)
    },

    async updateClientTaskActivityFrequency({ commit }, payload, id) {
        const res = await api.updateClientTaskActivityFrequency(payload, id);
        commit('setClientTaskActivityFrequency', res.data)
    },

    async showClientTaskActivityFrequency({ commit }, id) {
        const res = await api.showClientTaskActivityFrequency(id);
        commit('setClientTaskActivityFrequency', res.data)
        return res.data;
    },

    async deleteClientTaskActivityFrequency({ commit }, id) {
        const res = await api.deleteClientTaskActivityFrequency(id);
        commit('setClientTaskActivityFrequencyList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientTaskActivityFrequency(state, payload) {
        state.frequency = payload
    },

    setClientTaskActivityFrequencyList(state, payload) {
        state.frequencyList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}