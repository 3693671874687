export const runtimeError = (app) => {
    app.config.warnHandler = (error) => {
        console.warn('Warning:', error);
    };
    app.config.productionTip = false;
    app.config.errorHandler = (error, vm, info) => {
        const isProduction = process.env.NODE_ENV === 'production';
        
        if (!isProduction) {
            console.error('Vue component error:', error);
            console.error('Vue component name:', vm?.$options?.name);
            console.error('Vue component info:', info);
            if (error && error.stack) {
                console.error('Stack trace:', error.stack);
            }
        } else {
            // Handle production errors (e.g., send to an error reporting service)
        }
    };
};
