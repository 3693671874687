import axios from 'axios';
import config from "@/config";
const { baseAPI, Header, PhotoHeader, AuthHeader, DownloadHeader } = config;

export const indexDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/clients/data/create-dropdown-list`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const filterDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/filter-data`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getInsurance = async() => {
    try {
        return await axios.get(`${baseAPI}/insurances`, Header);
    } catch (error) {
        return error;
    }
};

export const getClientIncidents = async(id) => {
    try {
        return await axios.get(`${baseAPI}/incidents?client_id=${id}`, Header);
    } catch (error) {
        return error
    }
}

export const storeClient = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients`, params, Header);
    } catch (error) {
        return error;
    }
};

export const importClient = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const getClients = async(params) => {
    try {
        return await axios.get(`${baseAPI}/clients`, Header);
    } catch (error) {
        return error;
    }
};

export const getWorkers = async (params) => {
    try {
        return await axios.get(`${baseAPI}/workers`, Header);
    } catch (error) {
        return error;
    }
};

export const getUserWorker = async () => {
    try {
        return await axios.get(`${baseAPI}/workers/detail`, Header);
    } catch (error) {
        return error;
    }
};

export const getWorker = async (params) => {
    try {
        return await axios.get(`${baseAPI}/workers/${params}/detail`, Header);
    } catch (error) {
        return error;
    }
};

export const getClientLog = async(params) => {
    try {
        return await axios.get(`${baseAPI}/clients/client-log`, Header);
    } catch (error) {
        return error;
    }
};


export const getClientDetails = async(params) => {
    try {
        return await axios.get(`${baseAPI}/clients/${params}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClient = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientInfo = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['id']}/update`, params, Header);
    } catch (error) {
        return error;
    }
};


export const updateClientAvatar = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params.get('id')}/update-avatar`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientServiceInfo = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['id']}/service-information`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientPhysicianInfo = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['id']}/physician-information`, params, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientProxies = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['id']}/proxies`, params, Header);
    } catch (error) {
        return error;
    }
};

export const assignClientNurse = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/assign-nurse`, params, Header);
    } catch (error) {
        return error;
    }
};

export const clientAssignedNurses = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/${id}/assigned-nurses`, Header);
    } catch (error) {
        return error;
    }
};

export const assignCoord = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['client_id']}/assign-coordinator`, params, Header);
    } catch (error) {
        return error;
    }
};

export const assignHomecareworker = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/assign-homecareworker`, params, Header);
    } catch (error) {
        return error;
    }
};

export const clientAssignedHomecareworkers = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/${id}/assigned-homecareworkers`, Header);
    } catch (error) {
        return error;
    }
};

export const removeNurse = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/${id}/unassign-nurse`, null, Header);
    } catch (error) {
        return error;
    }
};

export const removeCoord = async(params) => {
    try {
        return await axios.post(`${baseAPI}/clients/${params['client_id']}/unassign-coordinator`, null, Header);
    } catch (error) {
        return error;
    }
};

export const removeHomecareworker = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/${id}/unassign-homecareworker`, null, Header);
    } catch (error) {
        return error;
    }
};

//CLIENTS INCIDENTS
export const clientIncidents = async(params) => {
    try {
        return await axios.get(`${baseAPI}/incidents/${params['client_id']}/`, Header);
    } catch (error) {
        return error;
    }
};

export const getClientDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/clients/data/dashboard?type=${type}&period=${period}`;
        
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        
        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);

        return res;
    } catch (error) {
        console.error('Error getting clients dashboard details:', error);
        return error;
    }
}


//CLIENT DIAGNOSIS
export const storeClientDiagnosis = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/diagnosis`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientDiagnosis = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/diagnosis/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientDiagnosis = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/diagnosis/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientDiagnosis = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/diagnosis/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientDiagnosisLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/diagnosis/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT IMMUNIZATION
export const storeClientImmunization = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/immunization`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientImmunization = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/immunization/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientImmunization = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/immunization/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientImmunization = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/immunization/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientImmunizationLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/immunization/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT HOSPITALIZATION HISTORY
export const storeClientHospitalizationHistory = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/hospitalization-history`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientHospitalizationHistory = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/hospitalization-history/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientHospitalizationHistory = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/hospitalization-history/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientHospitalizationHistory = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/hospitalization-history/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientHospitalizationHistoryLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/hospitalization-history/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// client admin care team
export const fetchClientAdminCareTeam = async () => {
    try {
        return await axios.get(`${baseAPI}/clients/settings/admin-care-team`, Header);
    } catch (error) {
        return error;
    }
};

export const saveClientAdminCareTeam = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/admin-care-team`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientAdminCareTeam = async (payload, id) => {
    try {
        return await axios.put(`${baseAPI}/clients/admin-care-team/${id}`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientAdminCareTeam = async (id) => {
    try {
        return await axios.get(`${baseAPI}/clients/admin-care-team/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientAdminCareTeam = async (id) => {
    try {
        return await axios.delete(`${baseAPI}/clients/admin-care-team/${id}`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT ILLNESS HISTORY
export const storeClientIllnessHistory = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/illness-history`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientIllnessHistory = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/illness-history/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientIllnessHistory = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/illness-history/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientIllnessHistory = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/illness-history/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientIllnessHistoryLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/illness-history/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT MEDICATION MANAGEMENT
export const storeClientMedicationManagement = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/medication-management`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientMedicationManagement = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/medication-management/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientMedicationManagement = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/medication-management/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientMedicationManagement = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/medication-management/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientMedicationManagementLog = async(id) => {
    try {
        // const res = await axios.get(`${baseAPI}/clients/medication-management/${id}/client`, Header);
        // console.log('medic:',res);
        return await axios.get(`${baseAPI}/clients/medication-management/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT SUPPLY
export const storeClientSupply = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/supply`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientSupply = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/supply/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientSupply = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/supply/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientSupply = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/supply/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientSupplyLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/supply/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT HEALTH ITEM
export const storeClientHealthItem = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-item`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientHealthItem = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-item/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientHealthItem = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-item/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientHealthItem = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-item/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientNutritionLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-item/${id}/nutrition`, Header);
    } catch (error) {
        return error;
    }
};

export const clientAllergyLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-item/${id}/allergy`, Header);
    } catch (error) {
        return error;
    }
};

export const clientMedicalEquipmentLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-item/${id}/medical-equipment`, Header);
    } catch (error) {
        return error;
    }
};


//CLIENT PHYSICIAN
export const storeClientPhysician = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/physician`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientPhysician = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/physician/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientPhysician = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/physician/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientPhysician = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/physician/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientPhysicianLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/physician/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT SPECIALIST
export const storeClientSpecialist = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/specialist`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientSpecialist = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/specialist/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientSpecialist = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/specialist/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientSpecialist = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/specialist/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientSpecialistLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/specialist/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT INSURANCE
export const storeClientInsurance = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/insurance`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientInsurance = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/insurance/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientInsurance = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/insurance/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientInsurance = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/insurance/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientInsuranceLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/insurance/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT AUTHORIZATION
export const storeClientAuthorization = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/authorization`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientAuthorization = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/authorization/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientAuthorization = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/authorization/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientAuthorization = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/authorization/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientAuthorizationLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/authorization/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT AUTHORIZATION
export const storeClientM11q = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/m11q`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const updateClientM11q = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/m11q/${payload.id}/update`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};

export const showClientM11q = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/m11q/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientM11q = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/m11q/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientM11qLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/m11q/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

export const downloadM11q = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/m11q/${id}/download`, DownloadHeader);
    } catch (error) {
        return error;
    }
};

//CLIENT HEALTH PROXY
export const storeClientHealthProxy = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-proxy`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientHealthProxy = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-proxy/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientHealthProxy = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-proxy/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientHealthProxy = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/health-proxy/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientHealthProxyLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/health-proxy/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT DEPENDANT
export const storeClientDependant = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/dependant`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientDependant = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/dependant/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientDependant = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/dependant/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientDependant = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/dependant/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientDependantLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/dependant/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

//CLIENT EMERGENCY CONTACT
export const storeClientEmergencyContact = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/emergency-contact`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientEmergencyContact = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/emergency-contact/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientEmergencyContact = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/emergency-contact/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientEmergencyContact = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/emergency-contact/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientEmergencyContactLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/emergency-contact/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT CARE PLAN SUMMARY
export const storeClientCarePlanSummary = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/summary`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientCarePlanSummary = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/summary/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientCarePlanSummary = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/summary/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientCarePlanSummary = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/summary/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanSummaryLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/summary/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT CARE PLAN BASIC INFO
export const storeClientCarePlanBasicInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/basic-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientCarePlanBasicInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/basic-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientCarePlanBasicInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/basic-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientCarePlanBasicInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/basic-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanBasicInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/basic-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT CARE PLAN PATIENT
export const storeClientCarePlanPatient = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/patient`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientCarePlanPatient = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/patient/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientCarePlanPatient = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/patient/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientCarePlanPatient = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/patient/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanPatientLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/patient/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT CARE PLAN OTHER INFO
export const storeClientCarePlanOtherInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/other-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientCarePlanOtherInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/other-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientCarePlanOtherInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/other-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientCarePlanOtherInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/other-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanOtherInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/other-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT CARE PLAN TASK ACTIVITY
export const storeClientCarePlanTaskActivity = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/task-activity`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientCarePlanTaskActivity = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/task-activity/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientCarePlanTaskActivity = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientCarePlanTaskActivity = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/care-plan/task-activity/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanTaskActivityLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

export const clientCarePlanPersonalCareLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/personal-care`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanTransferLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/transfer`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanHouseKeepingLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/house-keeping`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanMedicationLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/medication`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanMiscellaneousLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/miscellaneous`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanNutritionLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/nutrition`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanMobilityLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/mobility`, Header);
    } catch (error) {
        return error;
    }
};
export const clientCarePlanOthersLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/care-plan/task-activity/${id}/client/others`, Header);
    } catch (error) {
        return error;
    }
};



/**
 * ==========================================
 * CLIENT FORM 485
 * ==========================================
 */

// CLIENT FORM 485 SUMMARY
export const storeClientForm485Summary = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/summary`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485nSummary = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/summary/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485Summary = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/summary/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485Summary = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/summary/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485SummaryLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/summary/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// Form 485 Basic Info

// CLIENT CARE PLAN BASIC INFO
export const storeClientForm485BasicInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/basic-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485BasicInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/basic-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485BasicInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/basic-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485BasicInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/basic-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485BasicInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/basic-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


// CLIENT Form 485 OTHER INFO
export const storeClientForm485OtherInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/other-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485OtherInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/other-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485OtherInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/other-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485OtherInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/other-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485OtherInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/other-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT Form 485 ELIGIBILITY INFO
export const storeClientForm485EligibilityInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/eligibility-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485EligibilityInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/eligibility-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485EligibilityInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/eligibility-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485EligibilityInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/eligibility-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485EligibilityInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/eligibility-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

// CLIENT Form 485 Patient INFO
export const storeClientForm485PatientInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/patient-info`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485PatientInfo = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/patient-info/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485PatientInfo = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/patient-info/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485PatientInfo = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/patient-info/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485PatientInfoLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/patient-info/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

export const storeClientForm485Diagnosis = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/diagnosis`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485Diagnosis = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/diagnosis/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485Diagnosis = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/diagnosis/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485Diagnosis = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/diagnosis/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485DiagnosisLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/diagnosis/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};


export const storeClientForm485Medication = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/medication`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const updateClientForm485Medication = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/medication/${payload.id}/update`, payload, Header);
    } catch (error) {
        return error;
    }
};

export const showClientForm485Medication = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/medication/${id}`, Header);
    } catch (error) {
        return error;
    }
};

export const deleteClientForm485Medication = async(id) => {
    try {
        return await axios.post(`${baseAPI}/clients/form485/medication/${id}/delete`, null, Header);
    } catch (error) {
        return error;
    }
};

export const clientForm485MedicationLog = async(id) => {
    try {
        return await axios.get(`${baseAPI}/clients/form485/medication/${id}/client`, Header);
    } catch (error) {
        return error;
    }
};

export const mailPDF = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/clients/data/send-mail`, payload, Header);
    } catch (error) {
        return error
    }
}