import * as api from '@/utils/services/clients/settings/diagnosis';

const state = {
    diagnosisSettings: {},
    diagnosisSettingsList: [],
}

const getters = {
    getClientDiagnosisSettings: state => state.diagnosisSettings,
    getClientDiagnosisSettingsList: state => state.diagnosisSettingsList
}

const actions = {

    async fetchClientDiagnosisSettings({ commit }) {
        const res = await api.getClientDiagnosis();
        commit('setClientDiagnosisSettingsList', res.data)
        return res.data;
    },

    async saveClientDiagnosisSettings({ commit }, payload) {
        const res = await api.storeClientDiagnosis(payload);
        commit('setClientDiagnosisSettings', res.data)
    },

    async updateClientDiagnosisSettings({ commit }, payload, id) {
        const res = await api.updateClientDiagnosis(payload, id);
        commit('setClientDiagnosisSettings', res.data)
    },

    async showClientDiagnosisSettings({ commit }, id) {
        const res = await api.showClientDiagnosis(id);
        commit('setClientDiagnosisSettings', res.data)
        return res.data;
    },

    async deleteClientDiagnosisSettings({ commit }, id) {
        const res = await api.deleteClientDiagnosis(id);
        commit('setClientDiagnosisSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientDiagnosisSettings(state, payload) {
        state.diagnosisSettings = payload
    },

    setClientDiagnosisSettingsList(state, payload) {
        state.diagnosisSettingsList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}