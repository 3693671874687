import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'incident/categories';

export const fetchIncidentCategoriesRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchIncidentCategoryRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const importIncidentCategoriesRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/import`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importIncidentFeedbackQuestionsRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/import-feedback`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveIncidentFeedbackQuestionRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/feedback-questions`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateIncidentFeedbackQuestionRequest = async (id,questionID, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/feedbacks/${id}/questions/${questionID}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteIncidentFeedbackQuestionRequest = async (id,questionID) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/feedbacks/${id}/questions/${questionID}/delete`,[], Header);
    } catch (error) {
        return error
    }
}

export const importIncidentFollowupsRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/import-followups`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importIncidentFollowupQuestionsRequest = async (followupID, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/followups/${followupID}/import-questions`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveIncidentFollowupQuestionRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/followup-questions`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateIncidentFollowupQuestionRequest = async (id,questionID, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/followups/${id}/questions/${questionID}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteIncidentFollowupQuestionRequest = async (id,questionID) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/followups/${id}/questions/${questionID}/delete`,[], Header);
    } catch (error) {
        return error
    }
}