import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

export const saveTenantDetails = async (payload) => {
    try {
  
      const resonse = await axios.post(`${baseAPI}/tenant/save-tenant`, payload, Header);
      return resonse;
    } catch (error) {
      console.error('Error creating Tenant:', error);
      throw error;
    }
  }

  export const getTenants = async (payload) => {
    try {
  
      const resonse = await axios.post(`${baseAPI}/tenant/tenant-list`, payload, Header);
      return resonse;
    } catch (error) {
      console.error('Error creating Tenant:', error);
      throw error;
    }
  }
  