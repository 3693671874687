import * as api from '@/utils/services/users/settings/workMaxHours';

const state = {
    userWorkMaxHours: {},
    userWorkMaxHoursList: [],
}

const getters = {
    getUserWorkMaxHours: state => state.userWorkMaxHours,
    getUserWorkMaxHoursList: state => state.userWorkMaxHoursList
}

const actions = {

    async fetchUserWorkMaxHours({ commit }) {
        const res = await api.getUserWorkMaxHours();
        commit('setUserWorkMaxHoursList', res.data)
        return res.data;
    },

    async saveUserWorkMaxHours({ commit }, payload) {
        const res = await api.storeUserWorkMaxHours(payload);
        commit('setUserWorkMaxHours', res.data)
    },

    async updateUserWorkMaxHours({ commit }, payload, id) {
        const res = await api.updateUserWorkMaxHours(payload, id);
        commit('setUserWorkMaxHours', res.data)
    },

    async showUserWorkMaxHours({ commit }, id) {
        const res = await api.showUserWorkMaxHours(id);
        commit('setUserWorkMaxHours', res.data)
        return res.data;
    },

    async deleteUserWorkMaxHours({ commit }, id) {
        const res = await api.deleteUserWorkMaxHours(id);
        commit('setUserWorkMaxHoursList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserWorkMaxHours(state, payload) {
        state.userWorkMaxHours = payload
    },

    setUserWorkMaxHoursList(state, payload) {
        state.userWorkMaxHoursList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}