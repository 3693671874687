import * as api from '@/utils/services/clients/settings/hoFall';

const state = {
    hoFall: {},
    hoFallList: [],
}

const getters = {
    getClientHOFall: state => state.hoFall,
    getClientHOFallList: state => state.hoFallList
}

const actions = {

    async fetchClientHOFall({ commit }) {
        const res = await api.getClientHOFall();
        commit('setClientHOFallList', res.data)
        return res.data;
    },

    async saveClientHOFall({ commit }, payload) {
        const res = await api.storeClientHOFall(payload);
        commit('setClientHOFall', res.data)
    },

    async updateClientHOFall({ commit }, payload, id) {
        const res = await api.updateClientHOFall(payload, id);
        commit('setClientHOFall', res.data)
    },

    async showClientHOFall({ commit }, id) {
        const res = await api.showClientHOFall(id);
        commit('setClientHOFall', res.data)
        return res.data;
    },

    async deleteClientHOFall({ commit }, id) {
        const res = await api.deleteClientHOFall(id);
        commit('setClientHOFallList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientHOFall(state, payload) {
        state.hoFall = payload
    },

    setClientHOFallList(state, payload) {
        state.hoFallList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}