import * as api from '@/utils/services/users/settings/complianceStatus';

const state = {
    userComplianceStatus: {},
    userComplianceStatusList: [],
}

const getters = {
    getUserComplianceStatus: state => state.userComplianceStatus,
    getUserComplianceStatusList: state => state.userComplianceStatusList
}

const actions = {

    async fetchUserComplianceStatus({ commit }) {
        const res = await api.getUserComplianceStatus();
        commit('setUserComplianceStatusList', res.data)
        return res.data;
    },

    async saveUserComplianceStatus({ commit }, payload) {
        const res = await api.storeUserComplianceStatus(payload);
        commit('setUserComplianceStatus', res.data)
    },

    async updateUserComplianceStatus({ commit }, payload, id) {
        const res = await api.updateUserComplianceStatus(payload, id);
        commit('setUserComplianceStatus', res.data)
    },

    async showUserComplianceStatus({ commit }, id) {
        const res = await api.showUserComplianceStatus(id);
        commit('setUserComplianceStatus', res.data)
        return res.data;
    },

    async deleteUserComplianceStatus({ commit }, id) {
        const res = await api.deleteUserComplianceStatus(id);
        commit('setUserComplianceStatusList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserComplianceStatus(state, payload) {
        state.userComplianceStatus = payload
    },

    setUserComplianceStatusList(state, payload) {
        state.userComplianceStatusList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}