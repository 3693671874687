import * as api from '@/utils/services/clients/settings/authorizationRequestedService';

const state = {
    authorizationRequestedService: {},
    authorizationRequestedServiceList: [],
}

const getters = {
    getClientAuthorizationRequestedService: state => state.authorizationRequestedService,
    getClientAuthorizationRequestedServiceList: state => state.authorizationRequestedServiceList
}

const actions = {

    async fetchClientAuthorizationRequestedServices({ commit }) {
        const res = await api.getClientAuthorizationRequestedServices();
        commit('setClientAuthorizationRequestedServiceList', res.data)
        return res.data;
    },

    async saveClientAuthorizationRequestedService({ commit }, payload) {
        const res = await api.storeClientAuthorizationRequestedService(payload);
        commit('setClientAuthorizationRequestedService', res.data)
    },

    async updateClientAuthorizationRequestedService({ commit }, payload, id) {
        const res = await api.updateClientAuthorizationRequestedService(payload, id);
        commit('setClientAuthorizationRequestedService', res.data)
    },

    async showClientAuthorizationRequestedService({ commit }, id) {
        const res = await api.showClientAuthorizationRequestedService(id);
        commit('setClientAuthorizationRequestedService', res.data)
        return res.data;
    },

    async deleteClientAuthorizationRequestedService({ commit }, id) {
        const res = await api.deleteClientAuthorizationRequestedService(id);
        commit('setClientAuthorizationRequestedServiceList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientAuthorizationRequestedService(state, payload) {
        state.authorizationRequestedService = payload
    },

    setClientAuthorizationRequestedServiceList(state, payload) {
        state.authorizationRequestedServiceList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}