import * as api from '@/utils/services/clients/settings/treatments';

const state = {
    treatmentSettings: {},
    treatmentSettingsList: [],
    treatmentSettingsByDiagnosisList: [],
}

const getters = {
    getClientTreatmentSettings: state => state.treatmentSettings,
    getClientTreatmentSettingsList: state => state.treatmentSettingsList,
    getClientTreatmentSettingsByDiagnosisList: state => state.treatmentSettingsByDiagnosisList
}

const actions = {

    async fetchClientTreatmentSettings({ commit }) {
        const res = await api.getClientTreatments();
        commit('setClientTreatmentSettingsList', res.data)
        return res.data;
    },

    async fetchClientTreatmentSettingsByDiagnosis({ commit }, id) {
        const res = await api.getClientTreatmentsByDiagnosis(id);
        commit('setClientTreatmentSettingsByDiagnosisList', res.data)
        return res.data;
    },

    async saveClientTreatmentSettings({ commit }, payload) {
        const res = await api.storeClientTreatment(payload);
        commit('setClientTreatmentSettings', res.data)
    },

    async updateClientTreatmentSettings({ commit }, payload) {
        const res = await api.updateClientTreatment(payload);
        commit('setClientTreatmentSettings', res.data)
    },

    async showClientTreatmentSettings({ commit }, id) {
        const res = await api.showClientTreatment(id);
        commit('setClientTreatmentSettings', res.data)
        return res.data;
    },

    async deleteClientTreatmentSettings({ commit }, id) {
        const res = await api.deleteClientTreatment(id);
        commit('setClientTreatmentSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientTreatmentSettings(state, payload) {
        state.treatmentSettings = payload
    },

    setClientTreatmentSettingsList(state, payload) {
        state.treatmentSettingsList = payload
    },

    setClientTreatmentSettingsByDiagnosisList(state, payload) {
        state.treatmentSettingsByDiagnosisList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}