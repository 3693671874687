import * as api from '@/utils/services/clients/settings/fluid';

const state = {
    fluid: {},
    fluidList: [],
}

const getters = {
    getClientFluid: state => state.fluid,
    getClientFluidList: state => state.fluidList
}

const actions = {

    async fetchClientFluid({ commit }) {
        const res = await api.getClientFluid();
        commit('setClientFluidList', res.data)
        return res.data;
    },

    async saveClientFluid({ commit }, payload) {
        const res = await api.storeClientFluid(payload);
        commit('setClientFluid', res.data)
    },

    async updateClientFluid({ commit }, payload, id) {
        const res = await api.updateClientFluid(payload, id);
        commit('setClientFluid', res.data)
    },

    async showClientFluid({ commit }, id) {
        const res = await api.showClientFluid(id);
        commit('setClientFluid', res.data)
        return res.data;
    },

    async deleteClientFluid({ commit }, id) {
        const res = await api.deleteClientFluid(id);
        commit('setClientFluidList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientFluid(state, payload) {
        state.fluid = payload
    },

    setClientFluidList(state, payload) {
        state.fluidList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}