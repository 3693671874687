const token = localStorage.getItem("user-token")
// console.log("Token:", token);
const { VUE_APP_BASE_API } = process.env;

export default {
    baseAPI: VUE_APP_BASE_API,
    token,
    Header: {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    },
    header: {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    },
    PhotoHeader: {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    },
    DownloadHeader: {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob',
    },

}