import * as api from '@/utils/services/users/settings/companySector';

const state = {
    userCompanySector: {},
    userCompanySectorList: [],
}

const getters = {
    getUserCompanySector: state => state.userCompanySector,
    getUserCompanySectorList: state => state.userCompanySectorList
}

const actions = {

    async fetchUserCompanySectors({ commit }) {
        const res = await api.getUserCompanySectors();
        commit('setUserCompanySectorList', res.data)
        return res.data;
    },

    async saveUserCompanySector({ commit }, payload) {
        const res = await api.storeUserCompanySector(payload);
        commit('setUserCompanySector', res.data)
    },

    async updateUserCompanySector({ commit }, payload, id) {
        const res = await api.updateUserCompanySector(payload, id);
        commit('setUserCompanySector', res.data)
    },

    async showUserCompanySector({ commit }, id) {
        const res = await api.showUserCompanySector(id);
        commit('setUserCompanySector', res.data)
        return res.data;
    },

    async deleteUserCompanySector({ commit }, id) {
        const res = await api.deleteUserCompanySector(id);
        commit('setUserCompanySectorList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserCompanySector(state, payload) {
        state.userCompanySector = payload
    },

    setUserCompanySectorList(state, payload) {
        state.userCompanySectorList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}