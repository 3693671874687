import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'schedule-management/settings/admin-activities';

export const fetchSchedulingAdminActivitiesRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchSchedulingAdminActivityRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveSchedulingAdminActivityRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateSchedulingAdminActivityRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteSchedulingAdminActivityRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importSchedulingAdminActivityQuestionsRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/import`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveSchedulingAdminActivityQuestionRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/questions`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateSchedulingAdminActivityQuestionRequest = async (id,questionID, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/questions/${questionID}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteSchedulingAdminActivityQuestionRequest = async (id,questionID) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/questions/${questionID}/delete`,[], Header);
    } catch (error) {
        return error
    }
}

export const saveAdminActivityResponseRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${req['admin_activity_id']}/store-activity`, req, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchAdminActivityResponseRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/load-activity`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const fetchAdminActivityCompletedResponseRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/load-completed-activity`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
export const fetchAdminActivityDeleteResponseRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/activity/${id}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}
