import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

export const incidentDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/incident/data/create-dropdown-list`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}


export const getIncidentDashboardData = async (params) => {
    try {
        const { type, period } = params;
        const res = await axios.get(`${baseAPI}/incident/data/dashboard?type=${type}&period=${period}`, Header);

        return res;
    } catch (error) {
        return error
    }
}



export const fetchIncidentsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/incidents`, Header);
    } catch (error) {
        return error
    }
}



export const saveIncidentRequest = async (req) => {
    try {

        const res = await axios.post(`${baseAPI}/incidents`, req, Header);

        return res;

    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const updateIncidentRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['id']}/update`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const deleteIncidentRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['id']}/delete`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getIncidentRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/incidents/${id}`, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}


export const getIncidentSummary = async (id) => {
    try {
        return await axios.get(`${baseAPI}/incidents/summary/${id}`, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}



export const saveIncidentInvestigationRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incident/investigations`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveIncidentInvestigationActivityRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/store-activity`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveIncidentFeedbackRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/feedback`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveIncidentFollowupRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/followup`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveIncidentFollowupFormRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/followup-form`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveIncidentResolutionRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/resolution`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}



export const saveIncidentReviewRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/review`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const assignIncidentRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/assign`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getIncidentReportRequest = async (req) => {
    try {
        axios.defaults.responseType = 'blob';
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/incident-report`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateIncidentInvestigationReportRequest = async (req) => {
    try {
        // axios.defaults.responseType = 'blob';
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/investigation-report`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateIncidentManagedCareReportRequest = async (req) => {
    try {
        axios.defaults.responseType = 'blob';
        return await axios.post(`${baseAPI}/incidents/${req['incident_id']}/managed-care-report`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}



export const fetchUserIncidentsRequest = async (params) => {
    try {
        const { user_id } = params
        return await axios.get(`${baseAPI}/incidents/${user_id}/client`, Header);
    } catch (error) {
        return error
    }
}


export const mailPDF = async (req) => {
    try {
        axios.defaults.responseType = 'json';
        return await axios.post(`${baseAPI}/incidents/send-mail`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}
