import * as api from '@/utils/services/clients/settings/boosterRequired';

const state = {
    boosterRequiredSettings: {},
    boosterRequiredSettingsList: [],
}

const getters = {
    getClientBoosterRequiredSettings: state => state.boosterRequiredSettings,
    getClientBoosterRequiredSettingsList: state => state.boosterRequiredSettingsList
}

const actions = {

    async fetchClientBoosterRequiredSettings({ commit }) {
        const res = await api.getClientBoosterRequired();
        commit('setClientBoosterRequiredSettingsList', res.data)
        return res.data;
    },

    async saveClientBoosterRequiredSettings({ commit }, payload) {
        const res = await api.storeClientBoosterRequired(payload);
        commit('setClientBoosterRequiredSettings', res.data)
    },

    async updateClientBoosterRequiredSettings({ commit }, payload, id) {
        const res = await api.updateClientBoosterRequired(payload, id);
        commit('setClientBoosterRequiredSettings', res.data)
    },

    async showClientBoosterRequiredSettings({ commit }, id) {
        const res = await api.showClientBoosterRequired(id);
        commit('setClientBoosterRequiredSettings', res.data)
        return res.data;
    },

    async deleteClientBoosterRequiredSettings({ commit }, id) {
        const res = await api.deleteClientBoosterRequired(id);
        commit('setClientBoosterRequiredSettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientBoosterRequiredSettings(state, payload) {
        state.boosterRequiredSettings = payload
    },

    setClientBoosterRequiredSettingsList(state, payload) {
        state.boosterRequiredSettingsList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}