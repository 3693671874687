import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'incident/reasons';

export const fetchIncidentReasonsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const fetchIncidentReasonRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}

export const importIncidentReasonsRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/import`, payload, Header);
    } catch (error) {
        return error
    }
}


export const saveIncidentReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateIncidentReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteIncidentReasonRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}