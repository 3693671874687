import { loginRequest } from '@/utils/services/auth';  
export default {
    state: {
        authUser: null, 
    },
    getters: {
        authUser: (state) => state.authUser, 
        isAuthenticated: (state) => !!state.authUser,
    },
    mutations: {
        SET_USER(state, payload) {
            state.authUser = payload;
        }, 
        CLEAR_USER(state) {
            state.authUser = null; // Clear user data when logging out
        },
    },
    actions: {
        async getAuthUser({ commit },payload) {
            const res = await loginRequest(payload)
            commit('SET_USER', res.data.data)
        },
        async logout({ commit }) {
            const res = await logoutUserReq(); // Call your logout API request here
            commit('CLEAR_USER', res.data); // Clear user data when logging out
          },

    }
}
