export default {
    path: '/auth',
    name: 'Auth',
    guest: true,
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
        {
            path: 'login',
            name: 'Login',
            component: () => import('@/views/auth/Login.vue'),
            guest: true
        },
        {
            path: '2fa',
            name: 'TwoFactor',
            component: () => import('@/views/auth/TwoFactorAuth.vue'),
            guest: true

        },
        {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: () => import('@/views/auth/ForgotPassword.vue'),
            guest: true

        },
        {
            path: 'new-password',
            name: 'NewPassword',
            component: () => import('@/views/auth/NewPassword.vue'),
            guest: true

        },
    ]
}