import {fetchComplaintTypeRequest} from '@/utils/services/complaints/settings/types';

const state = {
    type: {},
}

const getters = {
    getComplaintType: state => state.type
}

const actions = {
    async fetchComplaintType({ commit }, id) {
        const res = await fetchComplaintTypeRequest(id)
        commit('setComplaintType', res.data.data)

    },
}

const mutations = {
    setComplaintType(state, payload) {
        state.type = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}