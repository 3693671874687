import * as api from '@/utils/services/users';
import gender from './settings/gender';
import transportationMode from './settings/transportationMode';
import skillCertification from './settings/skillCertification';
import skillLevel from './settings/skillLevel';
import mobileStatus from './settings/mobileStatus';
import jobExperienceLevel from './settings/jobExperienceLevel';
import maritalStatus from './settings/maritalStatus';
import educationCertification from './settings/educationCertification';
import educationConcentration from './settings/educationConcentration';
import companySector from './settings/companySector';
import benefitMedical from './settings/benefitMedical';
import benefit401k from './settings/benefit401k';
import relationship from './settings/relationship';
import ethnicity from './settings/ethnicity';
import petFriendly from './settings/petFriendly';
import smokeFriendly from './settings/smokeFriendly';
import complianceStatus from './settings/complianceStatus';
import complianceResolution from './settings/complianceResolution';
import complianceTestResult from './settings/complianceTestResult';
import complianceActionTaken from './settings/complianceActionTaken';
import workSchedule from './settings/workSchedule';
import workLicenseType from './settings/workLicenseType';
import workLicenseStatus from './settings/workLicenseStatus';
import workMaxHours from './settings/workMaxHours';
import clientType from './settings/clientType';
import homeDistance from './settings/homeDistance';
import racialIdentity from './settings/racialIdentity';
import sexuality from './settings/sexuality';


const state = {
    user: {},
    user_transportation_mode: {},
    userProfile: {},
    user_details: {},
    userStatuses: {},
    roles: {},
    role_details: {},
    users: {},
    user_details_by_id: {},
    user_education: [],
    user_emergency_contact: [],
    user_work_details: {},
    user_work_details_list: [],
    selected_users: {},
    userGroupsList: {},
    userGroupDetailsById: {},
    usersNotInGroup: {},
    usersInGroup: {},
    userLevelsList: {},
    userLevelDetailsById: {},
    usersNotInLevel: {},
    usersInLevel: {},
    system_roles: {},
    usersInSystemRole: {},
    usersNotInSystemRole: {},
    system_role_details: {},
    userSystemRoleInfo: null,

    ...transportationMode.state,
    ...gender.state,
    ...sexuality.state,
    ...benefitMedical.state,
    ...benefit401k.state,
    ...relationship.state,
    ...maritalStatus.state,
    ...mobileStatus.state,
    ...companySector.state,
    ...skillLevel.state,
    ...skillCertification.state,
    ...educationCertification.state,
    ...educationConcentration.state,
    ...jobExperienceLevel.state,

    ...ethnicity.state,
    ...petFriendly.state,
    ...smokeFriendly.state,
    ...complianceStatus.state,
    ...complianceResolution.state,
    ...complianceTestResult.state,
    ...complianceActionTaken.state,
    ...workSchedule.state,
    ...workLicenseType.state,
    ...workLicenseStatus.state,
    ...workMaxHours.state,
    ...clientType.state,
    ...homeDistance.state,
    ...racialIdentity.state,
}

const getters = {
    authStatus: state => state.status,
    currentUser: state => state.currentUser,
    listUsers: state => state.users,
    listUserStatuses: state => state.userStatuses,
    currentUserProfile: state => state.userProfile,
    listUserDetails: state => state.user_details,
    listUserEducation: state => state.user_education,
    listUserEmergencyContact: state => state.user_emergency_contact,
    listUserWorkDetails: state => state.user_work_details,
    listUserTransportationMode: state => state.user_transportation_mode,
    listUserWorkDetailsList: state => state.user_work_details_list,
    listUserRoles: state => state.roles,
    // listAdminCareRoles: state => state.roles,
    listSystemRoles: state => state.system_roles,
    listRoleDetails: state => state.role_details,
    listAllUsers: state => state.users,
    listSelectedUsers: state => state.selected_users,
    listUserDetailsById: state => state.user_details_by_id,
    listAllUserGroups: state => state.userGroupsList,
    listUserGroupDetailsById: state => state.userGroupDetailsById,
    listUsersNotInGroup: state => state.userNotInGroup,
    listUsersInGroup: state => state.usersInGroup,
    listAllUserLevels: state => state.userLevelsList,
    listUserLevelDetailsById: state => state.userLevelDetailsById,
    listUsersNotInLevel: state => state.userNotInLevel,
    listUsersInLevel: state => state.usersInLevel,
    listUsersNotInSystemRole: state => state.usersNotInSystemRole,
    listUsersInSystemRole: state => state.usersInSystemRole,
    listSystemRoleDetails: state => state.system_role_details,
    listAuthUserSystemRoleInfo: state => state.userSystemRoleInfo,

    ...transportationMode.getters,
    ...gender.getters,
    ...sexuality.getters,
    ...benefitMedical.getters,
    ...benefit401k.getters,
    ...relationship.getters,
    ...maritalStatus.getters,
    ...mobileStatus.getters,
    ...companySector.getters,
    ...skillLevel.getters,
    ...skillCertification.getters,
    ...educationCertification.getters,
    ...educationConcentration.getters,
    ...jobExperienceLevel.getters,

    ...ethnicity.getters,
    ...petFriendly.getters,
    ...smokeFriendly.getters,
    ...complianceStatus.getters,
    ...complianceResolution.getters,
    ...complianceTestResult.getters,
    ...complianceActionTaken.getters,
    ...workSchedule.getters,
    ...workLicenseType.getters,
    ...workLicenseStatus.getters,
    ...workMaxHours.getters,
    ...clientType.getters,
    ...homeDistance.getters,
    ...racialIdentity.getters,

}
//THIS ONE IS IN USE
const actions = {

    async fetchUsers({ commit }) {
        try {
            const response = await api.getUsers();

            commit('setUsers', response.data.data);
        } catch (error) {
            console.log(error);
            localStorage.removeItem('user-token');
        }
    },

    async fetchUserStatuses({ commit }) {
        const response = await api.getUserStatus();
        commit('setUserStatuses', response.data);
        return response.data;
    },

    async fetchUserProfile({ commit }, params) {
        try {
            const response = await api.getUserProfile();

            commit('setUserProfile', response.data);
            return response.data;
        } catch (error) {

            throw error;
        }
    },

    async fetchUserDetail({ commit }) {
        try {

            console.log("this is the one being used2222");

            const response = await api.getUserDetails();

            commit('setUserDetails', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUserDetailsById({ commit }, params) {
        try {
            const response = await api.getUserDetailsById(params);

            commit('setUserDetailsById', response.data);
            return response;
        } catch (error) {

            throw error;
        }
    },

    async fetchUserDetailsByIdentifier({ commit }, params) {
        try {
            const response = await api.getUserDetailsByIdentifier(params);

            // commit('setUserDetailsById', response.data);
            commit('setUserDetails', response.data);
            return response;
        } catch (error) {

            throw error;
        }
    },



    async fetchUserById({ commit }, params) {
        try {
            const response = await api.getUserById(params);

            commit('setUser', response.data);
            return response;
        } catch (error) {

            throw error;
        }
    },

    async updateUserById({ commit }, { id, params }) {
        try {
            const response = await api.updateUserById(id, params);

            // commit('setUser', response.data);
            return response;
        } catch (error) {

            throw error;
        }
    },

    // async markAsRead() {
    //     try {
    //         const response = await api.readNotification();

    //         return response.data;
    //     } catch (error) {
    //         throw error.response;
    //     }
    // },

    async fetchUserRoles({ commit }) {
        try {

            const response = await api.getRoles();

            commit('setUserRoles', response.data);
        } catch (error) {

        }
    },

    async fetchRoleDetails({ commit }, params) {
        try {
            const response = await api.getRoleDetails(params);

            commit('setRoleDetails', response.data);
        } catch (error) {
            console.log(error);
        }
    },


    async createUser({ commit }, params) {
        try {
            const response = await api.storeUser(params);
            return response;
        } catch (error) {
            throw error;
        }
    },


    async fetchAllUsers({ commit }, params) {
        try {
            const response = await api.getAllUsers(params);

            commit('setAllUsers', response.data);
        } catch (error) {

        }
    },

    async createUserEmergencyContact({ commit }, params) {
        try {
            const response = await api.storeEmergencyContact(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserEmergencyContact({ commit }, params) {
        try {
            const response = await api.updateEmergencyContact(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async showUserEmergencyContact({ commit }, id) {
        try {
            const response = await api.showEmergencyContact(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteUserEmergencyContact({ commit }, id) {
        try {
            const response = await api.deleteEmergencyContact(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUserEmergencyContact({ commit }, id) {

        try {
            const response = await api.getUserEmergencyContacts(id);

            commit('setUserEmergencyContact', response.data.data);

            return response.data.data;

        } catch (error) {

            throw error.response;
        }
    },

    async createUserEducationDetails({ commit }, params) {
        try {
            const response = await api.storeEducationDetails(params);

            commit('setUserEducation', response.data);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserEducationDetails({ commit }, params) {
        try {
            const response = await api.updateEducationDetails(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async showUserEducationDetails({ commit }, id) {
        try {
            const response = await api.showEducationDetails(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteUserEducationDetails({ commit }, id) {
        try {
            const response = await api.deleteEducationDetails(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUserEducationDetails({ commit }, id) {
        try {
            const response = await api.userEducationDetails(id);

            commit('setUserEducation', response.data.data);

            return response.data.data;
        } catch (error) {

            throw error.response;
        }
    },

    async createUserMedicalDetails({ commit }, params) {
        try {
            const response = await api.storeMedicalDetails(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserMedicalDetails({ commit }, params) {
        try {
            const response = await api.updateMedicalDetails(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteUserMedicalDetails({ commit }, id) {
        try {
            const response = await api.deleteMedicalDetails(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async createUserTransportationMode({ commit }, params) {
        try {
            const response = await api.storeTransportationMode(params);

            commit('setUserTransportationMode', response.data);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserTransportationMode({ commit }, { params }) {
        try {
            const response = await api.updateTransportationMode(params);

            commit('setUserTransportationMode', response.data);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async createUserWorkDetails({ commit }, params) {
        try {
            const response = await api.storeWorkDetails(params);

            commit('setUserWorkDetails', response.data);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserWorkDetails({ commit }, { params }) {
        try {
            const response = await api.updateWorkDetails(params);

            commit('setUserWorkDetails', response.data);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchWorkDetails({ commit }, id) {
        const res = await api.showWorkDetails(id);
        // commit('setUserWorkDetailsList', res.data);
        return res.data
    },

    async deleteUserWorkDetails({ commit }, id) {
        try {
            const response = await api.deleteWorkDetails(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUserWorkDetails({ commit }, id) {
        const res = await api.getUserWorkDetails(id);
        commit('setUserWorkDetailsList', res.data.data);
        return res.data.data;
    },

    async fetchWorkLocationByWork({ commit }, id) {
        const res = await api.getWorkLocationByWork(id);

        // commit('setUserWorkDetailsList', res.data);
        return res.data
    },



    //User Groups Section
    async fetchUserGroups({ commit }) {
        try {
            const response = await api.fetchAllUserGroups();

            commit('setUserGroups', response.data.data);
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async fetchUserGroupById({ commit }, params) {
        try {
            const response = await api.getUserGroupDetailsById(params);

            commit('setUserGroupDetailsById', response.data.data);
        } catch (error) {

            throw error;
        }
    },

    async createUserGroup({ commit }, params) {
        try {
            const response = await api.saveUserGroup(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserGroup({ commit }, params) {
        try {
            const response = await api.updateUserGroup(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteUserGroup({ commit }, id) {
        try {
            const response = await api.deleteUserGroup(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async assignSupervisor(commit, params) {
        try {
            const response = await api.assignSupervisor(params);

            return response;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUsersFilter({ commit }, { search }) {
        try {
            const response = await api.allUsers(search);
            commit('setUsers', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUsersInGroup({ commit }, id) {
        try {
            const response = await api.getUsersInGroup(id);
            commit('setUsersInGroup', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUsersNotInGroup({ commit }, { groupId, search }) {
        try {
            const response = await api.getUsersNotInGroup(groupId, search);
            commit('setUsersNotInGroup', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async groupUser(commit, params) {
        try {
            const response = await api.addUserToGroup(params);

            return response;
        } catch (error) {

            throw error.response;
        }
    },


    //User Levels Section
    async fetchUserLevels({ commit }) {
        try {
            const response = await api.fetchAllUserLevels();

            commit('setUserLevels', response.data.data);
        } catch (error) {
            console.log(error);
            throw error;
        }
    },

    async fetchUserLevelById({ commit }, params) {
        try {
            const response = await api.getUserLevelDetailsById(params);

            commit('setUserLevelDetailsById', response.data.data);
        } catch (error) {

            throw error;
        }
    },

    async createUserLevel({ commit }, params) {
        try {
            const response = await api.saveUserLevel(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async updateUserLevel({ commit }, params) {
        try {
            const response = await api.updateUserLevel(params);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteUserLevel({ commit }, id) {
        try {
            const response = await api.deleteUserLevel(id);

            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUsersInLevel({ commit }, id) {
        try {
            const response = await api.getUsersInLevel(id);
            commit('setUsersInLevel', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async fetchUsersNotInLevel({ commit }, { levelId, search }) {
        try {
            const response = await api.getUsersNotInLevel(levelId, search);
            commit('setUsersNotInLevel', response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async addUserToLevel(commit, params) {
        try {
            const response = await api.addUserToLevel(params);

            return response;
        } catch (error) {

            throw error.response;
        }
    },

    //system roles
    async fetchSystemRoles({ commit }) {
        try {

            const response = await api.getSystemRoles();

            commit('setSystemRoles', response.data);
        } catch (error) {

            throw error.response;
        }
    },

    async createSystemRole({ commit }, params) {
        try {
            const response = await api.storeSystemRole(params);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async updateSystemRole({ commit }, params) {
        try {
            const response = await api.updateSystemRole(params);
            console.log('updateSystemRole ' + response.data);
            return response.data;
        } catch (error) {

            throw error.response;
        }
    },

    async deleteSystemRole({ commit }, id) {
        try {
            const response = await api.deleteSystemRole(id);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchUsersInSystemRole({ commit }, id) {
        try {
            const response = await api.getUsersInSystemRole(id);
            commit('setUsersInSystemRole', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchUsersNotInSystemRole({ commit }, { systemRoleId, search }) {
        try {
            const response = await api.getUsersNotInSystemRole(systemRoleId, search);
            commit('setUsersNotInSystemRole', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async fetchSystemRoleDetails({ commit }, params) {
        try {
            const response = await api.getSystemRoleDetails(params);

            commit('setSystemRoleDetails', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async addUserToSystemRole({ commit }, params) {
        try {
            const response = await api.addUserToSystemRole(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async removeUserFromSystemRole({ commit }, params) {
        try {
            const response = await api.deleteUserFromSystemRole(params);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async authUserSystemRolesInfo({ commit }) {
        try {
            const response = await api.getAuthSystemRoleInfo();
            commit('setUserSystemRoleInfo', response.data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    ...transportationMode.actions,
    ...gender.actions,
    ...sexuality.actions,
    ...benefitMedical.actions,
    ...benefit401k.actions,
    ...relationship.actions,
    ...maritalStatus.actions,
    ...mobileStatus.actions,
    ...companySector.actions,
    ...skillLevel.actions,
    ...skillCertification.actions,
    ...educationCertification.actions,
    ...educationConcentration.actions,
    ...jobExperienceLevel.actions,

    ...ethnicity.actions,
    ...petFriendly.actions,
    ...smokeFriendly.actions,
    ...complianceStatus.actions,
    ...complianceResolution.actions,
    ...complianceTestResult.actions,
    ...complianceActionTaken.actions,
    ...workSchedule.actions,
    ...workLicenseType.actions,
    ...workLicenseStatus.actions,
    ...workMaxHours.actions,
    ...clientType.actions,
    ...homeDistance.actions,
    ...racialIdentity.actions,

}

const mutations = {

    setUser(state, user) {
        state.user = user
    },

    authSuccess(state, token) {
        state.status = 'success';
    },

    setUsers(state, user) {
        state.users = user
    },

    setUserStatuses(state, status) {
        state.userStatuses = status
    },

    setUserProfile(state, profile) {
        state.userProfile = profile
    },


    setUserDetails(state, detail) {
        state.user_details = detail.data
    },

    setUserRoles(state, role) {
        state.roles = role
    },

    setRoleDetails(state, detail) {
        state.role_details = detail
    },

    setUserEducation(state, userEducation) {
        state.user_education = userEducation
    },

    setUserEmergencyContact(state, userContact) {
        state.user_emergency_contact = userContact
    },

    setUserWorkDetails(state, userWork) {
        state.user_work_details = userWork
    },

    setUserTransportationMode(state, data) {
        state.user_transportation_mode = data
    },

    setUserWorkDetailsList(state, userWorkList) {
        state.user_work_details_list = userWorkList
    },

    setSystemRoles(state, system_role) {
        state.system_roles = system_role
    },

    setAllUsers(state, user) {
        state.users = user
    },

    setSelectedUsers(state, user) {
        state.selected_users = selected_users
    },

    setUserDetailsById(state, detailbyid) {
        state.user_details_by_id = detailbyid
    },

    setUserGroups(state, userGroups) {
        state.userGroupsList = userGroups
    },

    setUserGroupDetailsById(state, userGroupById) {
        state.userGroupDetailsById = userGroupById
    },

    setUsersNotInGroup(state, user) {
        state.usersNotInGroup = user
    },

    setUsersInGroup(state, data) {
        state.usersInGroup = data
    },

    setUserLevels(state, userLevels) {
        state.userLevelsList = userLevels
    },

    setUserLevelDetailsById(state, userLevelById) {
        state.userLevelDetailsById = userLevelById
    },

    setUsersNotInLevel(state, user) {
        state.usersNotInLevel = user
    },

    setUsersInLevel(state, data) {
        state.usersInLevel = data
    },

    setUsersNotInSystemRole(state, user) {
        state.usersNotInSystemRole = user
    },

    setUsersInSystemRole(state, data) {
        state.usersInSystemRole = data
    },

    setSystemRoleDetails(state, detail) {
        state.system_role_details = detail
    },

    setUserSystemRoleInfo(state, userSystemPerms) {
        state.userSystemRoleInfo = userSystemPerms
    },

    ...transportationMode.mutations,
    ...gender.mutations,
    ...sexuality.mutations,
    ...relationship.mutations,
    ...benefit401k.mutations,
    ...benefitMedical.mutations,
    ...companySector.mutations,
    ...skillLevel.mutations,
    ...skillCertification.mutations,
    ...mobileStatus.mutations,
    ...maritalStatus.mutations,
    ...jobExperienceLevel.mutations,
    ...educationCertification.mutations,
    ...educationConcentration.mutations,

    ...ethnicity.mutations,
    ...petFriendly.mutations,
    ...smokeFriendly.mutations,
    ...complianceStatus.mutations,
    ...complianceResolution.mutations,
    ...complianceTestResult.mutations,
    ...complianceActionTaken.mutations,
    ...workSchedule.mutations,
    ...workLicenseType.mutations,
    ...workLicenseStatus.mutations,
    ...workMaxHours.mutations,
    ...clientType.mutations,
    ...homeDistance.mutations,
    ...racialIdentity.mutations,


}


export default {
    state,
    getters,
    actions,
    mutations
}