import {fetchIncidentCategoryRequest} from '@/utils/services/incidents/settings/categories';

const state = {
    category: {},
}

const getters = {
    getIncidentCategory: state => state.category
}

const actions = {
    async fetchIncidentCategory({ commit }, id) {
        const res = await fetchIncidentCategoryRequest(id)
        commit('setIncidentCategory', res.data.data)

    },
}

const mutations = {
    setIncidentCategory(state, payload) {
        state.category = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}