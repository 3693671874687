import axios from 'axios';
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;


export const getIntakeCoordinators = async(params) => {
    try {
        // if (params) {
        //     return await axios.get(`${baseAPI}/intake-coordinators`, {
        //         params: {
        //             name: params['name'],
        //             ic_id: params['ic_id']
        //         }
        //     });
        // } else {
        //     return await axios.get(`${baseAPI}/intake-coordinators`, {
        //         params: {
        //             name: '',
        //             ic_id: ''
        //         }
        //     });
        // }
        return await axios.get(`${baseAPI}/intake-coordinators`, Header);
    } catch (error) {
        console.error('Error getting intake coordinators:', error);
        throw error;
    }
};

export const postIntakeCoordinator = async(params) => {
    try {
        return await axios.post(`${baseAPI}/intake-coordinators`, params, Header);
    } catch (error) {
        console.error('Error posting intake coordinator:', error);
        throw error;
    }
};

export const updateIntakeCoordinator = async(params) => {
    try {
        return await axios.post(`${baseAPI}/intake-coordinators/${params['id']}/update`, params, Header);
    } catch (error) {
        console.error('Error updating intake coordinator:', error);
        throw error;
    }
};

export const deleteIntakeCoordinator = async(id) => {
    try {
        return await axios.post(`${baseAPI}/intake-coordinators/${id}/delete`, Header);
    } catch (error) {
        console.error('Error deleting intake coordinator:', error);
        throw error;
    }
};

export const getIntakeCoordinatorDetails = async(id) => {
    try {
        return await axios.get(`${baseAPI}/intake-coordinators/${id}`, Header);
    } catch (error) {
        console.error('Error getting intake coordinator details:', error);
        throw error;
    }
};

export const getIntakeCoordinatorDashboardData = async (params) => {
    try {
        const { type, period } = params;
        const res = await axios.get(`${baseAPI}/intake-coordinators/data/dashboard?type=${type}&period=${period}`, Header);
       
        return res;
    } catch (error) {
        console.error('Error getting intake coordinator dashboard details:', error);
        throw error; 
    }
}

export const importIntakeCoordinators = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/intake-coordinators/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};