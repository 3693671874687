import * as api from '@/utils/services/clients/settings/safetyMeasure';

const state = {
    safetyMeasure: {},
    safetyMeasureList: [],
}

const getters = {
    getClientSafetyMeasure: state => state.safetyMeasure,
    getClientSafetyMeasureList: state => state.safetyMeasureList
}

const actions = {

    async fetchClientSafetyMeasure({ commit }) {
        const res = await api.getClientSafetyMeasure();
        commit('setClientSafetyMeasureList', res.data)
        return res.data;
    },

    async saveClientSafetyMeasure({ commit }, payload) {
        const res = await api.storeClientSafetyMeasure(payload);
        commit('setClientSafetyMeasure', res.data)
    },

    async updateClientSafetyMeasure({ commit }, payload, id) {
        const res = await api.updateClientSafetyMeasure(payload, id);
        commit('setClientSafetyMeasure', res.data)
    },

    async showClientSafetyMeasure({ commit }, id) {
        const res = await api.showClientSafetyMeasure(id);
        commit('setClientSafetyMeasure', res.data)
        return res.data;
    },

    async deleteClientSafetyMeasure({ commit }, id) {
        const res = await api.deleteClientSafetyMeasure(id);
        commit('setClientSafetyMeasureList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientSafetyMeasure(state, payload) {
        state.safetyMeasure = payload
    },

    setClientSafetyMeasureList(state, payload) {
        state.safetyMeasureList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}