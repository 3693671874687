import * as api from '@/utils/services/users/settings/workSchedule';

const state = {
    userWorkSchedule: {},
    userWorkScheduleList: [],
}

const getters = {
    getUserWorkSchedule: state => state.userWorkSchedule,
    getUserWorkScheduleList: state => state.userWorkScheduleList
}

const actions = {

    async fetchUserWorkSchedule({ commit }) {
        const res = await api.getUserWorkSchedule();
        commit('setUserWorkScheduleList', res.data)
        return res.data;
    },

    async saveUserWorkSchedule({ commit }, payload) {
        const res = await api.storeUserWorkSchedule(payload);
        commit('setUserWorkSchedule', res.data)
    },

    async updateUserWorkSchedule({ commit }, payload, id) {
        const res = await api.updateUserWorkSchedule(payload, id);
        commit('setUserWorkSchedule', res.data)
    },

    async showUserWorkSchedule({ commit }, id) {
        const res = await api.showUserWorkSchedule(id);
        commit('setUserWorkSchedule', res.data)
        return res.data;
    },

    async deleteUserWorkSchedule({ commit }, id) {
        const res = await api.deleteUserWorkSchedule(id);
        commit('setUserWorkScheduleList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserWorkSchedule(state, payload) {
        state.userWorkSchedule = payload
    },

    setUserWorkScheduleList(state, payload) {
        state.userWorkScheduleList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}