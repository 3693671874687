import * as api from '@/utils/services/clients/settings/specialistType';

const state = {
    specialistType: {},
    specialistTypeList: [],
}

const getters = {
    getClientSpecialistType: state => state.specialistType,
    getClientSpecialistTypeList: state => state.specialistTypeList
}

const actions = {

    async fetchClientSpecialistTypes({ commit }) {
        const res = await api.getClientSpecialistTypes();
        commit('setClientSpecialistTypeList', res.data)
        return res.data;
    },

    async saveClientSpecialistType({ commit }, payload) {
        const res = await api.storeClientSpecialistType(payload);
        commit('setClientSpecialistType', res.data)
    },

    async updateClientSpecialistType({ commit }, payload, id) {
        const res = await api.updateClientSpecialistType(payload, id);
        commit('setClientSpecialistType', res.data)
    },

    async showClientSpecialistType({ commit }, id) {
        const res = await api.showClientSpecialistType(id);
        commit('setClientSpecialistType', res.data)
        return res.data;
    },

    async deleteClientSpecialistType({ commit }, id) {
        const res = await api.deleteClientSpecialistType(id);
        commit('setClientSpecialistTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientSpecialistType(state, payload) {
        state.specialistType = payload
    },

    setClientSpecialistTypeList(state, payload) {
        state.specialistTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}