import * as api from '@/utils/services/users/settings/homeDistance';

const state = {
    userHomeDistance: {},
    userHomeDistanceList: [],
}

const getters = {
    getUserHomeDistance: state => state.userHomeDistance,
    getUserHomeDistanceList: state => state.userHomeDistanceList
}

const actions = {

    async fetchUserHomeDistance({ commit }) {
        const res = await api.getUserHomeDistance();
        commit('setUserHomeDistanceList', res.data)
        return res.data;
    },

    async saveUserHomeDistance({ commit }, payload) {
        const res = await api.storeUserHomeDistance(payload);
        commit('setUserHomeDistance', res.data)
    },

    async updateUserHomeDistance({ commit }, payload, id) {
        const res = await api.updateUserHomeDistance(payload, id);
        commit('setUserHomeDistance', res.data)
    },

    async showUserHomeDistance({ commit }, id) {
        const res = await api.showUserHomeDistance(id);
        commit('setUserHomeDistance', res.data)
        return res.data;
    },

    async deleteUserHomeDistance({ commit }, id) {
        const res = await api.deleteUserHomeDistance(id);
        commit('setUserHomeDistanceList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserHomeDistance(state, payload) {
        state.userHomeDistance = payload
    },

    setUserHomeDistanceList(state, payload) {
        state.userHomeDistanceList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}