import * as api from '@/utils/services/users/settings/workLicenseType';

const state = {
    userWorkLicenseType: {},
    userWorkLicenseTypeList: [],
}

const getters = {
    getUserWorkLicenseType: state => state.userWorkLicenseType,
    getUserWorkLicenseTypeList: state => state.userWorkLicenseTypeList
}

const actions = {

    async fetchUserWorkLicenseType({ commit }) {
        const res = await api.getUserWorkLicenseType();
        commit('setUserWorkLicenseTypeList', res.data)
        return res.data;
    },

    async saveUserWorkLicenseType({ commit }, payload) {
        const res = await api.storeUserWorkLicenseType(payload);
        commit('setUserWorkLicenseType', res.data)
    },

    async updateUserWorkLicenseType({ commit }, payload, id) {
        const res = await api.updateUserWorkLicenseType(payload, id);
        commit('setUserWorkLicenseType', res.data)
    },

    async showUserWorkLicenseType({ commit }, id) {
        const res = await api.showUserWorkLicenseType(id);
        commit('setUserWorkLicenseType', res.data)
        return res.data;
    },

    async deleteUserWorkLicenseType({ commit }, id) {
        const res = await api.deleteUserWorkLicenseType(id);
        commit('setUserWorkLicenseTypeList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserWorkLicenseType(state, payload) {
        state.userWorkLicenseType = payload
    },

    setUserWorkLicenseTypeList(state, payload) {
        state.userWorkLicenseTypeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}