import * as api from '@/utils/services/clients/settings/relationship';

const state = {
    relationship: {},
    relationshipList: [],
}

const getters = {
    getClientRelationship: state => state.relationship,
    getClientRelationshipList: state => state.relationshipList
}

const actions = {

    async fetchClientRelationships({ commit }) {
        const res = await api.getClientRelationships();
        commit('setClientRelationshipList', res.data)
        return res.data;
    },

    async saveClientRelationship({ commit }, payload) {
        const res = await api.storeClientRelationship(payload);
        commit('setClientRelationship', res.data)
    },

    async updateClientRelationship({ commit }, payload, id) {
        const res = await api.updateClientRelationship(payload, id);
        commit('setClientRelationship', res.data)
    },

    async showClientRelationship({ commit }, id) {
        const res = await api.showClientRelationship(id);
        commit('setClientRelationship', res.data)
        return res.data;
    },

    async deleteClientRelationship({ commit }, id) {
        const res = await api.deleteClientRelationship(id);
        commit('setClientRelationshipList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientRelationship(state, payload) {
        state.relationship = payload
    },

    setClientRelationshipList(state, payload) {
        state.relationshipList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}