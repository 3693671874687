import * as api from '@/utils/services/users/settings/jobExperienceLevel';

const state = {
    userJobExperienceLevel: {},
    userJobExperienceLevelList: [],
}

const getters = {
    getUserJobExperienceLevel: state => state.userJobExperienceLevel,
    getUserJobExperienceLevelList: state => state.userJobExperienceLevelList
}

const actions = {

    async fetchUserJobExperienceLevels({ commit }) {
        const res = await api.getUserJobExperienceLevels();
        commit('setUserJobExperienceLevelList', res.data)
        return res.data;
    },

    async saveUserJobExperienceLevel({ commit }, payload) {
        const res = await api.storeUserJobExperienceLevel(payload);
        commit('setUserJobExperienceLevel', res.data)
    },

    async updateUserJobExperienceLevel({ commit }, payload, id) {
        const res = await api.updateUserJobExperienceLevel(payload, id);
        commit('setUserJobExperienceLevel', res.data)
    },

    async showUserJobExperienceLevel({ commit }, id) {
        const res = await api.showUserJobExperienceLevel(id);
        commit('setUserJobExperienceLevel', res.data)
        return res.data;
    },

    async deleteUserJobExperienceLevel({ commit }, id) {
        const res = await api.deleteUserJobExperienceLevel(id);
        commit('setUserJobExperienceLevelList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserJobExperienceLevel(state, payload) {
        state.userJobExperienceLevel = payload
    },

    setUserJobExperienceLevelList(state, payload) {
        state.userJobExperienceLevelList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}