import { state } from "./notificationState.js"
import { actions } from "./notificationActions.js"
import { getters } from "./notificationGetters.js"
import { mutations } from "./notificationMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}