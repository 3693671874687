import * as api from '@/utils/services/clients/settings/allergy';

const state = {
    allergySettings: {},
    allergySettingsList: [],
}

const getters = {
    getClientAllergySettings: state => state.allergySettings,
    getClientAllergySettingsList: state => state.allergySettingsList
}

const actions = {

    async fetchClientAllergySettings({ commit }) {
        const res = await api.getClientAllergies();
        commit('setClientAllergySettingsList', res.data)
        return res.data;
    },

    async saveClientAllergySettings({ commit }, payload) {
        const res = await api.storeClientAllergy(payload);
        commit('setClientAllergySettings', res.data)
    },

    async updateClientAllergySettings({ commit }, payload, id) {
        const res = await api.updateClientAllergy(payload, id);
        commit('setClientAllergySettings', res.data)
    },

    async showClientAllergySettings({ commit }, id) {
        const res = await api.showClientAllergy(id);
        commit('setClientAllergySettings', res.data)
        return res.data;
    },

    async deleteClientAllergySettings({ commit }, id) {
        const res = await api.deleteClientAllergy(id);
        commit('setClientAllergySettingsList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientAllergySettings(state, payload) {
        state.allergySettings = payload
    },

    setClientAllergySettingsList(state, payload) {
        state.allergySettingsList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}