import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [
    {
        path: '/tasks',
        name: 'Tasks',
        meta: {
            check: 'show_task',
            title: 'Task Log'
        },
        component: () => import('@/views/tasks'),
        beforeEnter: checkPermissions,
    },

    {
        path: '/tasks/my-task/:sampleId?',
        name: 'MyTasks',
        meta: {
            title: 'My Tasks',
        },
        component: () =>
            import('@/views/tasks/MyTasks.vue')
    },
    {
        path: '/tasks/staff-metrics/:userId/:sampleId?',
        name: 'StaffMetricsTasks',
        component: () =>
            import('@/views/tasks/StaffMetric.vue')
    },
     // 
    {
        path: '/staff/happiness-score-analysis/:userId/:sampleId?',
        name: 'StaffHappinessScoreAnalysis',
        component: () =>
            import('@/views/tasks/StaffHappinessScore.vue')
    },
    // 
    {
        path: '/staff/care-score-analysis/:userId/:sampleId?',
        name: 'StaffCareScoreAnalysis',
        component: () =>
            import('@/views/tasks/StaffCareScore.vue')
    },
    {
        path: '/tasks/user-group-task',
        name: 'UserGroupTasks',
        meta: {
            title: 'User Group Tasks',
        },
        component: () =>
            import('@/views/tasks/TeamTasks.vue')
    },
    {
        path: '/tasks/create',
        name: 'CreateTask',
        component: () =>
            import('@/views/tasks/tasksActions/CreateTask.vue')
    },
    {
        path: '/tasks/update/:id',
        name: 'UpdateTask',
        component: () =>
            import('@/views/tasks/tasksActions/UpdateTask.vue')
    },
    {
        path: '/tasks/:id',
        name: 'selectedTask',
        component: () =>
            import('@/views/tasks/selectedTask/index.vue')
    },
    {
        path: '/tasks/escalate/:id',
        name: 'Escalate',
        component: () =>
            import('@/views/tasks/tasksActions/TaskEscalate.vue')
    },
    {
        path: '/tasks/review/:id',
        name: 'Rate Task Review',
        component: () =>
            import('@/views/tasks/tasksActions/RateReview.vue')
    },
    {
        path: '/tasks/reassign/:id',
        name: 'Task Reassign',
        component: () =>
            import('@/views/tasks/tasksActions/TaskReassign.vue')
    },
    {
        path: '/tasks/reassign-original/:id',
        name: 'Task Reassign',
        component: () =>
            import('@/views/tasks/tasksActions/TaskReassignOriginal.vue')
    },
    {
        path: '/tasks/e-signature',
        name: 'Sign Document',
        component: () =>
            import('@/views/tasks/tasksActions/Esignature.vue')
    },
    {
        path: '/tasks/task-summary',
        name: 'TaskSummary',
        component: () =>
            import('@/views/tasks/taskSummary')
    },
    {
        path: '/tasks/settings',
        name: 'taskSettings',
        component: () =>
            import('@/views/tasks/taskSettings'),
        meta: {
                check: 'show_settings',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/tasks/task-reasons-settings',
        name: 'TaskReasonsSettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskDeleteReasonsSettings.vue')
    },
    {
        path: '/tasks/task-frequency-settings',
        name: 'TaskFrequencySettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskFrequencySettings.vue')
    },
    {
        path: '/tasks/task-priority-settings',
        name: 'TaskPrioritySettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskPrioritySettings.vue')
    },
    {
        path: '/tasks/task-type-settings',
        name: 'TaskTypeSettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskTypeSettings.vue')
    },
    {
        path: '/tasks/task-status-settings',
        name: 'TaskStatusSettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskStatusSettings.vue')
    },
    {
        path: '/tasks/task-category-settings',
        name: 'TaskCategorySettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskCategorySettings.vue')
    },
    {
        path: '/tasks/task-user-summary-settings',
        name: 'TaskUserSummarySettings',
        component: () =>
            import('@/views/tasks/taskSettings/TaskUserSummarySettings.vue')
    },
    {
        path: '/tasks/metrics-settings',
        name: 'MetricsAnalysisSettings',
        component: () =>
            import('@/views/tasks/taskSettings/MetricsAnalysisSettings.vue')
    },
    {
        path: '/tasks/create-priority',
        name: 'CreateTaskPriority',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskPriority.vue')
    },
    {
        path: '/tasks/create-task-type',
        name: 'CreateTaskType',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskType.vue')
    },
    {
        path: '/tasks/create-task-reasons',
        name: 'CreateTaskReasons',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskReasons.vue')
    },
    {
        path: '/tasks/create-task-frequency',
        name: 'CreateTaskFrequency',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskFrequency.vue')
    },
    {
        path: '/tasks/create-task-status',
        name: 'CreateTaskStatus',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskStatus.vue')
    },
    {
        path: '/tasks/create-task-category',
        name: 'CreateTaskCategory',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateTaskCategory.vue')
    },
    {
        path: '/tasks/create-metrics-analysis/:id',
        name: 'CreateMetricsAnalysis',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/CreateMetricsAnalysis.vue')
    },
    {
        path: '/tasks/edit-metrics-analysis/:id',
        name: 'EditMetricsAnalysis',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/EditMetricsAnalysis.vue')
    },
    {
        path: '/tasks/list-metrics-analysis/:id',
        name: 'ListMetricsAnalysis',
        component: () =>
            import('@/views/tasks/taskSettings/actionsTable/ListMetricsAnalysis.vue')
    },
    {
        path: '/tasks/sampled/:type/:id',
        name: 'LoadSampleTask',
        component: () =>
            import('@/views/tasks/sampledTask/LoadSampleTask.vue')
    },


];