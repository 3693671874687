import * as api from '@/utils/services/clients/settings/goal';

const state = {
    goal: {},
    goalList: [],
}

const getters = {
    getClientGoal: state => state.goal,
    getClientGoalList: state => state.goalList
}

const actions = {

    async fetchClientGoal({ commit }) {
        const res = await api.getClientGoal();
        commit('setClientGoalList', res.data)
        return res.data;
    },

    async saveClientGoal({ commit }, payload) {
        const res = await api.storeClientGoal(payload);
        commit('setClientGoal', res.data)
    },

    async updateClientGoal({ commit }, payload, id) {
        const res = await api.updateClientGoal(payload, id);
        commit('setClientGoal', res.data)
    },

    async showClientGoal({ commit }, id) {
        const res = await api.showClientGoal(id);
        commit('setClientGoal', res.data)
        return res.data;
    },

    async deleteClientGoal({ commit }, id) {
        const res = await api.deleteClientGoal(id);
        commit('setClientGoalList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientGoal(state, payload) {
        state.goal = payload
    },

    setClientGoalList(state, payload) {
        state.goalList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}