import * as api from '@/utils/services/clients/settings/newProblems';

const state = {
    newProblem: {},
    newProblemList: [],
}

const getters = {
    getClientNewProblem: state => state.newProblem,
    getClientNewProblemList: state => state.newProblemList
}

const actions = {

    async fetchClientNewProblems({ commit }) {
        const res = await api.getClientNewProblems();
        commit('setClientNewProblemList', res.data)
        return res.data;
    },

    async saveClientNewProblem({ commit }, payload) {
        const res = await api.storeClientNewProblem(payload);
        commit('setClientNewProblem', res.data)
    },

    async updateClientNewProblem({ commit }, payload, id) {
        const res = await api.updateClientNewProblem(payload, id);
        commit('setClientNewProblem', res.data)
    },

    async showClientNewProblem({ commit }, id) {
        const res = await api.showClientNewProblem(id);
        commit('setClientNewProblem', res.data)
        return res.data;
    },

    async deleteClientNewProblem({ commit }, id) {
        const res = await api.deleteClientNewProblem(id);
        commit('setClientNewProblemList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientNewProblem(state, payload) {
        state.newProblem = payload
    },

    setClientNewProblemList(state, payload) {
        state.newProblemList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}