import * as api from '@/utils/services/users/settings/transportationMode';

const state = {
    userTransportationMode: {},
    userTransportationModeList: [],
}

const getters = {
    getUserTransportationMode: state => state.userTransportationMode,
    getUserTransportationModeList: state => state.userTransportationModeList
}

const actions = {

    async fetchUserTransportationModes({ commit }) {
        const res = await api.getUserTransportationModes();
        commit('setUserTransportationModeList', res.data)
        return res.data;
    },

    async saveUserTransportationMode({ commit }, payload) {
        const res = await api.storeUserTransportationMode(payload);
        commit('setUserTransportationMode', res.data)
    },

    async updateUserTransportationMode({ commit }, payload, id) {
        const res = await api.updateUserTransportationMode(payload, id);
        commit('setUserTransportationMode', res.data)
    },

    async showUserTransportationMode({ commit }, id) {
        const res = await api.showUserTransportationMode(id);
        commit('setUserTransportationMode', res.data)
        return res.data;
    },

    async deleteUserTransportationMode({ commit }, id) {
        const res = await api.deleteUserTransportationMode(id);
        commit('setUserTransportationModeList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserTransportationMode(state, payload) {
        state.userTransportationMode = payload
    },

    setUserTransportationModeList(state, payload) {
        state.userTransportationModeList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}