import * as api from '@/utils/services/clients/settings/activitiesPermitted';

const state = {
    activitiesPermitted: {},
    activitiesPermittedList: [],
}

const getters = {
    getClientActivitiesPermitted: state => state.activitiesPermitted,
    getClientActivitiesPermittedList: state => state.activitiesPermittedList
}

const actions = {

    async fetchClientActivitiesPermitted({ commit }) {
        const res = await api.getActivitiesPermitted();
        commit('setClientActivitiesPermittedList', res.data)
        return res.data;
    },

    async saveClientActivitiesPermitted({ commit }, payload) {
        const res = await api.storeClientActivitiesPermitted(payload);
        commit('setClientActivitiesPermitted', res.data)
    },

    async updateClientActivitiesPermitted({ commit }, payload, id) {
        const res = await api.updateClientActivitiesPermitted(payload, id);
        commit('setClientActivitiesPermitted', res.data)
    },

    async showClientActivitiesPermitted({ commit }, id) {
        const res = await api.showClientActivitiesPermitted(id);
        commit('setClientActivitiesPermitted', res.data)
        return res.data;
    },

    async deleteClientActivitiesPermitted({ commit }, id) {
        const res = await api.deleteClientActivitiesPermitted(id);
        commit('setClientActivitiesPermittedList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientActivitiesPermitted(state, payload) {
        state.activitiesPermitted = payload
    },

    setClientActivitiesPermittedList(state, payload) {
        state.activitiesPermittedList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}