import * as api from '@/utils/services/clients/settings/livingAlone';

const state = {
    livingAlone: {},
    livingAloneList: [],
}

const getters = {
    getClientLivingAlone: state => state.livingAlone,
    getClientLivingAloneList: state => state.livingAloneList
}

const actions = {

    async fetchClientLivingAlones({ commit }) {
        const res = await api.getClientLivingAlones();
        commit('setClientLivingAloneList', res.data)
        return res.data;
    },

    async saveClientLivingAlone({ commit }, payload) {
        const res = await api.storeClientLivingAlone(payload);
        commit('setClientLivingAlone', res.data)
    },

    async updateClientLivingAlone({ commit }, payload, id) {
        const res = await api.updateClientLivingAlone(payload, id);
        commit('setClientLivingAlone', res.data)
    },

    async showClientLivingAlone({ commit }, id) {
        const res = await api.showClientLivingAlone(id);
        commit('setClientLivingAlone', res.data)
        return res.data;
    },

    async deleteClientLivingAlone({ commit }, id) {
        const res = await api.deleteClientLivingAlone(id);
        commit('setClientLivingAloneList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientLivingAlone(state, payload) {
        state.livingAlone = payload
    },

    setClientLivingAloneList(state, payload) {
        state.livingAloneList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}