import { state } from "./systemActivityState.js"
import { actions } from "./systemActivityActions.js"
import { getters } from "./systemActivityGetters.js"
import { mutations } from "./systemActivityMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}