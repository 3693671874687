import * as api from '@/utils/services/intake-coordinators';


const state = {
    intakecoordinator: {},
    intakecoordinators: [],
    intakecoordinatordetails: {},
    intakecoordinator_dashboard: {},
}

const getters = {
    getIntakeCoordinator: state => state.intakecoordinator,
    listIntakeCoordinators: state => state.intakecoordinators,
    listIntakeCoordinatorDetails: state => state.intakecoordinatordetails,
    listIntakeCoordinatorDashboardData: state => state.intakecoordinator_dashboard
}

const actions = {

    async fetchAllIntakeCoordinators({commit}, params) {
        try {
            const response = await api.getIntakeCoordinators(params);
            commit('setIntakeCoordinators', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async addIntakeCoordinator({commit}, params) {
        try {
            const response = await api.postIntakeCoordinator(params);
            commit('setIntakeCoordinator', response.data);
            return response.data;
        } catch (error) {
            
            throw error.response;
        }
    },

    async updateIntakeCoordinator({commit}, params) {
        try {
            const response = await api.updateIntakeCoordinator(params);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getIntakeCoordinatorDetails({ commit }, id) {
        try {
            const response = await api.getIntakeCoordinatorDetails(id);
            commit('setIntakeCoordinatorDetails', response.data.data);
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },

    async deleteIntakeCoordinator({commit}, id) {
        try {
            const response = await api.deleteIntakeCoordinator(id);
          
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getIntakeCoordinatorDashboardData({ commit }, params) {
        const res = await api.getIntakeCoordinatorDashboardData(params);
        if (res && res.data) {
          commit('setIntakeCoordinatorDashboardData', res.data);
        } else {
          console.log("getIntakeCoordinatorDashboardData returned null or undefined");
          commit('setIntakeCoordinatorDashboardData', res);
        }
    },

}

const mutations = {

    setIntakeCoordinator(state, intakecord) {
        state.intakecoordinator = intakecord
    },
    setIntakeCoordinators(state, intakecords) {
        state.intakecoordinators = intakecords
    },
    setIntakeCoordinatorDetails(state, intakecordDetails) {
        state.intakecoordinatordetails = intakecordDetails
    },
    setIntakeCoordinatorDashboardData(state, data) {
        state.intakecoordinator_dashboard = data
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}