import axios from "axios";
import config from "@/config"
const { baseAPI, Header, PhotoHeader } = config;



// export const storeCompany = async (params) => {
// 	try {
// 	  return await axios.post(`${baseAPI}/companies/store-company`, params, Header);
// 	} catch (error) {
// 	  return error
// 	}
//   }


  
//   export const getAllCompanies = async (params) => {
// 	try {
// 		let page = params == null ? '?page=1' : params;
// 	  return await axios.get(`${baseAPI}/companies/${page}`, Header);
// 	} catch (error) {
// 	  return error
// 	}
//   }


//   export const updateCompany = async (id) => {
// 	try {
// 	  return await axios.post(`${baseAPI}/companies/${id}/update`,  Header);
// 	} catch (error) {
// 	  return error
// 	}
//   }


  
//   export const deleteCompany = async (id) => {
// 	try {
// 	  return await axios.post(`${baseAPI}/companies/${id}/delete`, id, Header);
// 	} catch (error) {
// 	  return error
// 	}
//   }





export default {

	getAllCompanies(params) {
		let page = params == null ? '?page=1' : params
		return axios.get(`${baseAPI}/companies/${page}`, Header)
	},


	storeCompany(payload) {
		return axios.post(`${baseAPI}/companies/store-company`, payload, PhotoHeader);
	},

	updateCompany(id, payload) {
		return axios.post(`${baseAPI}/companies/${id}/update`, payload, PhotoHeader)
	},

	deleteCompany(id) {
		return axios.delete(`${baseAPI}/companies/${id}/delete`, Header)
	},

	getCompanyDetail(id) {
		return axios.get(`${baseAPI}/companies/${id}`, Header);
	},

	searchCompanies(data) {
		return axios.get(`${baseAPI}/companies/${data}/search`, Header);
	},

}