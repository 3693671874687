import * as api from '@/utils/services/clients/settings/recurrenceRates';

const state = {
    recurrenceRate: {},
    recurrenceRateList: [],
}

const getters = {
    getClientRecurrenceRate: state => state.recurrenceRate,
    getClientRecurrenceRateList: state => state.recurrenceRateList
}

const actions = {

    async fetchClientRecurrenceRates({ commit }) {
        const res = await api.getClientRecurrenceRates();
        commit('setClientRecurrenceRateList', res.data)
        return res.data;
    },

    async saveClientRecurrenceRate({ commit }, payload) {
        const res = await api.storeClientRecurrenceRate(payload);
        commit('setClientRecurrenceRate', res.data)
    },

    async updateClientRecurrenceRate({ commit }, payload, id) {
        const res = await api.updateClientRecurrenceRate(payload, id);
        commit('setClientRecurrenceRate', res.data)
    },

    async showClientRecurrenceRate({ commit }, id) {
        const res = await api.showClientRecurrenceRate(id);
        commit('setClientRecurrenceRate', res.data)
        return res.data;
    },

    async deleteClientRecurrenceRate({ commit }, id) {
        const res = await api.deleteClientRecurrenceRate(id);
        commit('setClientRecurrenceRateList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientRecurrenceRate(state, payload) {
        state.recurrenceRate = payload
    },

    setClientRecurrenceRateList(state, payload) {
        state.recurrenceRateList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}