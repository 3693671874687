
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';

export default
    [
        {
            path: 'tenant/add-tenant',
            name: 'AddTenant',
            component: () => import('@/views/multitenant/CreateTenant.vue'),
            meta: {
               // requiresAuth: true,
                title: 'Add New Tenant',
                // check: 'form_management_access',
            },
            // beforeEnter: checkPermissions,
        },
        {
            path: 'tenants',
            name: 'TenantList',
            component: () => import('@/views/multitenant/index.vue'),
            meta: {
               // requiresAuth: true,
                title: 'All Tenants',
                // check: 'form_management_access',
            },
            // beforeEnter: checkPermissions,
        },

    ]