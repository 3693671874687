
import { checkPermissions } from '@/utils/routeService/useCheckPermissions';



export default
    [
        {
            path: '/system-activity-log',
            name: 'SystemActivityLogs',
            component: () => import('@/views/systemActions/SystemActivityLog.vue'),
            meta: {
               // requiresAuth: true,
                title: 'System Activity Log',
                // check: 'form_management_access',
            },
            // beforeEnter: checkPermissions,
        },
     
        
        

        


    ];