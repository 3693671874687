import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;


export const getNotifications = async () => {
    try {
  
      const res = await axios.get(`${baseAPI}/notifications`, Header);
  
      return res;
    } catch (error) {
      console.error('Failed to get Notifications:', error);
      return error;
    }
  }

  export const getUserNotifications = async () => {
    try {
  
      const res = await axios.get(`${baseAPI}/user/notifications`, Header);
  
      return res.data;
    } catch (error) {
      console.error('Error getting Notifications:', error);
      return error;
    }
  }


  

  