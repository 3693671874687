import { ref } from "vue";

export const userPermissions = ref([]);

export function setRolePerms(rolePerms) {
    userPermissions.value = rolePerms;
   // console.log(userPermissions.value);
}

function filterRoutesByPermissions(routes) {
    return routes.filter((route) => {

        const slug = route.meta?.check;
        if (!slug) {
            return true; // No meta.slug, include the route
        }

       // console.log(userPermissions.value);

        return userPermissions.value[slug]?.hasPermissions || false;
    }).map((route) => {
        if (route.children) {
            route.children = filterRoutesByPermissions(route.children);
        }
        return route;
    });
}

export function getFilteredRoutes(routes) {
    return filterRoutesByPermissions(routes);
}