import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

export const complaintDropdownsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/complaint/data/dropdown-list`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}


export const getComplaintDashboardData = async (params) => {
    try {
        const { type, period } = params;
        const res = await axios.get(`${baseAPI}/complaint/data/dashboard?type=${type}&period=${period}`, Header);
       
        return res;
    } catch (error) {
       return error
    }
}



export const fetchComplaintsRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/complaints`, Header);
    } catch (error) {
        return error
    }
}



export const saveComplaintRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const updateComplaintRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['id']}/update`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const deleteComplaintRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['id']}/delete`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getComplaintRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/complaints/${id}`, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}



export const saveComplaintInvestigationRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaint/investigations`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveComplaintInvestigationActivityRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/store-activity`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveComplaintFeedbackRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/feedback`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveComplaintFollowupRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/followup`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveComplaintFollowupFormRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/followup-form`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const saveComplaintResolutionRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/resolution`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const setInvestigationAsNotApplicableRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/complaints/investigations/${id}/not-applicable`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveComplaintReviewRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/review`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const assignComplaintRequest = async (req) => {
    try {
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/assign`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const getComplaintReportRequest = async (req) => {
    try {
        axios.defaults.responseType = 'blob';
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/complaint-report`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const generateComplaintManagedCareReportRequest = async (req) => {
    try {
        axios.defaults.responseType = 'blob';
        return await axios.post(`${baseAPI}/complaints/${req['complaint_id']}/managed-care-report`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const mailPDF = async (req) => {
    try {
        axios.defaults.responseType = 'json';
        return await axios.post(`${baseAPI}/complaints/send-mail`, req, Header);
    } catch (error) {
        console.log(error)
        toastMsg(error.response.data.message, "error");
        return error
    }
}