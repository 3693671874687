import * as api from '@/utils/services/clients/settings/adminCareTeam';

const state = {
    adminCareTeam: {},
    adminCareTeamList: [],
}

const getters = {
    getClientAdminCareTeam: state => state.adminCareTeam,
    getClientAdminCareTeamList: state => state.adminCareTeamList
}

const actions = {
    async fetchClientAdminCareTeam({ commit }) {
        try {
            const res = await api.fetchClientAdminCareTeam(); // Updated API call
            commit('setClientAdminCareTeamList', res.data);
            return res.data;
        } catch (error) {
            console.error('Error fetching admin care team:', error);
        }
    },

    async saveClientAdminCareTeam({ commit }, payload) {
        try {
            const res = await api.saveClientAdminCareTeam(payload); // Updated API call
            commit('setClientAdminCareTeam', res.data);
        } catch (error) {
            console.error('Error saving admin care team:', error);
        }
    },

    async updateClientAdminCareTeam({ commit }, payload, id) {
        try {
            const res = await api.updateClientAdminCareTeam(payload, id); // Updated API call
            commit('setClientAdminCareTeam', res.data);
        } catch (error) {
            console.error('Error updating admin care team:', error);
        }
    },

    async showClientAdminCareTeam({ commit }, id) {
        try {
            const res = await api.showClientAdminCareTeam(id); // Updated API call
            commit('setClientAdminCareTeam', res.data);
            return res.data;
        } catch (error) {
            console.error('Error showing admin care team:', error);
        }
    },

    async deleteClientAdminCareTeam({ commit }, id) {
        try {
            const res = await api.deleteClientAdminCareTeam(id); // Updated API call
            commit('setClientAdminCareTeamList', res.data);
            return res.data;
        } catch (error) {
            console.error('Error deleting admin care team:', error);
        }
    },
}

const mutations = {
    setClientAdminCareTeam(state, payload) {
        state.adminCareTeam = payload;
    },

    setClientAdminCareTeamList(state, payload) {
        state.adminCareTeamList = payload;
    },
}

export default {
    state,
    getters,
    actions,
    mutations	
}
