import axios from "axios";
import config from "@/config"
import toastMsg from "@/utils/messages/toastMsg";
const { baseAPI, Header } = config;

const requestURI = 'complaint/categories';

export const fetchComplaintCategoriesRequest = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const fetchComplaintCategoryRequest = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        toastMsg(error.response.data.message, "error");
        return error
    }
}

export const importComplaintCategoriesRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/import`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importComplaintFeedbackQuestionsRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/import-feedback`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importComplaintFollowupsRequest = async (id, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/import-followups`, payload, Header);
    } catch (error) {
        return error
    }
}

export const importComplaintFollowupQuestionsRequest = async (followupID, payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/followups/${followupID}/import-questions`, payload, Header);
    } catch (error) {
        return error
    }
}

export const saveComplaintCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateComplaintCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const deleteComplaintCategoryRequest = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${payload['id']}/delete`, payload, Header);
    } catch (error) {
        return error
    }
}