import api from '@/utils/services/companies';
import axios from 'axios';

const state = {
	companies: [],
	companiesSearch: [],
	errors: {},
	loading: false,
	companyDetail: {},

}

const getters = {
	companies: state => state.companies,
	loading: state => state.loading,
	errors: state => state.errors,
	companyDetail: state => state.companyDetail,
	companiesSearch: state => state.companiesSearch
}

const actions = {

	async fetchAllCompanies(content, params) {
		content.commit('setLoader', true)
		api.getAllCompanies(params)
			.then(response => {

				content.commit('setAllCompanies', response.data.companies)
				content.commit('setLoader', false)
			}).catch(error => {
				console.log(error.response)
			})
	},

	async saveCompany(content, params) {
		return new Promise((resolve, reject) => {
			api.storeCompany(params)
				.then(response => {
					resolve(response)
				})
				.catch(error => {
					content.commit('setError', error.response.data.errors)
					content.commit('setLoader', false)
					reject(error.response)
				})
		})
	},

	async singleCompanyDetail(content, id) {
		return new Promise((resolve, reject) => {
			api.getCompanyDetail(id)
				.then(response => {
					content.commit('setCompanyDetail', response.data.company)
					content.commit('setLoader', false)
				})
				.catch(error => {
					content.commit('setLoader', false)
				})
		})
	},

	async getSearchCompanies(content, data) {
		return new Promise((resolve, reject) => {
			api.searchCompanies(data)
				.then(response => {
					content.commit('setCompaniessearch', response.data.company)
				
					content.commit('setLoader', false)
				})
				.catch(error => {
					content.commit('setLoader', false)
				})
		})
	},

	async updateCompany({ commit }, { data, id }) {
		let api = process.env.VUE_BASE_API + '/companies/' + id + '/update'
	
		const config = {
			headers: { 'content-type': 'application/x-www-form-urlencoded' }
		}
		const response = await axios.post(api, data, config)
			.then((response) => {

			}).catch(error => {
				commit('setError', error.response.data.errors)
				commit('setLoader', false)
			})
	},

	async destroyCompany(content, id) {
		return new Promise((resolve, reject) => {
			api.deleteCompany(id)
				.then(response => {
					
					resolve(response)
				})
				.catch(error => {
					console.log(error.response.data)
					reject(error.response)
				})
		})
	},


}

const mutations = {

	setAllCompanies(state, companies) {
		state.companies = companies
	},

	setLoader(state, loading) {
		state.loading = loading
	},

	setError(state, errors) {
		state.errors = errors
	},

	setCompanyDetail(state, companyDetail) {
		state.companyDetail = companyDetail
	},

	setCompaniessearch(state, companiesSearch) {
		state.companiesSearch = companiesSearch
	},

}

export default {
	state,
	getters,
	actions,
	mutations
}