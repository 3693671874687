import * as api from '@/utils/services/usermodules';


const state = {
    adminStaff: {},
    otherStaff: {},
    adminStaffList: [],
    otherStaffList: [],
    administrativeRole: {},
    userAdministrativeRoles: [],
    jobExperience: {},
    userJobExperience: [],
    bankDetails: {},
    userBankDetails: [],
    compensation: {},
    userCompensation: [],
    dependant: {},
    userDependant: [],
    benefit: {},
    userBenefit: [],
    skills: {},
    userSkills: [],
    workLocation: {},
    userWorkLocations: [],
    userRecentWorkLocations: [],
    workSchedule: {},
    userWorkSchedules: [],
    userRecentWorkSchedules: [],
    workLicense: {},
    userWorkLicenses: [],
    userRecentWorkLicenses: [],
    userAdminRoleWorkLicenses: [],
    userRecentAdminRoleWorkLicenses: [],
    compliance: {},
    userCompliance: {},
    complianceAnnualPhysical: {},
    complianceInservice: {},
    userComplianceAnnualPhysical: [],
    userComplianceInService: [],
    adminStaff_dashboard: {},

}

const getters = {
    getAdminStaff: state => state.adminStaff,
    getOtherStaff: state => state.otherStaff,
    getAdminStaffList: state => state.adminStaffList,
    getOtherStaffList: state => state.otherStaffList,
    getAdministrativeRole: state => state.administrativeRole,
    getUserAdministrativeRoles: state => state.userAdministrativeRoles,
    getJobExperience: state => state.jobExperience,
    getUserJobExperience: state => state.userJobExperience,
    getSkills: state => state.skills,
    getUserSkills: state => state.userSkills,
    getBankDetails: state => state.bankDetails,
    getUserBankDetails: state => state.userBankDetails,
    getCompensation: state => state.compensation,
    getUserCompensation: state => state.userCompensation,
    getDependant: state => state.dependant,
    getUserDependant: state => state.userDependant,
    getBenefit: state => state.benefit,
    getUserBenefit: state => state.userBenefit,
    getWorkLocation: state => state.workLocation,
    getUserWorkLocations: state => state.userWorkLocations,
    getUserRecentWorkLocations: state => state.userRecentWorkLocations,
    getWorkSchedule: state => state.workSchedule,
    getUserWorkSchedules: state => state.userWorkSchedules,
    getUserRecentWorkSchedules: state => state.userRecentWorkSchedules,
    getWorkLicense: state => state.workLicense,
    getUserWorkLicenses: state => state.userWorkLicenses,
    getUserRecentWorkLicenses: state => state.userRecentWorkLicenses,
    getUserAdminRoleWorkLicenses: state => state.userAdminRoleWorkLicenses,
    getUserRecentAdminRoleWorkLicenses: state => state.userRecentAdminRoleWorkLicenses,
    getCompliance: state => state.compliance,
    getUserCompliance: state => state.userCompliance,
    getComplianceInService: state => state.complianceInService,
    getComplianceAnnualPhysical: state => state.complianceAnnualPhysical,
    getUserComplianceInService: state => state.userComplianceInService,
    getUserComplianceAnnualPhysical: state => state.userComplianceAnnualPhysical,
    listAdminStaffDashboardData: state => state.adminStaff_dashboard,
}

const actions = {

    async fetchAdminStaffUsers({ commit }) {
        const res = await api.getAdminStaffUsers();
        commit('setAdminStaffList', res.data.data);
        
        return res.data.data;
    },

    async fetchOtherStaffUsers({ commit }) {
        const res = await api.getOtherStaffUsers();
        commit('setOtherStaffList', res.data.data);
        
        return res.data.data;
    },

    async saveAdminStaff({ commit }, payload) {
        try {
          const response = await api.storeAdminStaff(payload);
          commit('setAdminStaff', response.data)
          return response.data;
        } catch (error) {
          throw error;
        }
      },

      async saveOtherStaff({ commit }, payload) {
        try {
          const response = await api.storeOtherStaff(payload);
          commit('setOtherStaff', response.data)
          return response.data;
        } catch (error) {
          throw error;
        }
      },

    async updateAdminStaff({ commit }, payload) {
        const res = await api.updateAdminStaff(payload);
        
        commit('setAdminStaff', res.data)
        return res.data;
    },

    async updateOtherStaff({ commit }, payload) {
        const res = await api.updateOtherStaff(payload);
        
        commit('setOtherStaff', res.data)
        return res.data;
    },

    async deleteAdminStaff({ commit }, id) {
        const res = await api.deleteAdminStaff(id);
        
        return res.data;
    },

    async showAdminStaff({ commit }, id) {
        const res = await api.showAdminStaff(id);

        return res.data.data;
    },

    async showOtherStaff({ commit }, id) {
        const res = await api.showOtherStaff(id);     
        
        return res.data.data;
    },

    async getAdminStaffDashboardData({ commit }, params) {
        const res = await api.getAdminStaffDashboardData(params);
        if (res && res.data) {
          commit('setAdminStaffDashboardData', res.data);
        } else {
          console.log("getAdminStaffDashboardData returned null or undefined");
          commit('setAdminStaffDashboardData', res);
        }
    },

    // async deleteAdminStaff(id) {
    //     const response = await api.deleteAdminStaff(id);
    //     return response.data;
    //   },

    async saveAdministrativeRole({ commit }, payload) {
        const res = await api.storeAdministrativeRole(payload);        
        commit('setAdministrativeRole', res.data)
        return res.data;
    },

    async updateAdministrativeRole({ commit }, payload) {
        const res = await api.updateAdministrativeRole(payload);        
        return res.data;
    },

    async deleteAdministrativeRole({ commit }, id) {
        const res = await api.deleteAdministrativeRole(id);        
        return res.data;
    },

    async showAdministrativeRole({ commit }, id) {
        const res = await api.showAdministrativeRole(id);        
        return res.data;
    },

    async fetchUserAdministrativeRoles({ commit }, id) {
        const res = await api.getUserAdministrativeRoles(id);
        // return res.data
        commit('setUserAdministrativeRoles', res.data.data);
    },

    async saveJobExperience({ commit }, payload) {
        const res = await api.storeJobExperience(payload);        
        commit('setJobExperience', res.data)
    },

    async updateJobExperience({ commit }, payload) {
        const res = await api.updateJobExperience(payload);        
        commit('setJobExperience', res.data)
    },

    async deleteJobExperience({ commit }, id) {
        const res = await api.deleteJobExperience(id);        
        return res.data;
    },

    async showJobExperience({ commit }, id) {
        const res = await api.showJobExperience(id);        
        return res.data;
    },

    async fetchUserJobExperience({ commit }, id) {
        const res = await api.getUserJobExperience(id);   
        // return res.data
        commit('setUserJobExperience', res.data.data);
    },

    async saveSkills({ commit }, payload) {
        const res = await api.storeSkills(payload);
        commit('setSkills', res.data);
        return res.data;
    },

    async updateSkills({ commit }, payload) {
        const res = await api.updateSkills(payload);        
        commit('setSkills', res.data)
    },

    async deleteSkills({ commit }, id) {
        const res = await api.deleteSkills(id);        
        return res.data;
    },

    async showSkills({ commit }, id) {
        const res = await api.showSkills(id);        
        return res.data;
    },

    async fetchUserSkills({ commit }, id) {
        const res = await api.getUserSkills(id);      
        // return res.data
        commit('setUserSkills', res.data.data);
    },

    async saveBankInfo({ commit }, payload) {
        const res = await api.storeBankInfo(payload);
        commit('setBankDetails', res.data);
        return res.data;
    },

    async updateBankInfo({ commit }, payload) {
        const res = await api.updateBankInfo(payload);        
        commit('setBankDetails', res.data)
    },

    async deleteBankInfo({ commit }, id) {
        const res = await api.deleteBankInfo(id);        
        return res.data;
    },

    async showBankInfo({ commit }, id) {
        const res = await api.showBankInfo(id);        
        return res.data;
    },

    async fetchUserBankDetails({ commit }, id) {
        const res = await api.getUserBankInfo(id); 
        // return res.data
        commit('setUserBankDetails', res.data.data);
    },

    async saveCompensation({ commit }, payload) {
        const res = await api.storeCompensation(payload);
        commit('setCompensation', res.data);
        return res.data;
    },

    async updateCompensation({ commit }, payload) {
        const res = await api.updateCompensation(payload);        
        commit('setCompensation', res.data)
    },

    async deleteCompensation({ commit }, id) {
        const res = await api.deleteCompensation(id);        
        return res.data;
    },

    async showCompensation({ commit }, id) {
        const res = await api.showCompensation(id);        
        return res.data;
    },
    
    async fetchUserCompensation({ commit }, id) {
        const res = await api.getUserCompensation(id);
        commit('setUserCompensation', res.data.data);
        return res.data.data;
    },

    async saveDependant({ commit }, payload) {
        const res = await api.storeDependant(payload);
        commit('setDependant', res.data);
        return res.data;
    },

    async updateDependant({ commit }, payload) {
        const res = await api.updateDependant(payload);        
        commit('setDependant', res.data)
    },

    async deleteDependant({ commit }, id) {
        const res = await api.deleteDependant(id);        
        return res.data;
    },

    async showDependant({ commit }, id) {
        const res = await api.showDependant(id);
        
        return res.data;
    },

    async fetchUserDependant({ commit }, id) {
        const res = await api.getUserDependant(id);
        
        // return res.data
        commit('setUserDependant', res.data.data);
    },

    async saveBenefit({ commit }, payload) {
        const res = await api.storeBenefit(payload);
        commit('setBenefit', res.data);
        return res.data;
    },

    async updateBenefit({ commit }, payload) {
        const res = await api.updateBenefit(payload);
        
        commit('setBenefit', res.data)
    },

    async deleteBenefit({ commit }, id) {
        const res = await api.deleteBenefit(id);
        
        return res.data;
    },

    async showBenefit({ commit }, id) {
        const res = await api.showBenefit(id);
        
        return res.data;
    },

    async fetchUserBenefit({ commit }, id) {
        const res = await api.getUserBenefit(id);
        commit('setUserBenefit', res.data.data);
        return res.data.data;
    },

    async saveWorkLocation({ commit }, payload) {
        const res = await api.storeWorkLocation(payload);
        commit('setWorkLocation', res.data);
        return res.data;
    },

    async updateWorkLocation({ commit }, payload) {
        const res = await api.updateWorkLocation(payload);
        
        commit('setWorkLocation', res.data);
        return res.data;
    },

    async showWorkLocation({ commit }, id) {
        const res = await api.getUserWorkLocation(id);
        
        commit('setWorkLocation', res.data);
        return res.data;
    },

    async fetchUserWorkLocations({ commit }, id) {
        const res = await api.userWorkLocationLog(id);
        commit('setUserWorkLocations', res.data);
        return res.data;
    },

    async fetchUserRecentWorkLocations({ commit }, id) {
        const res = await api.userRecentWorkLocationLog(id);
        commit('setUserRecentWorkLocations', res.data.data);
        return res.data.data;
    },

    //work schedule
    async saveWorkSchedule({ commit }, payload) {
        const res = await api.storeWorkSchedule(payload);
        commit('setWorkSchedule', res.data);
        return res.data;
    },

    async updateWorkSchedule({ commit }, payload) {
        const res = await api.updateWorkSchedule(payload);
        
        commit('setWorkSchedule', res.data);
        return res.data;
    },

    async showWorkSchedule({ commit }, id) {
        const res = await api.getUserWorkSchedule(id);
        
        commit('setWorkSchedule', res.data);
        return res.data;
    },

    async fetchUserWorkSchedules({ commit }, id) {
        const res = await api.userWorkScheduleLog(id);
        commit('setUserWorkSchedules', res.data);
        return res.data;
    },

    async fetchUserRecentWorkSchedules({ commit }, id) {
        const res = await api.userRecentWorkScheduleLog(id);
        commit('setUserRecentWorkSchedules', res.data.data);
        return res.data.data;
    },

    //work license
    async saveWorkLicense({ commit }, payload) {
        const res = await api.storeWorkLicense(payload);
        commit('setWorkLicense', res.data);
        return res.data;
    },

    async updateWorkLicense({ commit }, payload) {
        const res = await api.updateWorkLicense(payload);
        
        commit('setWorkLicense', res.data);
        return res.data;
    },

    async showWorkLicense({ commit }, id) {
        const res = await api.getUserWorkLicense(id);
        
        commit('setWorkLicense', res.data);
        return res.data;
    },

    async fetchUserWorkLicenses({ commit }, id) {
        const res = await api.userWorkLicenseLog(id);
        commit('setUserWorkLicenses', res.data.data);
        return res.data.data;
    },

    async fetchUserAdminRoleLicenses({ commit }, id) {
        const res = await api.userAdminRoleLicenseLog(id);
        commit('setUserAdminRoleWorkLicenses', res.data.data);
        return res.data.data;
    },

    async fetchUserRecentAdminRoleLicenses({ commit }, id) {
        const res = await api.userRecentAdminRoleLicenseLog(id);
        commit('setUserRecentAdminRoleWorkLicenses', res.data.data);
        return res.data.data;
    },

    async fetchUserRecentWorkLicenses({ commit }, id) {
        const res = await api.userRecentWorkLicenseLog(id);
        console.log('res', res);
        commit('setUserRecentWorkLicenses', res.data.data);
        return res.data.data;
    },

    async saveCompliance({ commit }, payload) {
        const res = await api.storeCompliance(payload);
        commit('setCompliance', res.data);
        return res.data;
    },

    async updateCompliance({ commit }, payload) {
        const res = await api.updateCompliance(payload);
        
        commit('setCompliance', res.data)
    },

    async deleteCompliance({ commit }, id) {
        const res = await api.deleteCompliance(id);
        
        return res.data;
    },

    async fetchUserCompliance({ commit }, id) {
        const res = await api.getUserCompliance(id);
        commit('setUserCompliance', res.data);
        return res.data
    },

    async saveComplianceAnnualPhysical({ commit }, payload) {
        const res = await api.storeComplianceAnnualPhysical(payload);
        commit('setComplianceAnnualPhysical', res.data);
        return res.data;
    },

    async updateComplianceAnnualPhysical({ commit }, payload) {
        const res = await api.updateComplianceAnnualPhysical(payload);
        
        commit('setComplianceAnnualPhysical', res.data)
    },

    async deleteComplianceAnnualPhysical({ commit }, id) {
        const res = await api.deleteComplianceAnnualPhysical(id);
        
        return res.data;
    },

    async showComplianceAnnualPhysical({ commit }, id) {
        const res = await api.showComplianceAnnualPhysical(id);
        
        return res.data;
    },

    async fetchUserComplianceAnnualPhysical({ commit }, id) {
        const res = await api.getUserComplianceAnnualPhysical(id);
        commit('setUserComplianceAnnualPhysical', res.data.data);
        return res.data.data;
    },

    async saveComplianceInService({ commit }, payload) {
        const res = await api.storeComplianceInService(payload);
        commit('setComplianceInService', res.data);
        return res.data;
    },

    async updateComplianceInService({ commit }, payload) {
        const res = await api.updateComplianceInService(payload);
        
        commit('setComplianceInService', res.data)
    },

    async deleteComplianceInService({ commit }, id) {
        const res = await api.deleteComplianceInService(id);
        
        return res.data;
    },

    async showComplianceInService({ commit }, id) {
        const res = await api.showComplianceInService(id);
        
        return res.data;
    },

    async fetchUserComplianceInService({ commit }, id) {
        const res = await api.getUserComplianceInservice(id);
        commit('setUserComplianceInService', res.data.data);
        return res.data.data;
    },
}

const mutations = {

    setAdminStaff(state, admin_staff) {
        state.adminStaff = admin_staff
    },

    setOtherStaff(state, other_staff) {
        state.otherStaff = other_staff
    },


    setAdminStaffList(state, admin_staff_list) {
        state.adminStaffList = admin_staff_list
    },

    setOtherStaffList(state, other_staff_list) {
        state.otherStaffList = other_staff_list
    },


    
    setAdministrativeRole(state, administrative_role) {
        state.administrativeRole = administrative_role
    },

    setUserAdministrativeRoles(state, user_administrative_role){
        state.userAdministrativeRoles = user_administrative_role
    },

    setJobExperience(state, job_experience) {
        state.jobExperience = job_experience
    },

    setCompensation(state, compensation) {
        state.compensation = compensation
    },

    setDependant(state, dependant){
        state.dependant = dependant
    },

    setBenefit(state, benefit){
        state.benefit = benefit
    },

    setSkills(state, skills) {
        state.skills = skills
    },

    setBankDetails(state, bank_details) {
        state.bankDetails = bank_details
    },

    setWorkLocation(state, work_location) {
        state.workLocation = work_location
    },

    setUserWorkLocations(state, user_work_locations) {
        state.userWorkLocations = user_work_locations
    },

    setUserRecentWorkLocations(state, recent_work_locations) {
        state.userRecentWorkLocations = recent_work_locations
    },

    setWorkSchedule(state, work_schedule) {
        state.workLocation = work_schedule
    },

    setUserWorkSchedules(state, user_work_schedules) {
        state.userWorkSchedules = user_work_schedules
    },

    setUserRecentWorkSchedules(state, recent_work_schedules) {
        state.userRecentWorkSchedules = recent_work_schedules
    },

    setWorkLicense(state, work_license) {
        state.workLicense = work_license
    },

    setUserWorkLicenses(state, user_work_licenses) {
        state.userWorkLicenses = user_work_licenses
    },

    setUserRecentWorkLicenses(state, recent_work_licenses) {
        state.userRecentWorkLicenses = recent_work_licenses
    },

    setUserAdminRoleWorkLicenses(state, user_admin_role_licenses) {
        state.userAdminRoleWorkLicenses = user_admin_role_licenses
    },

    setUserRecentAdminRoleWorkLicenses(state, recent_role_licenses) {
        state.userRecentAdminRoleWorkLicenses = recent_role_licenses
    },

    setCompliance(state, compliance) {
        state.compliance = compliance
    },

    setComplianceInService(state, compliance_in_service) {
        state.complianceInService = compliance_in_service
    },

    setComplianceAnnualPhysical(state, compliance_annual_physical) {
        state.complianceAnnualPhysical = compliance_annual_physical
    },

    setUserJobExperience(state, user_job_experience) {
        state.userJobExperience = user_job_experience
    },

    setUserBankDetails(state, user_bank_details) {
        state.userBankDetails = user_bank_details
    },

    setUserCompensation(state, user_compensation) {
        state.userCompensation = user_compensation
    },

    setUserDependant(state, user_dependant){
        state.userDependant = user_dependant
    },

    setUserBenefit(state, user_benefit){
        state.userBenefit = user_benefit
    },

    setUserSkills(state, user_skills) {
        state.userSkills = user_skills
    },

    setUserCompliance(state, user_compliance) {
        state.userCompliance = user_compliance
    },

    setUserComplianceInService(state, user_compliance_in_service) {
        state.userComplianceInService = user_compliance_in_service
    },

    setUserComplianceAnnualPhysical(state, user_compliance_annual_physical) {
        state.userComplianceAnnualPhysical = user_compliance_annual_physical
    },

    setAdminStaffDashboardData(state, data) {
        state.adminStaff_dashboard = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}