import * as api from '@/utils/services/clients/settings/prognosis';

const state = {
    prognosis: {},
    prognosisList: [],
}

const getters = {
    getClientPrognosis: state => state.prognosis,
    getClientPrognosisList: state => state.prognosisList
}

const actions = {

    async fetchClientPrognosis({ commit }) {
        const res = await api.getClientPrognosis();
        commit('setClientPrognosisList', res.data)
        return res.data;
    },

    async saveClientPrognosis({ commit }, payload) {
        const res = await api.storeClientPrognosis(payload);
        commit('setClientPrognosis', res.data)
    },

    async updateClientPrognosis({ commit }, payload, id) {
        const res = await api.updateClientPrognosis(payload, id);
        commit('setClientPrognosis', res.data)
    },

    async showClientPrognosis({ commit }, id) {
        const res = await api.showClientPrognosis(id);
        commit('setClientPrognosis', res.data)
        return res.data;
    },

    async deleteClientPrognosis({ commit }, id) {
        const res = await api.deleteClientPrognosis(id);
        commit('setClientPrognosisList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientPrognosis(state, payload) {
        state.prognosis = payload
    },

    setClientPrognosisList(state, payload) {
        state.prognosisList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}