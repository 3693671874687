import * as api from '@/utils/services/users/settings/benefit401k';

const state = {
    userBenefit401k: {},
    userBenefit401kList: [],
}

const getters = {
    getUserBenefit401k: state => state.userBenefit401k,
    getUserBenefit401kList: state => state.userBenefit401kList
}

const actions = {

    async fetchUserBenefit401ks({ commit }) {
        const res = await api.getUserBenefit401ks();
        commit('setUserBenefit401kList', res.data)
        return res.data;
    },

    async saveUserBenefit401k({ commit }, payload) {
        const res = await api.storeUserBenefit401k(payload);
        commit('setUserBenefit401k', res.data)
    },

    async updateUserBenefit401k({ commit }, payload, id) {
        const res = await api.updateUserBenefit401k(payload, id);
        commit('setUserBenefit401k', res.data)
    },

    async showUserBenefit401k({ commit }, id) {
        const res = await api.showUserBenefit401k(id);
        commit('setUserBenefit401k', res.data)
        return res.data;
    },

    async deleteUserBenefit401k({ commit }, id) {
        const res = await api.deleteUserBenefit401k(id);
        commit('setUserBenefit401kList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserBenefit401k(state, payload) {
        state.userBenefit401k = payload
    },

    setUserBenefit401kList(state, payload) {
        state.userBenefit401kList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}