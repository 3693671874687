import axios from "axios";
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;

export const postNurse = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/nurses`, payload, Header);
    } catch (error) {
        console.error('Error posting nurse:', error);
        return error; // Re-throw the error to handle it in the calling code
    }
};

export const getAllNurses = async(params) => {
    if (params) {
        try {
            return await axios.get(`${baseAPI}/nurses?name=${params.name}&client_id=${params.client_id}`, Header);
        } catch (error) {
            return error;
        }
    } else {
        try {
            return await axios.get(`${baseAPI}/nurses?name=&client_id=`, Header);
        } catch (error) {
            return error;
        }
    }
};

export const updateNurse = async(params) => {
    try {
        return await axios.post(`${baseAPI}/nurses/${params.id}/update`, params, Header);
    } catch (error) {
        console.error('Error updating nurse:', error);
        return error;
    }
};

export const deleteNurse = async(id) => {
    try {
        return await axios.post(`${baseAPI}/nurses/${id}/delete`, null, Header);
    } catch (error) {
        console.error('Error deleting nurse:', error);
        return error;
    }
};

export const nurseDetails = async(id) => {
    try {
        return await axios.get(`${baseAPI}/nurses/${id}`, Header);
    } catch (error) {
        console.error('Error fetching nurse details:', error);
        return error;
    }
};

export const getNurseDashboardData = async (params) => {
    try {
        const { type, period, startDate, endDate } = params;
        let url = `${baseAPI}/nurses/data/dashboard?type=${type}&period=${period}`;
        
        if (startDate) {
            url += `&startDate=${startDate}`;
        }
        
        if (endDate) {
            url += `&endDate=${endDate}`;
        }

        const res = await axios.get(url, Header);
        
        return res;
    } catch (error) {
        console.error('Error getting nurse dashboard details:', error);
        throw error; 
    }
}

export const importNurses = async(payload) => {
    try {
        return await axios.post(`${baseAPI}/nurses/import`, payload, PhotoHeader);
    } catch (error) {
        return error;
    }
};