import axios from "axios";
import config from "@/config"
const { baseAPI, Header, AuthHeader } = config;

export default {

	getEmailSMTP() {
		return axios.get(`${baseAPI}/email/smtp`, Header)
	},

    updateEmailSMTP(payload,id) {
		return axios.post(`${baseAPI}/email/smtp/${id}/update` ,payload, Header)
	},

}
