import { state } from "./taskState.js"
import { actions } from "./taskActions.js"
import { getters } from "./taskGetters.js"
import { mutations } from "./taskMutation.js"

export default {
    state,
    actions,
    getters,
    mutations
}