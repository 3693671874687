import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: '/case-coordinators',
        name: 'Case Coordinators',
        component: () =>
            import ('@/views/casecoordinators/index.vue'),
        meta: {
            check: 'show_case_coordinators',
            title: 'Case Coordinators Log',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: 'case-coordinators/add',
        name: 'Add Case Coordinator',
        component: () =>
            import ('@/views/casecoordinators/create'),
        meta: {
            title: 'Add Case Coordinator',
        },
    },
    {
        path: 'case-coordinators/update/:id',
        name: 'Case Coordinator Update',
        component: () =>
            import ('@/views/casecoordinators/edit'),
    },
    // {
    //     path: 'case-coordinators/view/:id',
    //     name: 'View Case Coordinator Details',
    //     component: () =>
    //         import ('@/views/casecoordinators/View.vue'),
    // },
]