import * as api from '@/utils/services/users/settings/racialIdentity';

const state = {
    userRacialIdentity: {},
    userRacialIdentityList: [],
}

const getters = {
    getUserRacialIdentity: state => state.userRacialIdentity,
    getUserRacialIdentityList: state => state.userRacialIdentityList
}

const actions = {

    async fetchUserRacialIdentitys({ commit }) {
        const res = await api.getUserRacialIdentity();
        commit('setUserRacialIdentityList', res.data)
        return res.data;
    },

    async saveUserRacialIdentity({ commit }, payload) {
        const res = await api.storeUserRacialIdentity(payload);
        commit('setUserRacialIdentity', res.data)
    },

    async updateUserRacialIdentity({ commit }, payload, id) {
        const res = await api.updateUserRacialIdentity(payload, id);
        commit('setUserRacialIdentity', res.data)
    },

    async showUserRacialIdentity({ commit }, id) {
        const res = await api.showUserRacialIdentity(id);
        commit('setUserRacialIdentity', res.data)
        return res.data;
    },

    async deleteUserRacialIdentity({ commit }, id) {
        const res = await api.deleteUserRacialIdentity(id);
        commit('setUserRacialIdentityList', res.data);
        return res.data;
    },

}

const mutations = {
    setUserRacialIdentity(state, payload) {
        state.userRacialIdentity = payload
    },

    setUserRacialIdentityList(state, payload) {
        state.userRacialIdentityList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}