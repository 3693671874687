import axios from "axios";
import config from "@/config"
const { baseAPI, Header } = config;

const requestURI = 'user/settings/work-max-hours';

export const getUserWorkMaxHours = async () => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}`, Header);
    } catch (error) {
        return error
    }
}

export const storeUserWorkMaxHours = async (payload) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}`, payload, Header);
    } catch (error) {
        return error
    }
}

export const updateUserWorkMaxHours = async (payload, id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/update`, payload, Header);
    } catch (error) {
        return error
    }
}

export const showUserWorkMaxHours = async (id) => {
    try {
        return await axios.get(`${baseAPI}/${requestURI}/${id}`, Header);
    } catch (error) {
        return error
    }
}
export const deleteUserWorkMaxHours = async (id) => {
    try {
        return await axios.post(`${baseAPI}/${requestURI}/${id}/delete`, null, Header);
    } catch (error) {
        return error
    }
}
