import * as api from '@/utils/services/clients/settings/taskActivityTask';

const state = {
    task: {},
    taskList: [],
}

const getters = {
    getClientTaskActivityTask: state => state.task,
    getClientTaskActivityTaskList: state => state.taskList
}

const actions = {

    async fetchClientTaskActivityTask({ commit }) {
        const res = await api.getClientTaskActivityTask();
        commit('setClientTaskActivityTaskList', res.data)
        return res.data;
    },

    async saveClientTaskActivityTask({ commit }, payload) {
        const res = await api.storeClientTaskActivityTask(payload);
        commit('setClientTaskActivityTask', res.data)
    },

    async updateClientTaskActivityTask({ commit }, payload, id) {
        const res = await api.updateClientTaskActivityTask(payload, id);
        commit('setClientTaskActivityTask', res.data)
    },

    async showClientTaskActivityTask({ commit }, id) {
        const res = await api.showClientTaskActivityTask(id);
        commit('setClientTaskActivityTask', res.data)
        return res.data;
    },

    async deleteClientTaskActivityTask({ commit }, id) {
        const res = await api.deleteClientTaskActivityTask(id);
        commit('setClientTaskActivityTaskList', res.data);
        return res.data;
    },

}

const mutations = {
    setClientTaskActivityTask(state, payload) {
        state.task = payload
    },

    setClientTaskActivityTaskList(state, payload) {
        state.taskList = payload
    },
}

export default {
	state,
	getters,
	actions,
	mutations	
}