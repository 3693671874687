import axios from 'axios';
import config from "@/config";
const { baseAPI, Header, AuthHeader } = config;

export const getCoordinators = (params) => {
    if (params) {
        // return axios.get(`${baseAPI}/coordinators`, {
        //     params: {
        //         name: params.name,
        //         coord_id: params.coord_id,
        //     },
        //     Header
        // });
        return axios.get(`${baseAPI}/coordinators?name=` + params['name'] + '&coord_id=' + params['coord_id'], Header);
    }
    // return axios.get(`
    //         $ { baseAPI }
    //         /coordinators`, {
    //     params: {
    //         name: '',
    //         coord_id: '',
    //     },
    //     Header
    // });
    return axios.get(`${baseAPI}/coordinators?name=&coord_id=`, Header);

};

export const assign_homecareworker_coordinator = (params) => {
    return axios.post(`
                $ { baseAPI }
                /coordinators/assign - homecareworker `, params, Header);
};