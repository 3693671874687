import { checkPermissions } from '@/utils/routeService/useCheckPermissions';


export default [{
        path: '/users',
        name: 'Users',
        component: () =>
            import('@/views/users'),
        meta: {
            check: 'show_users',
            title: 'Users Log'
        },
        beforeEnter: checkPermissions,
    },
    {
        path: '/users/personal-information',
        name: 'Personal Information',
        component: () =>
            import('@/views/users/components/personalInformation.vue'),
    },
    {
        path: '/users/confirmation',
        name: 'Users Confirmation',
        component: () =>
            import('@/views/users/components/userConfirmation.vue')
    }, 
    {
        path: '/users/successful',
        name: 'Users Successful',
        component: () =>
            import('@/views/users/components/userSuccessful.vue')
    },
    {
        path: '/users/add',
        name: 'Add User',
        component: () =>
            import('@/views/users/AddUser.vue')
    },
    {
        path: '/users/update/:id',
        name: 'Update User',
        component: () =>
            import('@/views/users/components/editPersonalInformation.vue')
    },
    {
        path: 'user-groups',
        name: 'User Groups',
        component: () =>
            import('@/views/users/userGroups'),
        meta: {
            check: 'show_user_group',
            title: 'User Groups'
        },
        beforeEnter: checkPermissions,
    },
    {
        path: 'user-groups/details/:id',
        name: 'User Groups Details',
        component: () =>
            import('@/views/users/userGroups/Details'),
        meta: {
            // requiresAuth: true,
             title: 'User Groups Details'
        }
    },
    {
        path: 'user-levels',
        name: 'User Levels',
        component: () =>
            import('@/views/users/userlevels'),
        meta: {
            check: 'show_user_level',
        },
        beforeEnter: checkPermissions,
    },
    {
        path: 'user-level/details/:id',
        name: 'User Level Details',
        component: () =>
            import('@/views/users/userlevels/Details'),
    },
    {
        path: 'users/settings',
        name: 'UserSettings',
        component: () =>
            import('@/views/users/settings'),
    },
    {
        path: '/users/gender-settings',
        name: 'UserGenderSettings',
        component: () =>
            import('@/views/users/settings/gender')
    },
    {
        path: '/users/gender-settings/:id',
        name: 'EditUserGenderSettings',
        component: () =>
            import('@/views/users/settings/gender/components/Edit')
    },
    {
        path: '/users/add-gender',
        name: 'AddUserGenderSettings',
        component: () =>
            import('@/views/users/settings/gender/components/Add')
    },

    {
        path: '/users/transportation-mode-settings',
        name: 'UserTransportationModeSettings',
        component: () =>
            import('@/views/users/settings/transportationMode')
    },
    {
        path: '/users/transportation-mode-settings/:id',
        name: 'EditUserTransportationModeSettings',
        component: () =>
            import('@/views/users/settings/transportationMode/components/Edit')
    },
    {
        path: '/users/add-gender',
        name: 'AddUserTransportationModeSettings',
        component: () =>
            import('@/views/users/settings/transportationMode/components/Add')
    },

    {
        path: '/users/relationship-settings',
        name: 'UserRelationshipSettings',
        component: () =>
            import('@/views/users/settings/relationship')
    },
    {
        path: '/users/relationship-settings/:id',
        name: 'EditUserRelationshipSettings',
        component: () =>
            import('@/views/users/settings/relationship/components/Edit')
    },
    {
        path: '/users/add-relationship',
        name: 'AddUserRelationshipSettings',
        component: () =>
            import('@/views/users/settings/relationship/components/Add')
    },

    {
        path: '/users/company-sector-settings',
        name: 'UserCompanySectorSettings',
        component: () =>
            import('@/views/users/settings/companySector')
    },
    {
        path: '/users/company-sector-settings/:id',
        name: 'EditUserCompanySectorSettings',
        component: () =>
            import('@/views/users/settings/companySector/components/Edit')
    },
    {
        path: '/users/add-company-sector',
        name: 'AddUserCompanySectorSettings',
        component: () =>
            import('@/views/users/settings/companySector/components/Add')
    },

    {
        path: '/users/marital-status-settings',
        name: 'UserMaritalStatusSettings',
        component: () =>
            import('@/views/users/settings/maritalStatus')
    },
    {
        path: '/users/marital-status-settings/:id',
        name: 'EditUserMaritalStatusSettings',
        component: () =>
            import('@/views/users/settings/maritalStatus/components/Edit')
    },
    {
        path: '/users/add-marital-status',
        name: 'AddUserMaritalStatusSettings',
        component: () =>
            import('@/views/users/settings/maritalStatus/components/Add')
    },

    {
        path: '/users/mobile-status-settings',
        name: 'UserMobileStatusSettings',
        component: () =>
            import('@/views/users/settings/mobileStatus')
    },
    {
        path: '/users/mobile-status-settings/:id',
        name: 'EditUserMobileStatusSettings',
        component: () =>
            import('@/views/users/settings/mobileStatus/components/Edit')
    },
    {
        path: '/users/add-mobile-status',
        name: 'AddUserMobileStatusSettings',
        component: () =>
            import('@/views/users/settings/mobileStatus/components/Add')
    },

    {
        path: '/users/skill-level-settings',
        name: 'UserSkillLevelSettings',
        component: () =>
            import('@/views/users/settings/skillLevel')
    },
    {
        path: '/users/skill-level-settings/:id',
        name: 'EditUserSkillLevelSettings',
        component: () =>
            import('@/views/users/settings/skillLevel/components/Edit')
    },
    {
        path: '/users/add-skill-level',
        name: 'AddUserSkillLevelSettings',
        component: () =>
            import('@/views/users/settings/skillLevel/components/Add')
    },

    {
        path: '/users/skill-certification-settings',
        name: 'UserSkillCertificationSettings',
        component: () =>
            import('@/views/users/settings/skillCertification')
    },
    {
        path: '/users/skill-certification-settings/:id',
        name: 'EditUserSkillCertificationSettings',
        component: () =>
            import('@/views/users/settings/skillCertification/components/Edit')
    },
    {
        path: '/users/add-skill-certification',
        name: 'AddUserSkillCertificationSettings',
        component: () =>
            import('@/views/users/settings/skillCertification/components/Add')
    },

    {
        path: '/users/education-certification-settings',
        name: 'UserEducationCertificationSettings',
        component: () =>
            import('@/views/users/settings/educationCertification')
    },
    {
        path: '/users/education-certification-settings/:id',
        name: 'EditUserEducationCertificationSettings',
        component: () =>
            import('@/views/users/settings/educationCertification/components/Edit')
    },
    {
        path: '/users/add-education-certification',
        name: 'AddUserEducationCertificationSettings',
        component: () =>
            import('@/views/users/settings/educationCertification/components/Add')
    },

    {
        path: '/users/education-concentration-settings',
        name: 'UserEducationConcentrationSettings',
        component: () =>
            import('@/views/users/settings/educationConcentration')
    },
    {
        path: '/users/education-concentration-settings/:id',
        name: 'EditUserEducationConcentrationSettings',
        component: () =>
            import('@/views/users/settings/educationConcentration/components/Edit')
    },
    {
        path: '/users/add-education-concentration',
        name: 'AddUserEducationConcentrationSettings',
        component: () =>
            import('@/views/users/settings/educationConcentration/components/Add')
    },

    {
        path: '/users/job-experience-level-settings',
        name: 'UserJobExperienceLevelSettings',
        component: () =>
            import('@/views/users/settings/jobExperienceLevel')
    },
    {
        path: '/users/job-experience-level-settings/:id',
        name: 'EditUserJobExperienceLevelSettings',
        component: () =>
            import('@/views/users/settings/jobExperienceLevel/components/Edit')
    },
    {
        path: '/users/add-job-experience-level',
        name: 'AddUserJobExperienceLevelSettings',
        component: () =>
            import('@/views/users/settings/jobExperienceLevel/components/Add')
    },

    {
        path: '/users/benefit-medical-settings',
        name: 'UserBenefitMedicalSettings',
        component: () =>
            import('@/views/users/settings/benefitMedical')
    },
    {
        path: '/users/benefit-medical-settings/:id',
        name: 'EditUserBenefitMedicalSettings',
        component: () =>
            import('@/views/users/settings/benefitMedical/components/Edit')
    },
    {
        path: '/users/add-benefit-medical',
        name: 'AddUserBenefitMedicalSettings',
        component: () =>
            import('@/views/users/settings/benefitMedical/components/Add')
    },

    {
        path: '/users/benefit-401k-settings',
        name: 'UserBenefit401kSettings',
        component: () =>
            import('@/views/users/settings/benefit401k')
    },
    {
        path: '/users/benefit-401k-settings/:id',
        name: 'EditUserBenefit401kSettings',
        component: () =>
            import('@/views/users/settings/benefit401k/components/Edit')
    },
    {
        path: '/users/add-benefit-401k',
        name: 'AddUserBenefit401kSettings',
        component: () =>
            import('@/views/users/settings/benefit401k/components/Add')
    },

    {
        path: '/users/ethnicity-settings',
        name: 'UserEthnicitySettings',
        component: () =>
            import('@/views/users/settings/ethnicity')
    },
    {
        path: '/users/ethnicity-settings/:id',
        name: 'EditUserEthnicitySettings',
        component: () =>
            import('@/views/users/settings/ethnicity/components/Edit')
    },
    {
        path: '/users/add-ethnicity',
        name: 'AddUserEthnicitySettings',
        component: () =>
            import('@/views/users/settings/ethnicity/components/Add')
    },

    {
        path: '/users/pet-friendly-settings',
        name: 'UserPetFriendlySettings',
        component: () =>
            import('@/views/users/settings/petFriendly')
    },
    {
        path: '/users/pet-friendly-settings/:id',
        name: 'EditUserPetFriendlySettings',
        component: () =>
            import('@/views/users/settings/petFriendly/components/Edit')
    },
    {
        path: '/users/add-pet-friendly',
        name: 'AddUserPetFriendlySettings',
        component: () =>
            import('@/views/users/settings/petFriendly/components/Add')
    },

    {
        path: '/users/smoke-friendly-settings',
        name: 'UserSmokeFriendlySettings',
        component: () =>
            import('@/views/users/settings/smokeFriendly')
    },
    {
        path: '/users/smoke-friendly-settings/:id',
        name: 'EditUserSmokeFriendlySettings',
        component: () =>
            import('@/views/users/settings/smokeFriendly/components/Edit')
    },
    {
        path: '/users/add-smoke-friendly',
        name: 'AddUserSmokeFriendlySettings',
        component: () =>
            import('@/views/users/settings/smokeFriendly/components/Add')
    },

    {
        path: '/users/compliance-status-settings',
        name: 'UserComplianceStatusSettings',
        component: () =>
            import('@/views/users/settings/complianceStatus')
    },
    {
        path: '/users/compliance-status-settings/:id',
        name: 'EditUserComplianceStatusSettings',
        component: () =>
            import('@/views/users/settings/complianceStatus/components/Edit')
    },
    {
        path: '/users/add-compliance-status',
        name: 'AddUserComplianceStatusSettings',
        component: () =>
            import('@/views/users/settings/complianceStatus/components/Add')
    },

    {
        path: '/users/compliance-resolution-settings',
        name: 'UserComplianceResolutionSettings',
        component: () =>
            import('@/views/users/settings/complianceResolution')
    },
    {
        path: '/users/compliance-resolution-settings/:id',
        name: 'EditUserComplianceResolutionSettings',
        component: () =>
            import('@/views/users/settings/complianceResolution/components/Edit')
    },
    {
        path: '/users/add-compliance-resolution',
        name: 'AddUserComplianceResolutionSettings',
        component: () =>
            import('@/views/users/settings/complianceResolution/components/Add')
    },

    {
        path: '/users/compliance-test-result-settings',
        name: 'UserComplianceTestResultSettings',
        component: () =>
            import('@/views/users/settings/complianceTestResult')
    },
    {
        path: '/users/compliance-test-result-settings/:id',
        name: 'EditUserComplianceTestResultSettings',
        component: () =>
            import('@/views/users/settings/complianceTestResult/components/Edit')
    },
    {
        path: '/users/add-compliance-test-result',
        name: 'AddUserComplianceTestResultSettings',
        component: () =>
            import('@/views/users/settings/complianceTestResult/components/Add')
    },

    {
        path: '/users/compliance-action-taken-settings',
        name: 'UserComplianceActionTakenSettings',
        component: () =>
            import('@/views/users/settings/complianceActionTaken')
    },
    {
        path: '/users/compliance-action-taken-settings/:id',
        name: 'EditUserComplianceActionTakenSettings',
        component: () =>
            import('@/views/users/settings/complianceActionTaken/components/Edit')
    },
    {
        path: '/users/add-compliance-action-taken',
        name: 'AddUserComplianceActionTakenSettings',
        component: () =>
            import('@/views/users/settings/complianceActionTaken/components/Add')
    },

    {
        path: '/users/work-schedule-settings',
        name: 'UserWorkScheduleSettings',
        component: () =>
            import('@/views/users/settings/workSchedule')
    },
    {
        path: '/users/work-schedule-settings/:id',
        name: 'EditUserWorkScheduleSettings',
        component: () =>
            import('@/views/users/settings/workSchedule/components/Edit')
    },
    {
        path: '/users/add-work-schedule',
        name: 'AddUserWorkScheduleSettings',
        component: () =>
            import('@/views/users/settings/workSchedule/components/Add')
    },

    {
        path: '/users/work-license-type-settings',
        name: 'UserWorkLicenseTypeSettings',
        component: () =>
            import('@/views/users/settings/workLicenseType')
    },
    {
        path: '/users/work-license-type-settings/:id',
        name: 'EditUserWorkLicenseTypeSettings',
        component: () =>
            import('@/views/users/settings/workLicenseType/components/Edit')
    },
    {
        path: '/users/add-work-license-type',
        name: 'AddUserWorkLicenseTypeSettings',
        component: () =>
            import('@/views/users/settings/workLicenseType/components/Add')
    },

    {
        path: '/users/work-license-status-settings',
        name: 'UserWorkLicenseStatusSettings',
        component: () =>
            import('@/views/users/settings/workLicenseStatus')
    },
    {
        path: '/users/work-license-status-settings/:id',
        name: 'EditUserWorkLicenseStatusSettings',
        component: () =>
            import('@/views/users/settings/workLicenseStatus/components/Edit')
    },
    {
        path: '/users/add-work-license-status',
        name: 'AddUserWorkLicenseStatusSettings',
        component: () =>
            import('@/views/users/settings/workLicenseStatus/components/Add')
    },

    {
        path: '/users/work-max-hours-settings',
        name: 'UserWorkMaxHoursSettings',
        component: () =>
            import('@/views/users/settings/workMaxHours')
    },
    {
        path: '/users/work-max-hours-settings/:id',
        name: 'EditUserWorkMaxHoursSettings',
        component: () =>
            import('@/views/users/settings/workMaxHours/components/Edit')
    },
    {
        path: '/users/add-work-max-hours',
        name: 'AddUserWorkMaxHoursSettings',
        component: () =>
            import('@/views/users/settings/workMaxHours/components/Add')
    },

    {
        path: '/users/client-type-settings',
        name: 'UserClientTypeSettings',
        component: () =>
            import('@/views/users/settings/clientType')
    },
    {
        path: '/users/client-type-settings/:id',
        name: 'EditUserClientTypeSettings',
        component: () =>
            import('@/views/users/settings/clientType/components/Edit')
    },
    {
        path: '/users/add-client-type',
        name: 'AddUserClientTypeSettings',
        component: () =>
            import('@/views/users/settings/clientType/components/Add')
    },

    {
        path: '/users/home-distance-settings',
        name: 'UserHomeDistanceSettings',
        component: () =>
            import('@/views/users/settings/homeDistance')
    },
    {
        path: '/users/home-distance-settings/:id',
        name: 'EditUserHomeDistanceSettings',
        component: () =>
            import('@/views/users/settings/homeDistance/components/Edit')
    },
    {
        path: '/users/add-home-distance',
        name: 'AddUserHomeDistanceSettings',
        component: () =>
            import('@/views/users/settings/homeDistance/components/Add')
    },
    {
        path: 'user-service-board/:id',
        name: 'UserServiceBoard',
        component: () =>
            import('@/views/users/userServiceBoard'),
    },
    {
        path: 'view/client/:id',
        name: 'ClientInformation',
        component: () =>
            import('@/views/users/userServiceBoard'),
    },
    {
        path: '/users/racial-identity-settings',
        name: 'UserRacialIdentitySettings',
        component: () =>
            import('@/views/users/settings/racialIdentity')
    },
    {
        path: '/users/racial-identity-settings/:id',
        name: 'EditUserRacialIdentitySettings',
        component: () =>
            import('@/views/users/settings/racialIdentity/components/Edit')
    },
    {
        path: '/users/add-racial-identity',
        name: 'AddUserRacialIdentitySettings',
        component: () =>
            import('@/views/users/settings/racialIdentity/components/Add')
    },
    {
        path: '/users/sexuality-settings',
        name: 'UserSexualitySettings',
        component: () =>
            import('@/views/users/settings/sexuality')
    },
    {
        path: '/users/sexuality-settings/:id',
        name: 'EditUserSexualitySettings',
        component: () =>
            import('@/views/users/settings/sexuality/components/Edit')
    },
    {
        path: '/users/add-sexuality',
        name: 'AddUserSexualitySettings',
        component: () =>
            import('@/views/users/settings/sexuality/components/Add')
    },

];