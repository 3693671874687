import * as api from '@/utils/services/case-coordinators';


const state = {
    casecoordinators: [],
    casecoordinator: {},
    casecoordinatordetails: {},
    casecoordinator_dashboard: {},
}

const getters = {
    getCaseCoordinator: state => state.casecoordinator,
    listCaseCoordinators: state => state.casecoordinators,
    listCaseCoordinatorDetails: state => state.casecoordinatordetails,
    listCaseCoordinatorDashboardData: state => state.casecoordinator_dashboard
}

const actions = {

    async fetchAllCaseCoordinators({commit}, params) {
        try {
            const response = await api.getCaseCoordinators(params);
            commit('setCaseCoordinators', response.data.data);
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async addCaseCoordinator({commit}, params) {
        try {
            const response = await api.postCaseCoordinator(params);
            commit('setCaseCoordinator', response.data);
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async updateCaseCoordinator({commit}, params) {
        try {
            const response = await api.updateCaseCoordinator(params);
            
            return response;
        } catch (error) {
           
            throw error.response;
        }
    },

    async getCaseCoordinatorDetails({ commit }, id) {
        try {
            const response = await api.caseCoordinatorDetails(id);
           
            commit('setCaseCoordinatorDetails', response.data.data);

            return response.data.data;
        } catch (error) {
           
            throw error.response;
        }
    },

    async deleteCaseCoordinator({commit}, id) {
        try {
            const response = await api.deleteCaseCoordinator(id);
           
            return response;
        } catch (error) {
            
            throw error.response;
        }
    },

    async getCaseCoordinatorDashboardData({ commit }, params) {
        const res = await api.getCaseCoordinatorDashboardData(params);
        if (res && res.data) {
          commit('setCaseCoordinatorDashboardData', res.data);
        } else {
          console.log("getCaseCoordinatorDashboardData returned null or undefined");
          commit('setCaseCoordinatorDashboardData', res);
        }
    },

}

const mutations = {

    setCaseCoordinator(state, casecord) {
        state.casecoordinator = casecord
    },
    setCaseCoordinators(state, cc) {
        state.casecoordinators = cc
    },
    setCaseCoordinatorDetails(state, ccdetails) {
        state.casecoordinatordetails = ccdetails
    },
    setCaseCoordinatorDashboardData(state, data) {
        state.casecoordinator_dashboard = data
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}